import { type FC } from 'react';
import { 
  type UiHStackProps,
  UiStack,
  UiHStack,
  UiText,
  uiStyles,
  UiIconCheck,
} from '@/lib/ui';
import { account } from '@/api';

export interface TenantSelectionProps extends UiHStackProps {
  onTenantChange: (tenant: account.AdminUserDataTenant) => void;
  tenants: account.AdminUserDataTenant[];
  selectedTenant: account.AdminUserDataTenant|null;
}

const TenantSelection: FC<TenantSelectionProps> = ({
  onTenantChange,
  tenants,
  selectedTenant = null,
}) => {
  return (
    <UiStack
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      spacing={0}
      enableDivider={true}
    >
      {tenants.map((tenant) => {
        return (
          <UiHStack
            key={tenant.id}
            p={6}
            {...uiStyles.hover}
            onClick={() => {
              if (tenant.code !== selectedTenant?.code) {
                onTenantChange(tenant);
              }
            }}
          >
            <UiText flexGrow={1}>{tenant.name}</UiText>
            {tenant.code === selectedTenant?.code && (
              <UiIconCheck color={'green.500'} size={'2xl'}/>
            )}
          </UiHStack>
        );
      })}
    </UiStack>
  );
};

export default TenantSelection;
