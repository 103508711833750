import { type FC } from 'react';
import { UiSimpleGrid } from '@/lib/ui';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonAvailable } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import ServicePack1 from '@/account/component/SubscriptionAddon/ServicePack1';
import ServicePack2 from '@/account/component/SubscriptionAddon/ServicePack2';
import ServicePack3 from '@/account/component/SubscriptionAddon/ServicePack3';
import { type SubscriptionServicePackName } from '@/api/account';

export interface AddonListProps {
  subscription: account.Subscription
  toggleServicePack: (addonName: SubscriptionServicePackName) => void
}

const AddonList: FC<AddonListProps> = ({
  subscription,
  toggleServicePack
}) => {
  return (
    <>
      <UiSimpleGrid columns={{ base: 1, lg: 2, '2xl': 3 }} gap={{ base: 4, lg: 8 }}>
        {isAddonAvailable(subscription, SubscriptionProduct.ServicePack1) && (
          <ServicePack1
            onToggle={() => toggleServicePack(SubscriptionProduct.ServicePack1)}
            subscription={subscription}
          />
        )}
        {isAddonAvailable(subscription, SubscriptionProduct.ServicePack2) && (
          <ServicePack2
            onToggle={() => toggleServicePack(SubscriptionProduct.ServicePack2)}
            subscription={subscription}
          />
        )}
        {isAddonAvailable(subscription, SubscriptionProduct.ServicePack3) && (
          <ServicePack3
            onToggle={() => toggleServicePack(SubscriptionProduct.ServicePack3)}
            subscription={subscription}
          />
        )}
      </UiSimpleGrid>
    </>
  );
};

export default AddonList;
