import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppDataInsightCard from '@/base/App/DataInsightCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface DataInsightProps extends UiBoxProps {
  isSelected: boolean
  bgColor?: ModuleCardProps['bgColor']
}

const DataInsight: FC<DataInsightProps> = ({
  isSelected,
  bgColor = 'blackAlpha.50'
}) => {
  return (
    <ModuleCard
      isSelected={isSelected}
      bgColor={bgColor}
    >
      <BaseAppDataInsightCard />
    </ModuleCard>
  );
};

export default DataInsight;
