import { type FC, useState } from 'react';
import {
  type registration
} from '@/api';
import BaseBlockList from '@/base/BlockList';
import EventRow from './EventRow';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { UiSpinner } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface EventListProps {
  queryParams: registration.EventListQueryRequest
}

const EventList: FC<EventListProps> = ({
  queryParams
}) => {
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const { data, isLoading, isError } = useEventListQuery();

  if (isLoading) {
    return (
      <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
    );
  }
  if (isError) {
    return (
      <BaseMessageBarError>
        Failed to load the event list. Please try to refresh the page.
      </BaseMessageBarError>
    );
  }

  return (
    <BaseBlockList
      bgColor={'#fff'}
      // shadow={'base'}
    >
      {data?.items.map((event) => {
        return (
          <EventRow
            key={event.id}
            event={event}
            onClick={() => { setSelectedEventId(event.id); }}
            isSelected={selectedEventId === event.id}
          />
        );
      })}
    </BaseBlockList>
  );
};

export default EventList;
