import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const ordersQueryKey = 'registration.orderlist';
export const orderQueryKey = 'registration.order';

export interface Order {
  id: number
  secureHash: string
  status: string
  serial: string
  event: string
  eventId: string
  tickets: Array<{ name: string, quantity: number }>
  attendee: { name: string, email: string }
  totalPrice: string
  purchasedAt: string
}

export interface TableOrder {
  attendee: { name: string, email: string }
  event: string
  purchasedAt: string
  tickets: string[]
  totalPrice: string
}

export interface OrderQueryRequest {
  page?: number
  eventIds?: string[]
  attendeeEmail?: string
  orderSerial?: string
}

export function loadOrder(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    secureHash: string
  ): Promise<ApiResponseSingle<Order>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/orders/${secureHash}`;
    return await properCallApi<ApiResponseSingle<Order>>(request);
  };
}

export function loadOrders(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: OrderQueryRequest
  ): Promise<ApiResponse<TableOrder>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders';
    request.endpoint.query = {
      page: params?.page ?? 0,
      eventId: params.eventIds ?? [],
      attendeeEmail: params.attendeeEmail ?? '',
      orderSerial: params.orderSerial ?? ''
    };
    return await callApi<TableOrder>(request);
  };
}
