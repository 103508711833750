import { type ApiRequest } from '@/api/tenantClient';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth } from '@/app/ProviderAdminAuth';

export function useTenantApi() {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { adminAuth } = useAdminAuth();

  const createTenantApiRequest = (): ApiRequest => {
    return {
      method: 'POST',
      endpoint: {
        host: tenant?.apiEndpoint,
        path: ''
      }
    };
  };

  const createTenantAdminApiRequest = (): ApiRequest => {
    const request = createTenantApiRequest();
    request.headers = {
      ...request.headers,
      uid: adminAuth.user.email,
      client: adminAuth.client,
      'access-token': adminAuth.authToken
    };
    return request;
  };

  return {
    createTenantApiRequest,
    createTenantAdminApiRequest,
    isLoading: isTenantLoading
  };
}
