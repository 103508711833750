import { useMemo, type FC, type ReactNode } from 'react';
import {
  UiStack,
  uiStyles
} from '@/lib/ui';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface BreadcrumbBodyProps {
  children: ReactNode
  breadcrumbNodes: BaseBreadcrumbBarNode[]
  message?: string
}

const BreadcrumbBody: FC<BreadcrumbBodyProps> = ({
  children,
  breadcrumbNodes,
  message = undefined
}) => {
  const { tenantCode } = useRegisterRoute();
  return (
    <UiStack
      spacing={0.5}
      alignItems={'stretch'}
      flexGrow={1}
      // p={8}
    >
      <UiStack
        // borderTopRadius={uiStyles.borderRadius}
        spacing={0}
        {...uiStyles.glass}
      >
        <UiStack p={8} py={6}>
          <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes} />
        </UiStack>
      </UiStack>
      <BaseTransitionSlideLeft>
        <UiStack
          alignItems={'stretch'}
          flexGrow={1}
          spacing={0}
          // borderBottomRadius={uiStyles.borderRadius}
          {...uiStyles.glass}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default BreadcrumbBody;
