import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import SmartWindow from '@/base/Layout/SmartWindow';
import PlanSummary from './PlanSummary';
import SideNav from '@/account/component/Layout/SideNav';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';

const Plan: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<SideNav activeNodes={['subscription', 'plan']} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard />} currentAppName={'account'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <PlanSummary />
        </BaseLayoutBody>
      </BaseLayout>
      {/* <Filter
        isOpen={showFilter}
        onClose={() => {setShowFilter(false)}}
      /> */}
    </>
  );
};

export default Plan;
