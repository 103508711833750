import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconHandshake } from '@/lib/ui';

export interface SponsorExhibitorCardProps {
  isSelected?: boolean
  navLink?: string
}

const SponsorExhibitorCard: FC<SponsorExhibitorCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      icon={(<UiIconHandshake size={'3xl'} color={'gray.800'} />)}
      name={'Sponsors & exhibitors'}
      description={'Manage sponsors and exhibitors'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default SponsorExhibitorCard;
