import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconChalkboard } from '@/lib/ui';

export interface DigitalPosterCardProps {
  isSelected?: boolean
  navLink?: string
  showDescription?: boolean
}

const DigitalPosterCard: FC<DigitalPosterCardProps> = ({
  isSelected = false,
  navLink = '',
  showDescription = true,
}) => {
  return (
    <AppCard
      icon={(<UiIconChalkboard size={'3xl'} color={'gray.800'} />)}
      name={'Digital poster'}
      description={showDescription ? 'Display posters on Kiosk and web' : undefined}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default DigitalPosterCard;
