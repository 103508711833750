import { type FC, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText,
  UiButton,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter, UiSimpleGrid, UiGrid
} from '@/lib/ui';
import { type DiscountCodeDeleteRequest, type DiscountCode } from '@/api/registration';
import { useDisclosure } from '@chakra-ui/react';
import CodeForm from './CodeForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DeletionConfirmation from './DeletionConfirmation';

dayjs.extend(utc);

export interface CodeCardProps extends UiHStackProps {
  discountCode: DiscountCode
}

const CodeCard: FC<CodeCardProps> = ({ discountCode }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate, isLoading } = useMutation<void, Error, DiscountCodeDeleteRequest>({
    mutationFn: async (request: DiscountCodeDeleteRequest) => {
      await registration.deleteDiscountCode(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<DiscountCode>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneDiscountCode(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId: discountCode.eventId.toString() }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId: discountCode.eventId.toString() }] });
      void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    mutate({ id: discountCode.id });
  }, [discountCode.id, mutate]);

  const onConfirm = useCallback(() => {
    mutateClone(discountCode.id);
  }, [discountCode, mutateClone]);

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={8}
        p={8}
        py={4}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiGrid
          templateColumns={{ base: '2fr 1fr 1fr' }}
          gap={8}
          flexGrow={1}
        >
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body1'} fontWeight={600}>{discountCode.name}</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>{discountCode.description}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Code</UiText>
            <UiText variant='body1'>{discountCode.discountCode}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Valid</UiText>
            <UiHStack>
              <UiText variant='body1'>{dayjs(discountCode.startDate).format('DD MMM YYYY')} - </UiText>
              <UiText variant='body1'>{dayjs(discountCode.endDate).format('DD MMM YYYY')}</UiText>
            </UiHStack>
          </UiStack>
        </UiGrid>
        <UiHStack
          spacing={8}
          justifyContent={'space-between'}
          onClick={() => {}}
        >
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'red'}
            onClick={onToggleConfirmDeletion}
            isLoading={isLoading}
          >
            Delete
          </UiButton>
          <UiStack />
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'primary'}
            onClick={onToggleConfirmClone}>
              Clone
          </UiButton>
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'primary'}
            onClick={onToggle}>
            Edit
          </UiButton>
        </UiHStack>
      </UiHStack>
      <CodeForm
        onClose={onToggle}
        isVisible={isOpen}
        discountCode={discountCode}
        onSaveSuccess={() => {}}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={8}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this discount code?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={discountCode.name} />
    </>
  );
};

export default CodeCard;
