import { useCallback, useMemo } from 'react';
import { useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { type BuildEventRouteName, type RegistrationRouteName } from '@/registration/pages';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from './useRegisterRoute';
import { useEventSettingsQuery } from './useEventSettingsQuery';

export interface RegisterStep {
  routeName: RegistrationRouteName
  isEnable?: boolean
}

export function useBuildEventSteps() {
  const { tenantCode, eventId } = useRegisterRoute();
  const [searchParams] = useSearchParams();
  const { data: eventSettings, isLoading } = useEventSettingsQuery(eventId);
  const navigate = useNavigate();
  const steps: Array<{ routeName: BuildEventRouteName, isEnable: boolean }> = useMemo(
    () => [{
      routeName: 'RegistrationBuildEventGroupRegistration',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventAttendeeCategories',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventDiscountCodes',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventPrivacy',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventPersonalInfoForm',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventMainEventTickets',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventFunctionTickets',
      isEnable: !!eventSettings?.functionTicket?.isEnabled
    }, {
      routeName: 'RegistrationBuildEventWorkshopTickets',
      isEnable: !!eventSettings?.workshopTicket?.isEnabled
    }, {
      routeName: 'RegistrationBuildEventTourTickets',
      isEnable: !!eventSettings?.tourTicket?.isEnabled
    }, {
      routeName: 'RegistrationBuildEventAdditionalInfoForm',
      isEnable: !!eventSettings?.tourTicket?.isEnabled
    }, {
      routeName: 'RegistrationBuildEventPaymentMethods',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventBadge',
      isEnable: true
    }, {
      routeName: 'RegistrationBuildEventOtherSettings',
      isEnable: true
    }],
    [eventSettings]
  );

  const matches = useMatches();
  const currentStepRouteName = useMemo(
    () => matches[matches.length - 1].id as RegistrationRouteName
    , [matches]);

  const stepIndex = useMemo(
    () => steps.findIndex((currentStep) => currentStep.routeName === currentStepRouteName)
    , [currentStepRouteName, steps]);

  const findNextStep = useCallback(() => {
    // The current step is not a valid step since it's not found.
    if (stepIndex === -1) return null;

    const nextStep = steps.find((step, index) => index > stepIndex);
    // return undefined if last step
    return nextStep;
  }, [stepIndex, steps]);

  const findPreviousStep = useCallback(() => {
    if (stepIndex === -1) return null;

    const prevStep = steps.findLast((step, index) => index < stepIndex);
    // return undefined if first step
    return prevStep;
  }, [stepIndex, steps]);

  const toNextStep = useCallback(() => {
    const nextStep = findNextStep();
    if (nextStep) {
      navigate(generatePageUrl(nextStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, findNextStep, navigate, searchParams, tenantCode]);

  const toPreviousStep = useCallback(() => {
    const previousStep = findPreviousStep();
    if (previousStep) {
      navigate(generatePageUrl(previousStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, findPreviousStep, navigate, searchParams, tenantCode]);

  // auto move to next page if page not enable
  // commented cause not needed in buildEvent flow
  // useEffect(() => {
  //   if (steps[stepIndex]?.isEnable === false) toNextStep();
  // }, [stepIndex, steps, toNextStep]);

  return {
    isLoading,
    steps,
    findNextStep,
    findPreviousStep,
    toNextStep,
    toPreviousStep
  };
}
