import { type FC } from 'react';
import {
  type registration
} from '@/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BaseBlockList from '@/base/BlockList';
// import EventRow from './EventRow';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import EventRow from './EventRow';
import { UiSpinner, UiStack } from '@/lib/ui';

dayjs.extend(utc);

export interface EventListProps {
  queryParams: registration.EventListQueryRequest
}

const EventList: FC<EventListProps> = ({
  queryParams
}) => {
  const { data, isLoading } = useEventListQuery();
  if (isLoading) {
    return (
      <UiStack px={4}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  return (
    <BaseBlockList
      bgColor={'#fff'}
    >
      {data?.items?.map((event) => {
        return (
          <EventRow
            key={event.id}
            event={event}
          />
        );
      })}

    </BaseBlockList>
  );
};

export default EventList;
