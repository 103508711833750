import { type FC, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  HStack,
  useDisclosure
} from '@chakra-ui/react';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText,
  UiTag,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter, UiGrid
} from '@/lib/ui';
import { text, locale } from '@/lib/util';
import {
  registration
} from '@/api';
import TicketForm from './TicketForm';
import ManageDiscounts from './ManageDiscounts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ActiveTicketDiscount, type TicketDiscount, type TicketDeleteRequest, type TicketData, type TicketCloneRequest } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DeletionConfirmation from './DeletionConfirmation';

dayjs.extend(utc);
const { createFormatPrice } = locale;

export interface TicketCardProps extends UiHStackProps {
  ticket: registration.TicketData
  ticketType: TicketType
}

const TicketCard: FC<TicketCardProps> = ({
  ticket, ticketType
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isDiscountsOpen, onToggle: onDiscountsToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const priceLocale = 'en-AU';
  const priceCurrency = 'AUD';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate, isLoading } = useMutation<void, Error, TicketDeleteRequest>({
    mutationFn: async (request: TicketDeleteRequest) => {
      await registration.deleteTicket(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      // NOTE: handle error
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<TicketData>, Error, TicketCloneRequest>({
    mutationFn: async (ticketData: TicketCloneRequest) => {
      return await registration.cloneTicket(createTenantAdminApiRequest)(ticketData);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    mutate({ id: ticket.id.toString(), ticketType });
  }, [ticket.id, mutate, ticketType]);

  const onConfirm = useCallback(() => {
    mutateClone({ id: ticket.id.toString(), ticketType });
  }, [ticket.id, mutateClone, ticketType]);

  return (
    <>
      <UiVStack
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        spacing={0}
        py={4}
      >
        <UiStack spacing={4} enableDivider={true}>
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} flexGrow={1} px={8} spacing={4}>
            <UiHStack justifyContent={'space-between'}>
              <UiGrid
                templateColumns={'1fr 1fr 1fr'}
                gap={0}
                flexGrow={1}
                // alignItems={'flex-start'}
              >
                <UiStack spacing={0}>
                  <UiText variant={'title'}>{ticket.name}</UiText>
                  <UiText variant={'body2'} color={'text.secondary'}>{ticket.description ? text.trimText(ticket.description, 50) : ''}</UiText>
                </UiStack>
                <UiStack justifyContent={'flex-start'} flexGrow={1} spacing={0}>
                  <UiText variant={'body2'} color={'text.secondary'}>Price</UiText>
                  <UiText variant={'body1'}>{formatPrice(ticket.price * 100)}</UiText>
                </UiStack>
                <UiStack justifyContent={'flex-start'} flexGrow={1} spacing={0}>
                  <UiText variant={'body2'} color={'text.secondary'}>Stock</UiText>
                  <UiText variant={'body1'}>{ticket.stock ?? ''}</UiText>
                </UiStack>
              </UiGrid>
              <HStack spacing={12}>
                <UiButton
                  px={0}
                  variant={'ghost'}
                  {...uiStyles.hover}
                  colorScheme={'red'}
                  onClick={onToggleConfirmDeletion}
                  isLoading={isLoading}
                >
                  Delete
                </UiButton>
                <UiStack width={4} />
                <UiButton
                  px={0}
                  variant={'ghost'}
                  {...uiStyles.hover}
                  colorScheme={'primary'}
                  onClick={onToggleConfirmClone}
                >
                  Clone
                </UiButton>
                <UiButton
                  variant={'ghost'}
                  colorScheme={'primary'}
                  px={0}
                  onClick={onToggle}
                >
                  Edit
                </UiButton>
              </HStack>
            </UiHStack>
            <UiHStack>
              {Array.isArray(ticket.attendeeCategories) && ticket.attendeeCategories.length > 0 ? (
                <UiHStack>
                  {ticket.attendeeCategories.map((attendeeCategory) => {
                    return (
                      <UiTag key={attendeeCategory.id} p={2} colorScheme={'gray'}>{attendeeCategory.name}</UiTag>
                    );
                  })}
                </UiHStack>
              ) : (
                <UiText color={'red.500'}>Unavailable to any attendees. Please choose attendee categories.</UiText>
              )}
            </UiHStack>
          </UiStack>
          <UiStack px={8}>
            <UiHStack alignItems={'center'} justifyContent={'space-between'}>
              <UiHStack justifyContent={'flex-start'} flexGrow={1} alignItems={'center'} spacing={4}>
                <UiText variant={'body1'} color={'text.secondary'}>Discounts</UiText>
                {(Array.isArray(ticket.ticketDiscounts) && ticket.ticketDiscounts.length > 0)
                  ? (
                    <UiHStack spacing={2}>
                      {(ticket.ticketDiscounts.filter((discountDelegateType: TicketDiscount) => discountDelegateType.active) as ActiveTicketDiscount[]).map((discountDelegateType: ActiveTicketDiscount) => {
                        return (
                          <UiTag key={discountDelegateType.id} p={2} colorScheme={'gray'}>{discountDelegateType.discount.name}</UiTag>
                        );
                      })}
                    </UiHStack>
                  )
                  : (
                    <UiText variant={'body1'} color={'red.500'}>None</UiText>
                  )}
              </UiHStack>
              <HStack>
                <UiButton px={0} variant={'ghost'} colorScheme={'primary'} onClick={onDiscountsToggle}>Edit</UiButton>
              </HStack>
            </UiHStack>
          </UiStack>
        </UiStack>
      </UiVStack>
      <TicketForm
        eventId={eventId}
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        ticket={ticket}
        ticketType={ticketType}
      />
      <ManageDiscounts
        onClose={onDiscountsToggle}
        isVisible={isDiscountsOpen}
        onSaveSuccess={() => {}}
        ticketId={ticket.id}
        ticketType={ticketType}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={8}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this ticket?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={ticket.name} />
    </>
  );
};

export default TicketCard;
