import { useCallback, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
// import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
// import { UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPencilSimple, UiStack, UiText, uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import { loadVisitorInfoFormResponse, type InfoFormResponse, InfoFormType, type InfoForm, type InfoFormByAttendeeCategoryQuery } from '@/api/registration';
import { registration } from '@/api';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import InformationList from './InformationList';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useVisitor } from '@/app/ProviderVisitor';

const PersonalInfoReview: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tenantCode, eventId } = useRegisterRoute();
  const { visitorId } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>(
    [registration.infoFormByAttendeeCategoryQueryKey, { visitorId, formType: InfoFormType.PERSONAL }],
    async () => {
      const params: InfoFormByAttendeeCategoryQuery = { formType: InfoFormType.PERSONAL, visitorId: visitorId! };
      const result = await registration.loadInfoFormByAttendeeCategory(createTenantApiRequest)(params);
      return {
        ...result.item,
        config: JSON.parse(result.item.config) as InfoForm['config']
      };
    },
    {
      enabled: !isApiPreparing && !!visitorId
    }
  );

  const personalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [registration.infoFormResponseQueryKey, { visitorId: visitorId!, type: InfoFormType.PERSONAL }],
    queryFn: async () => {
      const response = await loadVisitorInfoFormResponse(createTenantApiRequest)({
        visitorId: visitorId!,
        type: InfoFormType.PERSONAL
      });
      return response.item;
    },
    enabled: !!visitorId && !isApiPreparing
  });

  const toModify = useCallback(
    () => {
      navigate(generatePageUrl(
        'RegistrationRegisterPersonalInfo',
        { tenantCode, eventId },
        { ...Object.fromEntries(searchParams), review: true }
      ));
    },
    [eventId, navigate, searchParams, tenantCode]
  );

  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Personal information
        </UiText>
        <UiButton
          rightIcon={(<UiIconPencilSimple color={'primary.500'} size={'xl'} weight={'bold'} />)}
          variant={'ghost'}
          colorScheme={'primary'}
          fontSize={'md'}
          fontWeight={'bold'}
          onClick={toModify}
        >
          Modify
        </UiButton>
      </UiHStack>
      <QueryContainer query={infoFormByAttendeeCategoryQuery}>
        {(infoForm) => (
          <QueryContainer query={personalInfoQuery}>
            {(personalInfoForm) => (
              <InformationList
                fieldsMetadata={infoForm.config.fieldsMetadata}
                response={personalInfoForm.response}
              />
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </UiStack>
  );
};

export default PersonalInfoReview;
