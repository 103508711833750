import { type FC, type PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type Visitor } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useQuery } from '@tanstack/react-query';

interface VisitorContextValue {
  visitorId?: number
  setVisitorId: (visitorId: number | undefined) => void
  visitor?: Visitor
  isLoadingVisitor: boolean
}

const VisitorContext = createContext<VisitorContextValue>({
  visitorId: undefined,
  setVisitorId: () => {},
  visitor: undefined,
  isLoadingVisitor: false
});

export const ProviderVisitor: FC<PropsWithChildren> = ({
  ...props
}) => {
  const { eventId } = useRegisterRoute();
  const [visitorId, setVisitorId] = useLocalStorage<number | undefined>(`arinex_one_registration_visitor_event_${eventId}`, undefined);
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data: visitor, isLoading: isLoadingVisitor } = useQuery<Visitor, Error>({
    queryKey: [registration.visitorQueryKey, { visitorId }],
    queryFn: async () => {
      if (visitorId === undefined) {
        throw new Error('visitorId is undefined');
      }
      const response = await registration.loadVisitor(createTenantApiRequest)({ visitorId });
      return response.item;
    },
    enabled: !!visitorId && !isApiPreparing
  });

  const value: VisitorContextValue = useMemo(() => ({
    visitorId,
    setVisitorId,
    visitor,
    isLoadingVisitor
  }), [setVisitorId, visitorId, visitor, isLoadingVisitor]);
  return (
    <VisitorContext.Provider value={value} {...props} />
  );
};

export function useVisitor() {
  const { visitorId, setVisitorId, visitor, isLoadingVisitor } = useContext(VisitorContext);
  return {
    visitorId,
    setVisitorId,
    visitor,
    isLoadingVisitor
  };
}
