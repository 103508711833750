import { useCallback, useState, type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ordersQueryKey, type TableOrder } from '@/api/registration';
import { registration } from '@/api';
import { type PaginationState, createColumnHelper } from '@tanstack/react-table';
import { DataTable } from '@/base/DataTable/DataTable';
import { UiHStack, UiIconFunnel, UiStack, UiText, UiVStack, uiStyles, UiSpinner } from '@/lib/ui';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import OrderFilter, { type FilterFormData } from './OrderFilter';
dayjs.extend(advancedFormat);

const columnHelper = createColumnHelper<TableOrder>();

const columns = [
  columnHelper.accessor('event', {
    cell: (info) => info.getValue(),
    header: 'Event'
  }),
  columnHelper.accessor('tickets', {
    cell: (info) => info.getValue().join(', '),
    header: 'Ticket'
  }),
  columnHelper.accessor('attendee', {
    cell: (info) => (
      <UiVStack alignItems='flex-start'>
        <UiText>{info.getValue().name}</UiText>
        <UiText>{info.getValue().email}</UiText>
      </UiVStack>
    ),
    header: 'Attendee'
  }),
  columnHelper.accessor('totalPrice', {
    cell: (info) => Number(info.getValue()).toFixed(2),
    header: 'Amount'
  }),
  columnHelper.accessor('purchasedAt', {
    cell: (info) => info.getValue() ? dayjs(info.getValue()).format('hh:mm:ss Do MMM YYYY, Z') : '',
    header: 'Purchased At'
  })
];
export interface OrderTableProps {
}

const OrderTable: FC<OrderTableProps> = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterFormData>({});
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 20 });
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data, isLoading } = useQuery<ApiResponse<TableOrder>, Error>({
    queryKey: [ordersQueryKey, { page: pagination.pageIndex, filterParams }],
    queryFn: async (): Promise<ApiResponse<TableOrder>> =>
      await registration.loadOrders(createTenantAdminApiRequest)({ page: pagination.pageIndex, ...filterParams }),
    keepPreviousData: true
  });

  const onApply = useCallback(async (params: FilterFormData) => {
    setFilterParams(params);
    setShowFilter(false);
  }, []);

  return (
    <UiStack flexGrow={1} spacing={4} alignItems={'stretch'}>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Orders
        </UiText>
        <UiStack {...uiStyles.hover} onClick={() => { setShowFilter(true); }}>
          <UiIconFunnel size={'3xl'} color={'primary.400'} />
        </UiStack>
      </UiHStack>
      { isLoading ? (
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      ) : (
        <DataTable<TableOrder>
          data={data?.items ?? []}
          columns={columns}
          pagination={pagination}
          setPagination={setPagination}
          total={data?.pagination?.total}
        />
      )}
      <OrderFilter
        isVisible={showFilter}
        onApply={onApply}
        onClose={() => setShowFilter(false)}
      />
    </UiStack>
  );
};

export default OrderTable;
