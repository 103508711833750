// import 'react-grid-layout/css/styles.css';
// import 'react-resizable/css/styles.css';
import { useCallback, useState } from 'react';
import { forwardRef } from '@chakra-ui/react';
import {
  UiBox,
  type UiBoxProps,
  UiHStack,
  UiIconPencilSimple,
  UiIconX,
  UiStack,
  // UiStackProps,
  uiStyles
} from '@/lib/ui';
import { type FieldMetadata } from './Field';
import BaseFormBuilderFieldSectionBreak from '@/base/FormBuilder/FieldSectionBreak';
import BaseFormBuilderFieldInput from '@/base/FormBuilder/FieldInput';
import BaseFormBuilderFieldSelect from '@/base/FormBuilder/FieldSelect';
import FieldDeletionConfirmation from './FieldDeletionConfirmation';
import FieldDrawer from './FieldDrawer';

export interface GridItemProps extends UiBoxProps {
  fieldMetadata: FieldMetadata
  onDelete: (fieldId: string) => void
  onUpdate: (fieldMetadata: FieldMetadata) => void
}

const GridItem = forwardRef<GridItemProps, 'div'>(({
  fieldMetadata,
  onDelete,
  onUpdate,
  children,
  ...props
}, ref) => {
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const askToConfirmDeletion = useCallback(
    () => {
      setShowDeletionAlert(true);
    },
    [setShowDeletionAlert]
  );
  const deleteField = useCallback(
    () => {
      onDelete(fieldMetadata.id);
      // setShowDeletionAlert(false);
    },
    [onDelete, fieldMetadata.id]
  );
  const cancelDeletion = useCallback(
    () => {
      setShowDeletionAlert(false);
    },
    [setShowDeletionAlert]
  );
  const onFieldUpdate = useCallback(
    (newFieldMetadata: FieldMetadata) => {
      onUpdate(newFieldMetadata);
      setShowEditForm(false);
      // setShowDeletionAlert(false);
    },
    [onUpdate, setShowEditForm]
  );

  return (
    <>
      <UiBox
        ref={ref}
        p={4}
        px={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        // {...uiStyles.hover}
        _hover={{
          cursor: 'pointer'
        }}
        {...props}
      >
        <UiHStack position={'absolute'} right={0} top={'-36px'} spacing={2}>
          {fieldMetadata.canEdit && (
            <UiStack
              onClick={() => { setShowEditForm(true); }}
              _hover={{
                transform: 'scale(1.2)',
                transitionDuration: '0.2s',
                transitionTimingFunction: 'ease-in-out'
              }}
              p={2}
            >
              <UiIconPencilSimple size={'2xl'} color={'primary.400'}/>
            </UiStack>
          )}
          {fieldMetadata.canDelete && (
            <UiStack
              onClick={askToConfirmDeletion}
              _hover={{
                transform: 'scale(1.2)',
                transitionDuration: '0.2s',
                transitionTimingFunction: 'ease-in-out'
              }}
              p={2}
            >
              <UiIconX size={'2xl'} color={'red.500'}/>
            </UiStack>
          )}
        </UiHStack>
        {/* No idea why if not passing the children here, the resizeble handle from the grid won't show up. Even the children here is empty. */}
        {children}
        {fieldMetadata.type === 'input' && (
          <BaseFormBuilderFieldInput
            label={fieldMetadata.label}
            type={'text'}
            isRequired={fieldMetadata.isRequired}
            onClick={() => {}}
          />
        )}
        {fieldMetadata.type === 'select' && (
          <BaseFormBuilderFieldSelect
            label={fieldMetadata.label}
            isMultiple={false}
            isRequired={fieldMetadata.isRequired}
            onClick={() => {}}
          />
        )}
        {fieldMetadata.type === 'sectionBreak' && (
          <BaseFormBuilderFieldSectionBreak
            label={fieldMetadata.label}
            description={fieldMetadata.description ?? ''}
          />
        )}
      </UiBox>
      <FieldDeletionConfirmation
        isOpen={showDeletionAlert}
        onClose={cancelDeletion}
        onConfirm={deleteField}
        fieldName={fieldMetadata.label}
      />
      <FieldDrawer
        title={'Edit form element'}
        initFieldType={fieldMetadata.type}
        initFieldMetadata={fieldMetadata}
        isOpen={showEditForm}
        size={'xl'}
        onClose={() => { setShowEditForm(false); }}
        onFieldUpdate={onFieldUpdate}
      />
    </>
  );
});
export default GridItem;
