import { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
import { formBuilderSelectOptionTypeOptions, FormBuilderSelectOptionType } from '@/api/constant/formBuilder';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormSelectField, { Option } from '@/base/Form/SelectField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import { type FieldMetadataSelect } from './Field';

export interface FieldSelectFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataSelect) => void
  fieldMetadata?: FieldMetadataSelect
}

export interface FieldAttributes {
  label: string
  optionType: FormBuilderSelectOptionType
  options: string
  isMultiple: boolean
  helpText?: string
  isRequired: boolean
}

interface Errors {
  label?: string
  optionType?: string
  options?: string
  isMultiple?: boolean
  helpText?: string
  isRequired?: string
}

const optionTypeHelpTexts = {
  [FormBuilderSelectOptionType.Custom]: 'Create your own options.',
  [FormBuilderSelectOptionType.Country]: 'A built-in full list of all the countries. No need to create your own.'
};

const FieldSelectForm: FC<FieldSelectFormProps> = ({
  onSave,
  fieldMetadata = undefined
}) => {
  const initValues: FieldAttributes = {
    label: fieldMetadata?.label ?? '',
    optionType: fieldMetadata?.optionType ?? FormBuilderSelectOptionType.Custom,
    isMultiple: fieldMetadata?.isMultiple ?? false,
    options: fieldMetadata?.options ?? '',
    helpText: fieldMetadata?.helpText ?? '',
    isRequired: true
  };

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        if (!values.label) {
          errors.label = 'Label is required';
        } else if (values.label.length > 100) {
          errors.label = 'Label can not be more than 100 charactors.';
        }
        if (values.optionType === FormBuilderSelectOptionType.Custom && !values.options) {
          errors.options = 'Options can not be empty.';
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        // setSubmitting(false);
        onSave({
          ...fieldMetadata,
          id: fieldMetadata?.id ?? uuidv4(),
          type: 'select',
          label: values.label,
          options: values.options,
          optionType: values.optionType,
          isMultiple: values.isMultiple,
          helpText: values.helpText,
          isRequired: values.isRequired,
          canEdit: true,
          canDelete: true
        });
      }}
    >
      {({ values }) => (
        <Form
          style={{
            display: 'flex',
            marginTop: 0
          }}
        >
          <UiStack flexGrow={1}>
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'label'}
                label={'Field label'}
              />
              <BaseFormSelectField
                name={'optionType'}
                label={'Option type'}
                defaultValue={formBuilderSelectOptionTypeOptions[0]}
                options={formBuilderSelectOptionTypeOptions}
                helperText={optionTypeHelpTexts[values.optionType] ?? ''}
              />
              {values.optionType === FormBuilderSelectOptionType.Custom && (
                <BaseFormTextareaField
                  name={'options'}
                  label={'Options'}
                  height={'200px'}
                  placeholder={'Sample option 1, \nSample option 2,'}
                  helperText={'Add options in each line and separated by comma.'}
                />
              )}
              <BaseFormSelectField
                name={'isMultiple'}
                label={'Is this a multiple select?'}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
              />
              <BaseFormInputField
                name={'helpText'}
                label={'helpText'}
                isRequired={false}
                helperText={'This the text you want to show underneath the input field to provide some context or examples.'}
              />
              <BaseFormSelectField
                name={'isRequired'}
                label={'Is this field mandatory?'}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
              />
            </BaseFormFieldGroup>
            <UiStack flexDirection={'row-reverse'} pt={12}>
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
                Save
              </UiButton>
            </UiStack>
          </UiStack>
        </Form>
      )}
    </Formik>
  );
};

export default FieldSelectForm;
