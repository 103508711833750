import { type FC } from 'react';
import { Input, type InputProps } from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui/styles';

export interface UiInputProps extends InputProps {
}

export const UiInput: FC<UiInputProps> = (props) => {
  return (
    <Input
      // maxWidth={'800px'}
      // height={'48px'}
      size={'md'}
      borderRadius={uiStyles.formElementBorderRadius}
      borderColor={'gray.300'}
      focusBorderColor={'primary.400'}
      bgColor={'#fff'}
      // borderRadius={'none'}
      // borderStyle={'none'}
      // borderBottomColor={'gray.300'}
      // borderBottomStyle={'solid'}
      // borderBottomWidth={'1px'}
      // // color={'text.primary'}
      // _focus={{
      //   borderStyle: 'none',
      //   borderBottomColor: 'primary.400',
      //   borderBottomWidth: '1px',
      //   borderBottomStyle: 'solid',
      // }}
      // px={0}
      {...props}
    />
  );
};
