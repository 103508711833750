/**
 * Notes We should always use these 2 hooks (useEventSettingsQuery, useEventSettingsSegmentMutation) to
 * manage all the event settings related data in all the components during the registration setup.
 */
import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  UiGrid
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import Setting from './Setting';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';

const GroupRegistration: FC = () => {
  // const [showFilter, setShowFilter] = useState<boolean>(false);
  const { eventId } = useRegisterRoute();
  const { mutate, isLoading } = useIsSetMutation();

  const { toNextStep } = useBuildEventSteps();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'groupRegistration', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={
          <ManageEventSteps
            eventId={eventId}
            activeStep={'groupRegistration'}
          />
        }
        message={''}
      >
        {/* <BaseDividerHorizontal height={1}/> */}
        <UiStack
          // pt={4}
          p={8}
          spacing={4}
          flexGrow={1}
        >
          <UiText>
            Enable / disable the group registration.
          </UiText>
          <UiGrid
            templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }}
            gap={8}
          >
            <Setting />
          </UiGrid>
        </UiStack>
        <UiHStack
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
          p={8}
        >
          <UiStack flexGrow={1} />
          <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} isLoading={isLoading} onClick={onNext}>
            Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default GroupRegistration;
