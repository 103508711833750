import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSimpleGrid
} from '@/lib/ui';
import TeamCard from './TeamCard';
import TeamForm from './TeamForm';
import { generatePageUrl } from '@/app/pages';

// export const apps = {
//   ACCOUNT: 1,
//   REGISTRATION: 2,
//   ABSTRACT: 3,
// }

export interface TeamListProps extends UiBoxProps {
}

const TeamList: FC<TeamListProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack spacing={4}>
        <UiSimpleGrid spacing={8} columns={3}>
          <TeamCard
            onClick={onToggle}
            name={'Admin'}
            description={'Manages all modules'}
            numeOfMembers={2}
            navLink={generatePageUrl('AccountAccessTeamInfo', { tenantCode: 'dev', teamId: '1' })}
          />
          <TeamCard
            onClick={onToggle}
            name={'Rego management'}
            description={'Manages the registration module'}
            numeOfMembers={3}
            navLink={generatePageUrl('AccountAccessTeamInfo', { tenantCode: 'dev', teamId: '2' })}
          />
        </UiSimpleGrid>
      </UiStack>
      <TeamForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default TeamList;
