import { type FC } from 'react';
import BaseRouterLink, { type LinkProps as BaseRouterLinkProps } from '@/base/Router/Link';
import { UiStack, UiText, uiStyles, type UiTextProps } from '@/lib/ui';

export interface RouterLinkProps extends BaseRouterLinkProps {
  children: string
  description?: string
  textColor?: UiTextProps['color']
  descriptionColor?: UiTextProps['color']
}

const RouterLink: FC<RouterLinkProps> = ({
  children,
  description = undefined,
  textColor = 'primary.500',
  descriptionColor = 'text.secondary',
  ...props
}) => {
  return (
    <BaseRouterLink
      {...props}
    >
      <UiStack spacing={0} {...uiStyles.hover}>
        <UiText variant={'body1'} color={'primary.500'}>{children}</UiText>
        {!!description && (
          <UiText variant={'caption'} color={'text.secondary'}>{description}</UiText>
        )}
      </UiStack>
    </BaseRouterLink>
  );
};

export default RouterLink;
