import { type FC, useMemo } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  UiStack,
  UiText,
  UiIconCurrencyCircleDollar,
  uiStyles,
  UiHStack,
  UiTag
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import { locale } from '@/lib/util';
import { type TicketData } from '@/api/registration';
import TicketDrawer from './TicketDrawer';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
const { createFormatPrice } = locale;

export interface TicketProps {
  ticket: TicketData
}

const Ticket: FC<TicketProps> = ({ ticket }) => {
  const { eventId } = useRegisterRoute();
  const { data: event } = useEventQuery(eventId);
  const { isOpen, onToggle } = useDisclosure();
  const validToDay = useMemo(() =>
    dayjs.tz(ticket.validTo, event?.tzInfoName ?? 'America/Los_Angeles').add(1, 'day')
  , [event?.tzInfoName, ticket.validTo]);
  const isExpired = useMemo(() => validToDay.isBefore(dayjs()), [validToDay]);
  const timeToNow = useMemo(() => validToDay.fromNow(), [validToDay]);
  const priceLocale = 'en-AU';
  const priceCurrency = 'AUD';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );

  const price = useMemo(
    () => formatPrice((ticket?.price ?? 0) * 100),
    [formatPrice, ticket?.price]
  );
  return (
    <>
      <UiStack
        bgColor={isExpired || ticket.currentStock <= 0 ? 'gray.300' : '#fff'}
        borderRadius={uiStyles.borderRadius}
        {...isExpired || ticket.currentStock <= 0 ? {} : uiStyles.hover}
        onClick={isExpired || ticket.currentStock <= 0 ? undefined : onToggle}
        enableDivider={true}
        spacing={6}
        p={8}
      >
        <UiStack>
          <BaseTextList>
            <BaseTextListItem variant='h6'>{ticket?.name}</BaseTextListItem>
            <BaseTextListItem variant='body1' color={'text.secondary'}>{ticket?.description}</BaseTextListItem>
          </BaseTextList>
          <UiHStack>
            {
              isExpired
                ? <UiTag p={2} colorScheme="gray">No longer available</UiTag>
                : <UiTag p={2} colorScheme="green">{timeToNow}</UiTag>
            }
            {ticket.currentStock <= 0 && (<UiTag p={2} colorScheme="gray">Out of stock</UiTag>)}
          </UiHStack>
        </UiStack>
        <UiHStack>
          <UiIconCurrencyCircleDollar size={'2xl'} color={'text.primary'} />
          <UiText flexGrow={1} pl={2}>Price</UiText>
          <UiText>{price}</UiText>
        </UiHStack>
      </UiStack>
      <TicketDrawer
        ticket={ticket}
        price={price}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default Ticket;
