import { type FC } from 'react';
import {
  UiTable,
  UiThead,
  UiTbody,
  UiTr,
  UiTh,
  UiTd,
  UiTableContainer,
  uiStyles,
  UiIconPencilSimple,
  UiStack,
  UiText
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface AdminUserListProps {
}

const thStyles = {
  // fontSize: 'lg',
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6
};

export type AdminUserStatus = 'active' | 'inactive' | 'suspended';

const tableRows = [
  ['Derek Li', 'dli@arinexgroup.com', 'Account owner', 'active'],
  ['Tony Fu', 'tfu@arinexgroup.com', 'Admin', 'active'],
  ['Anthony James', 'aj@google.com', 'Registration', 'inactive']
];

const AdminUserList: FC<AdminUserListProps> = () => {
  return (
    <>
      <UiTableContainer>
        <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
          {/* <UiTableCaption>Imperial to metric conversion factors</UiTableCaption> */}
          <UiThead>
            <UiTr>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Name</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Email</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Team</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Status</UiText></UiTh>
              <UiTh {...thStyles}></UiTh>
            </UiTr>
          </UiThead>
          <UiTbody>
            {tableRows.map((row, rowIndex) => {
              return (
                <UiTr key={rowIndex}>
                  {row.map((col, colIndex) => {
                    return (
                      <UiTd {...tdStyles} key={colIndex}>{col}</UiTd>
                    );
                  })}
                  <UiTd {...tdStyles}>
                    <UiStack {...uiStyles.hover}>
                      <UiIconPencilSimple size={'3xl'} color={'primary.500'}/>
                    </UiStack>
                  </UiTd>
                </UiTr>
              );
            })}
          </UiTbody>
        </UiTable>
      </UiTableContainer>
    </>
  );
};

export default AdminUserList;
