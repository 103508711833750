import { type FC } from 'react';
import { UiSimpleGrid, UiSpinner, UiStack, uiStyles, UiText } from '@/lib/ui';
import PackageBasic from './Package/Pack1';
import PackageStandard from './Package/Pack2';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type SubscriptionEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { SubscriptionPackage } from '@/api/constant/subscription';
import AddonList from '@/account/subscription/PlanManagement/AddonList';
import ServicePackList from '@/account/subscription/PlanManagement/ServicePackList';
import CurrencyTag from '@/account/component/CurrencyTag';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface ManagementProps {
  subscriptionEditor: SubscriptionEditor
}

const Management: FC<ManagementProps> = ({
  subscriptionEditor
}) => {
  const {
    subscription,
    isLoading,
    selectPackage,
    toggleAddon,
    toggleServicePack
  } = subscriptionEditor;

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!subscription) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load the subscription editor.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <>
      <UiStack alignItems={'stretch'} py={8} spacing={8} pb={16}>
        <UiStack justifyContent={'flex-start'} alignItems={'flex-start'} px={8}>
          <CurrencyTag currency={'AUD'} />
        </UiStack>
        <UiStack
          alignItems={'stretch'}
          px={8}
          flexGrow={1}
          spacing={4}
        >
          <UiText variant={'h6'}>Choose a package</UiText>
          <UiSimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 4, lg: 8 }}>
            <PackageBasic
              subscription={subscription}
              onSelect={() => selectPackage(SubscriptionPackage.Pack1)}
            />
            <PackageStandard
              subscription={subscription}
              onSelect={() => selectPackage(SubscriptionPackage.Pack2)}
            />
          </UiSimpleGrid>
        </UiStack>
        <UiStack
          pt={4}
          px={8}
          spacing={4}
        >
          <UiStack spacing={0}>
            <UiText variant={'h6'}>
              Manage add-ons
            </UiText>
            <UiText>
              Add-ons are billed every 3 months until disabled.
            </UiText>
          </UiStack>
          <BaseDividerHorizontal height={2}/>
          <UiStack
            // borderWidth={'1px'}
            // borderColor={'gray.300'}
            // borderStyle={'solid'}
            spacing={8}
            // p={8}
            borderRadius={uiStyles.borderRadius}
          >
            <UiStack spacing={4}>
              <UiText variant={'body1'} fontWeight={'bold'}>Choose one service pack</UiText>
              <UiStack
                borderRadius={uiStyles.borderRadius}
                spacing={8}
              >
                <ServicePackList
                  subscription={subscription}
                  toggleServicePack={toggleServicePack}
                />
              </UiStack>
            </UiStack>
            <BaseDividerHorizontal height={1}/>
            <UiStack spacing={4}>
              <UiText variant={'body1'} fontWeight={'bold'}>Choose other add-ons</UiText>
              <UiStack
                // p={8}
                borderRadius={uiStyles.borderRadius}
                // borderWidth={'1px'}
                // borderColor={'gray.300'}
                // borderStyle={'solid'}
                spacing={8}
              >
                <AddonList
                  subscription={subscription}
                  toggleAddon={toggleAddon}
                />
              </UiStack>
            </UiStack>
          </UiStack>
        </UiStack>
      </UiStack>
    </>
  );
};

export default Management;
