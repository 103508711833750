import { type FC } from 'react';
import { Form } from 'formik';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerBody,
  UiButton,
  UiDrawerCloseButton,
  type UiDrawerProps
} from '@/lib/ui';

export interface DrawerProps extends UiDrawerProps {
  title: string
  size?: UiDrawerProps['size']
  isLoading?: boolean
}

const Drawer: FC<DrawerProps> = ({
  title,
  children,
  size = '2xl',
  isLoading = false,
  ...props
}) => {
  return (
    <UiDrawer placement={'right'} size={size} {...props}>
      <Form
        style={{
          display: 'flex'
        }}
      >
        <UiDrawerOverlay />
        <UiDrawerContent>
          <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
          <UiDrawerBody p={0}>
            <UiStack alignItems={'stretch'} p={8}>
              <UiText variant={'h6'} pb={8}>{title}</UiText>
              {children}
            </UiStack>
          </UiDrawerBody>
          <UiDrawerFooter p={0}>
            <UiStack flexDirection={'row-reverse'} p={8}>
              {isLoading
                ? (
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'gray'}>
                    Saving...
                  </UiButton>
                )
                : (
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
                    Save
                  </UiButton>
                )}
            </UiStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </Form>
    </UiDrawer>
  );
};

export default Drawer;
