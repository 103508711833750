import {
  callApi,
  type ApiRequest,
  type ApiResponse
} from '@/api/tenantClient';
import { type Order } from './order';

export const paymentIntentQueryKey = 'registration.paymentIntent';
export const paymentConfirmQueryKey = 'registration.paymentConfirm';

export type Gateway = 'stripe' | 'securepay' | 'invoice';

export interface PaymentIntent {
  id: string
  clientSecret: string
  publishableKey: string
  order: Order
}

export interface PaymentRequest {
  locale: string
  gateway: Gateway
}

export interface PreparePaymentRequest extends PaymentRequest {
  cartId: number
}

export function preparePayment(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: PreparePaymentRequest
  ): Promise<ApiResponse<PaymentIntent>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/payments/prepare_payment';
    request.payload = {
      payment: params
    };
    return await callApi<PaymentIntent>(request);
  };
}
