import { useState, type FC, useEffect, type Dispatch, type SetStateAction, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { type Stripe, loadStripe } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type PreparePaymentRequest, type PaymentIntent } from '@/api/registration/payment';
import { registration } from '@/api';
import { type ApiResponse } from '@/api/tenantClient';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import PayByStripe from './PayByStripe';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useVisitor } from '@/app/ProviderVisitor';

export interface PayByStripeWrapperProps {
  setErrors: Dispatch<SetStateAction<string[]>>
}

const PayByStripeWrapper: FC<PayByStripeWrapperProps> = ({
  setErrors,
  ...props
}) => {
  const [options, setOptions] = useState<PaymentIntent>({
    id: '',
    clientSecret: '',
    publishableKey: '',
    order: {
      id: 0,
      secureHash: '',
      status: '',
      serial: '',
      event: '',
      tickets: [],
      attendee: { name: '', email: '' },
      totalPrice: '',
      purchasedAt: '',
      eventId: ''
    }
  });
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const queryClient = useQueryClient();
  const { visitorId } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data: cart, isLoading: isCartLoading } = useCartQuery(visitorId);

  const { mutate: preparePaymentMutate, isLoading: isPreparePaymentLoading } = useMutation<ApiResponse<PaymentIntent>, Error, PreparePaymentRequest>({
    mutationFn: async (preparePaymentData: PreparePaymentRequest) => {
      return await registration.preparePayment(createTenantApiRequest)(preparePaymentData);
    },
    onSuccess: (paymentIntent) => {
      void queryClient.invalidateQueries({ queryKey: [registration.paymentIntentQueryKey] });
      setOptions(paymentIntent.items[0]);
      setStripePromise(loadStripe(paymentIntent.items[0].publishableKey));
    },
    onError: (error: any) => {
      setErrors([error.message ?? 'Failed to save the host.']);
    }
  });

  // NOTE: This is a workaround to avoid calling preparePaymentMutate on initial render
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (cart) {
        const preparePaymentData: PreparePaymentRequest = {
          cartId: cart.id,
          locale: 'aud',
          gateway: 'stripe'
        };
        preparePaymentMutate(preparePaymentData);
      }
    }
  }, []);

  return (
    <>
      {options?.clientSecret && stripePromise && cart && (
        <Elements stripe={stripePromise} options={options}>
          <PayByStripe
            setErrors={setErrors}
            options={options}
            cart={cart}
          />
        </Elements>
      )}
    </>
  );
};

export default PayByStripeWrapper;
