import { type FC, useMemo, useCallback, useState } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton,
  UiIconCheck,
  UiStack
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import React from 'react';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { type Layout } from 'react-grid-layout';
import RegisterLayout from '@/registration/component/Register/Layout';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

export interface FormPreviewProps {
  fieldsLayout: Layout[]
  fieldsMetadata: FieldMetadata[]
}

const FormPreview: FC<FormPreviewProps> = ({ fieldsLayout, fieldsMetadata }) => {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const utils = useMemo(() => createUtils(fieldsMetadata ?? []), [fieldsMetadata]);

  const submitForm = useCallback(async (values: FormData) => {
    setIsFakeLoading(true);
    setIsButtonClicked(false);
    setTimeout(() => {
      setIsFakeLoading(false);
      setIsButtonClicked(true);
    }, 1500);
  }, []);
  return (
    <RegisterLayout>
      <Formik
        initialValues={utils.getFieldsValues()}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values: FormData) => {
          const errors: Errors = {};
          // This is an example to check 'required' fields. We might validator others in the future.
          for (const fieldId in values) {
            const fieldMetadata = utils.getFieldMetadata(fieldId);
            // console.log(fieldMetadata);
            if (fieldMetadata) {
            // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
              if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
                errors[fieldId] = 'The field is required';
              }
            }
          }
          return errors;
        }}
        onSubmit={async (
          values,
          { setSubmitting }: FormikHelpers<FormData>
        ) => {
          setSubmitting(true);
          await submitForm(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <BaseTransitionSlideLeft>
            <UiStack flexGrow={1}>
              <FormGenerator
                fieldsLayout={fieldsLayout}
                fieldsMetadata={fieldsMetadata}
              />
            </UiStack>
          </BaseTransitionSlideLeft>
          <UiStack height={32} />
          <LayoutFooter justifyContent={'flex-end'}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={isButtonClicked && !isFakeLoading ? 'green' : 'primary'} type={'submit'} isLoading={isFakeLoading}>
              {isButtonClicked && !isFakeLoading ? <UiIconCheck color={'white'} size={'3xl'} /> : 'Test'}
            </UiButton>
          </LayoutFooter>
        </Form>
      </Formik>
    </RegisterLayout>
  );
};

export default React.memo(FormPreview);
