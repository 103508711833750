import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import AddressForm from './AddressForm';

const Checkout: FC = () => {
  return (
    <RegisterLayout>
      <AddressForm />
    </RegisterLayout>
  );
};

export default Checkout;
