import { type FC } from 'react';
// import { Player } from '@lottiefiles/react-lottie-player';
import {
  UiBox,
  type UiBoxProps,
  uiStyles,
  UiIconUserCircle, UiIconDotsNine, UiIconUser
} from '@/lib/ui';
// import smartButtonLottie from '@/lottie/white-robot.json';

export interface SmartButtonProps extends UiBoxProps {
}

const SmartButton: FC<SmartButtonProps> = (props) => {
  return (
    <UiBox
      // p={4}
      // borderRadius={'100%'}
      // bgColor={'primary.400'}
      // p={4}
      // px={8}
      {...uiStyles.hover}
      {...props}
    >
      {/* <Player
        autoplay
        loop
        src={smartButtonLottie}
        style={{ height: '72px', width: '72px' }}
      /> */}
      <UiBox
        p={2}
        borderRadius={'50%'}
        // borderRadius={uiStyles.borderRadius}
        borderWidth={'3px'}
        borderColor={'primary.500'}
        {...uiStyles.hoverFlex({ bgColor: 'primary.100', scale: 1.1 })}
      >
        <UiIconUser size={'3xl'} color={'primary.500'} weight={'bold'} />
      </UiBox>
      {/* <UiIconUserCircle size={48} color={'primary.500'} weight={'thin'}/> */}
    </UiBox>
  );
};

export default SmartButton;
