import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconHeadset } from '@/lib/ui';

export interface ServicePack3Props {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const ServicePack3Card: FC<ServicePack3Props> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconHeadset size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'Service pack 3'}
      isSelected={isSelected}
    />
  );
};
export default ServicePack3Card;
