import { type ElementType, type FC, type ReactNode, useMemo } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiStack,
  UiFlex,
  UiHStack,
  uiStyles,
  UiBox
} from '@/lib/ui';
import { smartWindowWidth } from '@/base/Layout/viewport';
// import { useWindowSize } from '@/lib/hook';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import AccountCard from '@/base/App/AccountCard';

export interface AccessDeniedProps {
  children: ReactNode
  smartWindow: ReactNode
  breadcrumb?: ReactNode
  isSmartWindowVisible?: boolean
  smartButton?: ReactNode
  disableTopNav?: boolean
  MainTransition?: ElementType | null
}

export const AccessDenied: FC<AccessDeniedProps> = ({
  children,
  smartWindow,
  MainTransition = BaseTransitionSlideLeft,
  disableTopNav = false,
  smartButton = undefined
}) => {
  const { isOpen: isSmartWindowVisible, onToggle: onSmartWindowToggle } = useDisclosure();
  // const { clientWidth: windowWidth } = useWindowSize();
  const mainWindowTranslate = useMemo(
    () => {
      const props = {
        transform: 'translateX(0)',
        transitionDuration: '0.5s',
        transitionTimingFunction: 'ease-in-out'
      };
      if (isSmartWindowVisible) {
        props.transform = `translateX(-${smartWindowWidth - 16}px)`;
      }
      return props;
    },
    [isSmartWindowVisible]
  );

  return (
    <UiStack
      spacing={0}
      // p={8}
      minHeight={'100vh'}
      alignItems={'stretch'}
      bgColor={'indigo.50'}
      // bgGradient='linear(to-r, indigo.50, indigo.100)'
      // bgGradient='radial(indigo.100, indigo.600)'
    >
      {!disableTopNav && (
        <UiStack
          // py={8}
          p={8}
          pb={4}
          // px={8}
        >
          <UiHStack
            bgColor={'gray.100'}
            borderRadius={uiStyles.borderRadius}
            p={4}
            px={8}
            // borderWidth={'1px'}
            // borderColor={'gray.300'}
            // borderStyle={'solid'}
          >
            {/* {appSwitch ?? (<UiBox/>)} */}
            <AccountCard/>
            {!!smartButton && (
              <UiBox
                {...uiStyles.hover}
                onClick={onSmartWindowToggle}
              >
                {smartButton}
              </UiBox>
            )}
          </UiHStack>
        </UiStack>
      )}
      <UiFlex
        // minHeight={'100vh'}
        // bgGradient='linear(to-r, primary.100, primary.200)'
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        overflowX={'hidden'}
        // maxW={windowWidth - 64}
        // minW={windowWidth - 64}
        flexGrow={1}
        // bgColor={'red'}
        zIndex={99}
        // p={8}
        pt={0}
      >
        <UiFlex
          p={8}
          pt={0}
          // pr={0}
          flexGrow={1}
          zIndex={2} // This is needed so that the hidden right smart window won't affect click/mouserover
          {...mainWindowTranslate}
        >
          <UiStack
            flexGrow={1}
            // zIndex={2}
          >
            { MainTransition ? (
              <MainTransition
                // shadow={'sm'}
              >
                {children}
              </MainTransition>
            ) : (
              <UiFlex
                flexGrow={1}
              >
                {children}
              </UiFlex>
            )}
          </UiStack>
        </UiFlex>
        <UiFlex
          marginLeft={-smartWindowWidth}
          p={8}
          pt={0}
          // ml={4}
          pl={0}
          // pr={8}
          // pb={8}
          alignItems={'stretch'}
          width={smartWindowWidth}
          transform={'background-color'}
          transitionDuration={'0.5s'}
          transitionTimingFunction={'ease-in-out'}
        >
          <UiFlex
            borderRadius={uiStyles.borderRadius}
            opacity={isSmartWindowVisible
              ? 1
              : 0}
            transform={`scale(${isSmartWindowVisible ? 1 : 0.9})`}
            transitionDuration={'0.5s'}
            transitionTimingFunction={'ease-in-out'}
            flexGrow={1}
            // borderWidth={'1px'}
            // borderColor={'gray.300'}
            // borderStyle={'solid'}
          >
            {smartWindow}
          </UiFlex>
        </UiFlex>
      </UiFlex>
    </UiStack>
  );
};

export default AccessDenied;
