import { useCallback, useEffect, useMemo } from 'react';
import { useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { type RegistrationRouteName } from '@/registration/pages';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from './useRegisterRoute';
import { useEventSettingsQuery } from './useEventSettingsQuery';
import { useVisitor } from '@/app/ProviderVisitor';

export interface RegisterStep {
  routeName: RegistrationRouteName
  isEnable?: boolean
}

export function useRegisterSteps() {
  const { tenantCode, eventId } = useRegisterRoute();
  const [searchParams] = useSearchParams();
  const { data: eventSettings, isLoading } = useEventSettingsQuery(eventId);
  const { visitor, isLoadingVisitor } = useVisitor();
  const navigate = useNavigate();
  const steps: Array<{ routeName: RegistrationRouteName, isEnable: boolean }> = useMemo(
    () => [{
      routeName: 'RegistrationRegisterCategory',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterPersonalInfo',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterMainTickets',
      isEnable: visitor?.registrationSteps?.mainEventTicket ?? false
    }, {
      routeName: 'RegistrationRegisterFunctionTickets',
      isEnable: visitor?.registrationSteps?.functionTicket ?? false
    }, {
      routeName: 'RegistrationRegisterWorkshopTickets',
      isEnable: visitor?.registrationSteps?.workshopTicket ?? false
    }, {
      routeName: 'RegistrationRegisterTourTickets',
      isEnable: visitor?.registrationSteps?.tourTicket ?? false
    }, {
      routeName: 'RegistrationRegisterAdditionalInfo',
      isEnable: visitor?.registrationSteps?.additionalInformation ?? false
    }, {
      routeName: 'RegistrationRegisterShoppingCart',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterReview',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterCheckoutAddress',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterPayment',
      isEnable: true
    }, {
      routeName: 'RegistrationRegisterPaymentResult',
      isEnable: true
    }],
    [eventSettings, isLoadingVisitor]
  );

  const matches = useMatches();
  const currentStepRouteName = useMemo(
    () => matches[matches.length - 1].id as RegistrationRouteName
    , [matches]);

  const stepIndex = useMemo(
    () => steps.findIndex((currentStep) => currentStep.routeName === currentStepRouteName)
    , [currentStepRouteName, steps]);

  const isReviewing = useMemo(() => !!searchParams.get('review'), [searchParams]);

  const findNextStep = useCallback(() => {
    // The current step is not a valid step since it's not found.
    if (stepIndex === -1) return null;

    const nextStep = steps.find((step, index) => index > stepIndex && step.isEnable);
    // return undefined if last step
    return nextStep;
  }, [stepIndex, steps]);

  const findPreviousStep = useCallback(() => {
    if (stepIndex === -1) return null;

    const prevStep = steps.findLast((step, index) => index < stepIndex && step.isEnable);
    // return undefined if first step
    return prevStep;
  }, [stepIndex, steps]);

  const toNextStep = useCallback(() => {
    if (isReviewing) {
      searchParams.delete('review');
      return navigate(generatePageUrl('RegistrationRegisterReview', { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
    const nextStep = findNextStep();
    if (nextStep) {
      navigate(generatePageUrl(nextStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, findNextStep, isReviewing, navigate, searchParams, tenantCode]);

  const toPreviousStep = useCallback(() => {
    if (isReviewing) {
      searchParams.delete('review');
      return navigate(generatePageUrl('RegistrationRegisterReview', { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
    const previousStep = findPreviousStep();
    if (previousStep) {
      navigate(generatePageUrl(previousStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, findPreviousStep, isReviewing, navigate, searchParams, tenantCode]);

  // auto move to next page if page not enable
  useEffect(() => {
    if (!(steps[stepIndex]?.isEnable)) toNextStep();
  }, [stepIndex, steps, toNextStep]);

  return {
    isLoading,
    steps,
    isReviewing,
    findNextStep,
    findPreviousStep,
    toNextStep,
    toPreviousStep
  };
}
