import { type FC, useState, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
// import { sellerHost } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';

export interface OrderFilterProps extends UiHStackProps {
  onClose: () => void
  onApply: (filterParam: FilterFormData) => Promise<void>
  isVisible: boolean
}

export interface FilterFormData {
  eventIds?: string[]
  attendeeEmail?: string
}

const initFormData: FilterFormData = {
  eventIds: []
};

const OrderFilter: FC<OrderFilterProps> = ({
  // tenantCode,
  onClose,
  onApply,
  isVisible
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { data: events } = useEventListQuery();
  const eventOptions: Option[] = useMemo(() =>
    events?.items?.map(event => ({ value: event.id, label: event.name })) ?? []
  , [events?.items]);
  return (
    <Formik
      initialValues={initFormData}
      // validateOnChange={false}
      // validateOnBlur={false}
      // validate={(values: FormData): FormErrors => {
      //   const errors: FormErrors = {};
      //   // if (!values.name) {
      //   //   errors.name = 'Name is required.';
      //   // }
      //   // if (!values.description) {
      //   //   errors.description = 'Description is required.';
      //   // }
      //   return errors;
      // }}
      onSubmit={async (
        values: FilterFormData,
        { setSubmitting }: FormikHelpers<FilterFormData>
      ) => {
        setSubmitting(true);
        await onApply(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Filter orders'}
        size={'md'}
        // isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'eventIds'}
            label={'Events'}
            layout="inline"
            options={eventOptions}
            isRequired={false}
            isMultiple={true}
          />
          <BaseFormInputField
            name={'attendeeEmail'}
            label={'Attendee email'}
            isRequired={false}
          />
          <BaseFormInputField
            name={'orderSerial'}
            label={'Order serial number'}
            isRequired={false}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default OrderFilter;
