import { useCallback, type FC, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
// import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
// import { UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconCurrencyCircleDollar, UiIconPencilSimple, UiStack, UiText, uiStyles } from '@/lib/ui';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import { createFormatPrice } from '@/lib/util/locale';
import { useVisitor } from '@/app/ProviderVisitor';

const ShoppingCartReview: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tenantCode, eventId } = useRegisterRoute();
  const toModify = useCallback(
    () => {
      navigate(generatePageUrl(
        'RegistrationRegisterMainTickets',
        { tenantCode, eventId },
        { ...Object.fromEntries(searchParams), review: true }
      ));
    },
    [eventId, navigate, searchParams, tenantCode]
  );

  const { visitorId } = useVisitor();
  const { data: cartData } = useCartQuery(visitorId);

  const priceCurrency = 'AUD';
  const priceLocale = 'en-AU';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );
  const totalAmount = useMemo(
    () => formatPrice((cartData?.totalAmount ?? 0) * 100),
    [formatPrice, cartData?.totalAmount]
  );

  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Your purchase
        </UiText>
        <UiButton
          rightIcon={(<UiIconPencilSimple color={'primary.500'} size={'xl'} weight={'bold'} />)}
          variant={'ghost'}
          colorScheme={'primary'}
          fontSize={'md'}
          fontWeight={'bold'}
          onClick={toModify}
        >
          Modify
        </UiButton>
      </UiHStack>
      <UiStack
        p={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        {cartData?.cartItems.map((item) => (
          <UiStack key={item.id}>
            <UiText>{item.name}</UiText>
            <UiHStack justifyContent={'flex-start'}>
              <UiIconCurrencyCircleDollar size={'xl'} color={'text.primary'} />
              <UiText variant={'body2'}>Price</UiText>
              <UiText variant={'body2'}>{formatPrice(item.price * 100)}</UiText>
            </UiHStack>
          </UiStack>
        ))}
      </UiStack>
    </UiStack>
  );
};

export default ShoppingCartReview;
