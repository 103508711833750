import { type FC, useState } from 'react';
import RGL, { WidthProvider, type Layout } from 'react-grid-layout';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiBox,
  UiHStack,
  UiIconPlusCircle,
  UiStack,
  type UiStackProps,
  UiText,
  uiStyles
} from '@/lib/ui';
// import BaseFormBuilderFieldSectionBreak from '@/base/FormBuilder/FieldSectionBreak';
// import BaseFormBuilderFieldInput from '@/base/FormBuilder/FieldInput';
// import BaseFormBuilderFieldSelect from '@/base/FormBuilder/FieldSelect';
import FieldDrawer from '@/base/FormBuilder/FieldDrawer';
// import GridItem from '@/base/FormBuilder/GridItem';
import Grid from '@/base/FormBuilder/Grid';
import { type FieldMetadata } from './Field';
import GridItem from './GridItem';

// const layout: Layout[] = [
//   {x: 0, y: 0, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: 'basicInfoTitle'},
//   {x: 0, y: 1, w: 1, h: 1, maxH: 1, i: 'firstName'},
//   {x: 1, y: 1, w: 1, h: 1, maxH: 1, i: 'lastName'},
//   {x: 0, y: 2, w: 1, h: 1, maxH: 1, i: 'gender'},
//   {x: 1, y: 2, w: 1, h: 1, minW: 1, maxW: 2, maxH: 1, i: 'dob'},
// ];

export interface FormBuilderProps extends UiStackProps {
  onFieldAdd: (fieldMetadata: FieldMetadata) => void
  onFieldUpdate: (fieldMetadata: FieldMetadata) => void
  onLayoutChange: (layout: Layout[]) => void
  onFieldDelete: (fieldId: string) => void
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
}

const FormBuilder: FC<FormBuilderProps> = ({
  // layout = [],
  onFieldAdd,
  onFieldUpdate,
  onLayoutChange,
  onFieldDelete,
  fieldsLayout = [],
  fieldsMetadata = [],
  ...props
}) => {
  // const [layouts, setLayouts] = useState({ lg: generateLayout() });
  // const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const { isOpen, onToggle } = useDisclosure();

  const onDrop = (layout: Layout[], layoutItem: Layout, _event: Event) => {
    alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
  };

  // const onBreakpointChange = (breakpoint: string, cols: number) => {
  //   console.log(breakpoint, cols);
  // };
  // const onLayoutChange = (layout: Layout[]) => {
  //   // this.props.onLayoutChange(layout, layouts);
  //   onLayoutChange(layout);
  //   // console.log(layout);
  // };

  const addFieldAndClose = (fieldMetadata: FieldMetadata) => {
    onFieldAdd(fieldMetadata);
    onToggle();
  };

  return (
    <>
      <UiStack
        p={8}
        py={16}
        // width={1080}
        // spacing={8}
        bgColor={'#fff'}
        alignItems={'stretch'}
        borderRadius={uiStyles.borderRadius}
        {...props}
      >
        <Grid
          layout={fieldsLayout}
          rowHeight={112}
          margin={[32, 48]}
          containerPadding={[0, 0]}
          onLayoutChange={onLayoutChange}
          cols={2}
          isDroppable={false}
          // isResizable={true}
          // resizeHandle={(<UiBox>12e123</UiBox>)}
        >
          {fieldsMetadata.map((fieldMetadata) => {
            return (
              <GridItem
                key={fieldMetadata.id}
                fieldMetadata={fieldMetadata}
                bgColor={'gray.50'}
                onDelete={onFieldDelete}
                onUpdate={onFieldUpdate}
              />
              //   <BaseFormBuilderFieldInput
              //     label={fieldMetadata.label}
              //     type={'text'}
              //     isRequired={true}
              //     onClick={() => {}}
              //   />
              // </GridItem>
            );
            // if (fieldMetadata.type === 'input') {
            //   return (
            //     <GridItem
            //       key={fieldMetadata.id}
            //       bgColor={'gray.50'}
            //       canEdit={fieldMetadata.canEdit}
            //       canDelete={fieldMetadata.canDelete}
            //     >
            //       <BaseFormBuilderFieldInput
            //         label={fieldMetadata.label}
            //         type={'text'}
            //         isRequired={true}
            //         onClick={() => {}}
            //       />
            //     </GridItem>
            //   )
            // }
            // if (fieldMetadata.type === 'select') {
            //   return (
            //     <GridItem
            //       key={fieldMetadata.id}
            //       bgColor={'gray.50'}
            //       canEdit={fieldMetadata.canEdit}
            //       canDelete={fieldMetadata.canDelete}
            //     >
            //       <BaseFormBuilderFieldSelect
            //         label={fieldMetadata.label}
            //         isMultiple={false}
            //         onClick={() => {}}
            //       />
            //     </GridItem>
            //   )
            // }
            // if (fieldMetadata.type === 'sectionBreak') {
            //   return (
            //     <GridItem
            //       key={fieldMetadata.id}
            //       bgColor={'gray.50'}
            //       canEdit={fieldMetadata.canEdit}
            //       canDelete={fieldMetadata.canDelete}
            //       onDelete={() => {}}
            //     >
            //       <BaseFormBuilderFieldSectionBreak
            //         label={fieldMetadata.label}
            //         description={fieldMetadata.description ?? ''}
            //       />
            //     </GridItem>
            //   )
            // }
            // return null;
          })}
        </Grid>
        <UiHStack
          pt={4}
          // bgColor={'gray.50'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          flexDirection={'row-reverse'}
        >
          <UiHStack
            {...uiStyles.hover}
            onClick={onToggle}
            spacing={2}
          >
            <UiIconPlusCircle
              size={48}
              color={'primary.600'}
              // width={64}
              weight={'light'}
            />
            <UiText variant={'h6'} color={'primary.400'}>Add</UiText>
          </UiHStack>
        </UiHStack>
      </UiStack>
      <FieldDrawer
        title={'Add form element'}
        isOpen={isOpen}
        // size={'xl'}
        onClose={onToggle}
        onFieldAdd={addFieldAndClose}
      />
    </>
  );
};

export default FormBuilder;
