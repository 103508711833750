import {
  type ApiRequest,
  type ApiResponse,
  properCallApi,
  type ApiResponseSingle
} from '@/api/tenantClient';
import { type InfoFormType } from './infoFormResponse';
import { type AttendeeCategoryData } from './attendeeCategory';
import { type Layout } from 'react-grid-layout';
import { type FieldMetadata } from '@/base/FormBuilder/Field';

export const infoFormsQueryKey = 'registration.infoFormList';
export const infoFormQueryKey = 'registration.infoForm';
export const infoFormByAttendeeCategoryQueryKey = 'registration.infoFormByAttendeeCategory';

export interface InfoForm {
  id: number
  formType: InfoFormType
  eventId: string
  config: {
    fieldsLayout: Layout[]
    fieldsMetadata: FieldMetadata[]
    totalFields: number
  }
  attendeeCategories: AttendeeCategoryData[]
}
export interface InfoFormData extends Omit<InfoForm, 'config'> {
  config: string
}

export interface InfoFormsQueryRequest {
  eventId: string
  formType: InfoFormType
}

export function loadInfoForms(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: InfoFormsQueryRequest
  ): Promise<ApiResponse<InfoFormData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_forms/';
    request.endpoint.query = {
      formType: params.formType,
      eventId: params.eventId
    };
    return await properCallApi<ApiResponse<InfoFormData>>(request);
  };
}

export function loadInfoForm(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: number
  ): Promise<ApiResponseSingle<InfoFormData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_forms/' + params.toString();
    return await properCallApi<ApiResponseSingle<InfoFormData>>(request);
  };
}

export interface InfoFormByAttendeeCategoryQuery {
  formType: InfoFormType
  visitorId: number
}

export function loadInfoFormByAttendeeCategory(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: InfoFormByAttendeeCategoryQuery
  ): Promise<ApiResponseSingle<InfoFormData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_forms/by_attendee_category';
    request.endpoint.query = {
      formType: params.formType,
      visitorId: params.visitorId.toString()
    };
    return await properCallApi<ApiResponseSingle<InfoFormData>>(request);
  };
}

export interface InfoFormSaveRequestItem {
  id?: number
  formType: InfoFormType
  eventId: string
  config: string
  attendeeCategoryIds?: string[]
}

export interface InfoFormSaveRequest {
  infoForm: InfoFormSaveRequestItem
}

export function saveInfoForm(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: InfoFormSaveRequest
  ): Promise<ApiResponseSingle<InfoForm>> => {
    if (params.infoForm.id) {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/info_forms/' + params.infoForm.id;
      request.payload = params;
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/info_forms';
      request.payload = params;
    }
    return await properCallApi<ApiResponseSingle<InfoForm>>(request);
  };
}

export interface InfoFormDeleteRequest {
  id: number
}

export function deleteInfoForm(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: InfoFormDeleteRequest
  ): Promise<ApiResponseSingle<{}>> => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/info_forms/' + params.id.toString();
    return await properCallApi<ApiResponseSingle<{}>>(request);
  };
}

export function cloneInfoForm(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (attendeeCategoryId: number): Promise<ApiResponseSingle<{}>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/info_forms/clone';
    request.endpoint.query = { id: attendeeCategoryId.toString() };

    return await properCallApi<ApiResponseSingle<{}>>(request);
  };
}
