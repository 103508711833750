import { type FC, type ReactNode } from 'react';
import {
  useToken,
  useDisclosure
} from '@chakra-ui/react';
import {
  UiBox,
  type UiBoxProps,
  UiHStack, UiIconArrowsClockwise, UiIconCaretDown,
  UiIconDotsNine, UiStack,
  uiStyles
} from '@/lib/ui';
import ListDrawer from './ListDrawer';
import { type AppName } from './List';
// import ListDrawerButtonLottie from '@/lottie/white-robot.json';

export interface ListDrawerButtonProps extends UiBoxProps {
  currentApp: ReactNode
  currentAppName?: AppName
}

const ListDrawerButton: FC<ListDrawerButtonProps> = ({
  currentApp,
  currentAppName = undefined
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const colorToken = useToken('colors', 'primary.100');

  return (
    <>
      <UiHStack
        spacing={4}
        // bgColor={'#fff'}
        // borderRightWidth={'1px'}
        // borderRightColor={'gray.300'}
        // borderRightStyle={'solid'}
        // onClick={onToggle}
        // py={6}
        // flexGrow={1}
        alignItems={'center'}
        // justifyContent={'space-around'}
        // bgColor={'red'}
        // height={'80px'}
      >
        <UiStack
          // bgColor={'primary.500'}
          borderWidth={'3px'}
          borderColor={'primary.500'}
          borderStyle={'solid'}
          // borderBottomStyle={'solid'}
          borderRadius={'50%'}
          onClick={onToggle}
          p={2}
          {...uiStyles.hoverFlex({
            bgColor: 'primary.100',
            scale: 1.1
          })}
        >
          <UiStack
            // width={'48px'}
            // height={'48px'}
            spacing={0}
            flexGrow={1}
            alignItems={'center'}
            justifyContent={'space-around'}
          >
            <UiIconDotsNine
              size={'3xl'}
              color={'primary.800'}
              weight={'bold'}
            />
          </UiStack>
        </UiStack>
        {currentApp}
      </UiHStack>
      <ListDrawer
        currentAppName={currentAppName}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );

  // return (
  //   <>
  //     <UiHStack
  //       spacing={0}
  //     >
  //       <UiHStack spacing={0}>
  //         <UiStack pl={8} pr={4} py={4} bgColor={colorToken} height={'80px'} alignItems={'center'} justifyContent={'space-around'}>
  //           <UiBox
  //             p={2}
  //             borderRadius={'100%'}
  //             borderWidth={'2px'}
  //             borderColor={'primary.500'}
  //             onClick={onToggle}
  //             {...uiStyles.hoverFlex({bgColor: 'primary.100'})}
  //           >
  //             <UiIconDotsNine size={'xl'} color={'primary.800'} weight={'bold'}/>
  //           </UiBox>
  //         </UiStack>
  //         <UiBox width={'80px'}>
  //           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none" fill={colorToken}>
  //             <polygon points="0 0 24 0 0 24"/>
  //           </svg>
  //         </UiBox>
  //       </UiHStack>
  //       {currentApp}
  //       {/*<UiIconArrowsClockwise size={'2xl'} color={'primary.500'} weight={'bold'}/>*/}
  //     </UiHStack>
  //     <ListDrawer
  //       currentAppName={currentAppName}
  //       isOpen={isOpen}
  //       onClose={onToggle}
  //     />
  //   </>
  // );
};

export default ListDrawerButton;
