import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiText,
  UiHStack
} from '@/lib/ui';
import CategoryForm from './CategoryForm';

export interface NewTypeProps extends UiHStackProps {}

const NewType: FC<NewTypeProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        // bgColor={'gray.50'}
        borderRadius={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        // flexGrow={1}
        onClick={onToggle}
        {...uiStyles.hover}
        // width={'64px'}
        // flexGrow={}
        p={0}
      >
        <UiIconPlusCircle
          size={56}
          color={'primary.600'}
          // width={64}
          weight={'light'}
        />
        <UiText variant={'title'} color={'primary.500'}>Add</UiText>
      </UiHStack>
      <CategoryForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewType;
