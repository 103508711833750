import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { type AttendeeCategoryData } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';

// We do not need to wrap every react query or mutations into hook. The reason why we do it here is because this query here will be used in a lot of components.
export function useAttendeeCategoryQuery(eventId: string) {
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();
  return useQuery<AttendeeCategoryData[], Error>({
    queryKey: [registration.attendeeCategoryListQueryKey, { eventId }],
    queryFn: async () => {
      const result = await registration.loadAttendeeCategoryList(createTenantApiRequest)({ eventId });
      return result.items?.[0];
    },
    enabled: !isApiPreparing,
  });
}
