import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSimpleGrid,
  uiStyles,
  UiButton,
  UiHStack
} from '@/lib/ui';
import { type account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import {
  subscriptionPackageLabels,
  subscriptionPlanLabels
} from '@/api/constant/subscription';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { getAddonsEnabled, getProductsEnabled } from '@/account/subscription/utils';
// import { subscription } from 'swr/subscription';

export interface SummaryProps extends UiBoxProps {
  subscription: account.Subscription
}

const Summary: FC<SummaryProps> = ({
  subscription
}) => {
  const { tenant } = useTenantRoute();
  const navigate = useNavigate();
  const toManagement = useCallback(() => {
    navigate(generatePageUrl('AccountSubscriptionPlanManagement', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);

  if (!subscription.plan) {
    return (
      <BaseMessageBarError>Failed to load the summary</BaseMessageBarError>
    );
  }

  return (
    <UiHStack spacing={4} p={8} py={6} bgColor={'#fff'} borderRadius={uiStyles.borderRadius} flexGrow={1} justifyContent={'space-between'}>
      <UiSimpleGrid spacing={0} columns={3} flexGrow={1}>
        <UiStack spacing={2}>
          <UiText variant={'body1'} color={'text.secondary'}>Package</UiText>
          <UiText variant={'title'}>{subscriptionPackageLabels[subscription.plan.package]}</UiText>
        </UiStack>
        <UiStack spacing={2}>
          <UiText variant={'body2'} color={'text.secondary'}>Add-ons</UiText>
          <UiText variant={'title'}>{getAddonsEnabled(subscription).length ?? 0}</UiText>
        </UiStack>
      </UiSimpleGrid>
      <UiStack width={36} justifyContent={'flex-end'} alignItems={'flex-end'}>
        <UiButton colorScheme={'primary'} onClick={toManagement}>Change</UiButton>
      </UiStack>
    </UiHStack>
  );
};

export default Summary;
