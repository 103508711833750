import { type ApiRequest, type ApiResponse, callApi } from '@/api/tenantClient';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { type Layout } from 'react-grid-layout';

export const eventSettingsQueryKey = 'registration.eventSettings';

interface EventSettingsSegment {
  isSet?: boolean // If the step is checked by the admin user.
}

export interface EventSettingsGroupRegistration extends EventSettingsSegment {
  isEnabled: boolean
}

export interface EventSettingsAttendeeCategory extends EventSettingsSegment {
  total: number
}

export interface EventSettingsDiscountCode extends EventSettingsSegment {
  total: number
}

export interface EventSettingsPersonalInformation extends EventSettingsSegment {
  totalFields: number
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
}

export interface EventSettingsMainEventTicket extends EventSettingsSegment {
  total: number
}

export interface EventSettingsFunctionTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsWorkshopTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsTourTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsAdditionalInformation extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  totalFields: number // This should be changed to totalForms. We will show the total number of forms in the step card.
  // The follow 2 fields will also need to be replaced by an array since we will have multiple forms.
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
  total: number
}

export interface EventSettingsPrivacy extends EventSettingsSegment {
  isEnabled: boolean
  privacyPolicyUrl?: string | undefined
  termsAndConditionsUrl?: string | undefined
}

export interface EventSettingsDelegateType extends EventSettingsSegment {
  total: number
}

export interface EventSettingsBadge extends EventSettingsSegment {
  isEnabled: boolean
}

export interface OtherSettings extends EventSettingsSegment {
}

export interface EventSettings {
  groupRegistration: EventSettingsGroupRegistration
  attendeeCategory: EventSettingsAttendeeCategory
  discountCode: EventSettingsDiscountCode
  delegateType: EventSettingsDelegateType
  personalInformation: EventSettingsPersonalInformation
  mainEventTicket: EventSettingsMainEventTicket
  functionTicket: EventSettingsFunctionTicket
  workshopTicket: EventSettingsWorkshopTicket
  tourTicket: EventSettingsTourTicket
  additionalInformation: EventSettingsAdditionalInformation
  privacy: EventSettingsPrivacy
  merchandise: EventSettingsSegment
  badge: EventSettingsBadge
  checkout: EventSettingsSegment
  event: EventSettingsSegment
  paymentMethod: EventSettingsSegment
}

export interface EventSetting {
  id: string
  eventId: string
  config: EventSettings
}

export type EventSettingsSegmentName = keyof EventSettings;

interface EventSettingQueryRequest {
  eventId: string | number
}

export function loadEventSettings(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: EventSettingQueryRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/event_settings';
    request.endpoint.query = {
      eventId: params.eventId
    };
    return await callApi<EventSetting>(request);
  };
}

export interface EventSettingsUpdateRequest {
  eventId?: string
  config: Partial<EventSettings>
}

export function updateEventSettings(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsUpdateRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/event_settings/' + params.eventId;
    request.payload = {
      eventId: params.eventId,
      eventSettings: params
    };
    return await callApi<EventSetting>(request);
  };
}

export function initializeEventSettings(): EventSettings {
  return {
    groupRegistration: { isSet: false, isEnabled: false },
    attendeeCategory: { isSet: false, total: 0 },
    discountCode: { isSet: false, total: 0 },
    delegateType: { isSet: false, total: 0 },
    personalInformation: {
      isSet: false,
      totalFields: 0,
      fieldsLayout: initFieldsLayout,
      fieldsMetadata: initFieldsMetadata
    },
    mainEventTicket: { isSet: false, total: 0 },
    functionTicket: { isSet: false, isEnabled: false, total: 0 },
    workshopTicket: { isSet: false, isEnabled: false, total: 0 },
    tourTicket: { isSet: false, isEnabled: false, total: 0 },
    additionalInformation: {
      total: 0,
      isSet: false,
      isEnabled: false,
      totalFields: 0,
      fieldsLayout: initFieldsLayout,
      fieldsMetadata: initFieldsMetadata
    },
    privacy: { isSet: false, isEnabled: false, privacyPolicyUrl: undefined, termsAndConditionsUrl: undefined },
    merchandise: { isSet: false },
    badge: { isSet: false, isEnabled: false },
    checkout: { isSet: false },
    event: { isSet: false },
    paymentMethod: { isSet: false }
  };
}

export interface EventSettingsCreateRequest extends Partial<EventSettings> {
  eventId: string | number
}

export function createEventSettings(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsCreateRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/event_settings/';
    request.payload = {
      eventSettings: { config: initializeEventSettings(), eventId: params.eventId }
    };
    return await callApi<EventSetting>(request);
  };
}

export interface EventSettingsUpdateIsSetRequest {
  eventId: string
  key: keyof EventSettings
  isSet: boolean
}

export function updateIsSetEventSetting(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsUpdateIsSetRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/event_settings/update_is_set';
    request.payload = {
      eventId: params.eventId,
      key: params.key,
      isSet: params.isSet
    };
    return await callApi<EventSetting>(request);
  };
}

export const initFieldsLayout: Layout[] = [
  { x: 0, y: 0, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: 'basicInfoSectionBreak' },
  { x: 0, y: 1, w: 1, h: 1, maxH: 1, i: 'email' },
  { x: 1, y: 1, w: 1, h: 1, maxH: 1, i: 'firstName' },
  { x: 0, y: 2, w: 1, h: 1, maxH: 1, i: 'lastName' },
  { x: 1, y: 2, w: 1, h: 1, maxH: 1, i: 'gender' },
  { x: 0, y: 3, w: 1, h: 1, minW: 1, maxW: 2, maxH: 1, i: 'dob' }
];
export const initFieldsMetadata: FieldMetadata[] = [
  {
    id: 'basicInfoSectionBreak',
    type: 'sectionBreak',
    label: 'Basic info',
    description: 'Please tell us about yourself.',
    canDelete: true,
    canEdit: true
  },
  {
    id: 'firstName',
    type: 'input',
    label: 'First name',
    length: 100,
    helpText: '',
    isRequired: true,
    canEdit: true,
    canDelete: true
  },
  {
    id: 'lastName',
    type: 'input',
    label: 'Last name',
    length: 50,
    helpText: '',
    isRequired: true,
    canEdit: true,
    canDelete: true
  },
  {
    id: 'gender',
    type: 'select',
    label: 'Gender',
    options: 'Male,Female,Choose not to tell',
    helpText: '',
    isRequired: true,
    isMultiple: false,
    canEdit: true,
    canDelete: true
  },
  {
    id: 'dob',
    type: 'input',
    label: 'Date of birth',
    length: 10,
    isRequired: true,
    helpText: '',
    canEdit: true,
    canDelete: true
  }
];
