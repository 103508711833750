import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type UiBoxProps, UiHStack, UiIconCaretRight, UiSpinner, UiStack, uiStyles, UiText } from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseDigitalPosterCard from '@/base/App/DigitalPosterCard';
import BaseLiveStreamingCard from '@/base/App/LiveStreamingCard';
import BaseSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import BaseWebinarCard from '@/base/App/WebinarCard';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { isProductEnabled } from '@/account/subscription/utils';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { SubscriptionProduct } from '@/api/constant/subscription';

// export interface Apps {
//   account: number;
//   registration: number;
//   abstract: number;
// }

// const apps: Apps = {
//   account: 1,
//   registration: 2,
//   abstract: 3,
// };

// export type AppName = keyof Apps;

export type AppName = 'account' | 'registration' | 'abstract' | 'eventWebsite' | 'speakerTools' | 'integrations' | 'contentHub' | 'eventApp' | 'digitalPoster' | 'sponsorExhibitor' | 'liveStreaming' | 'webinar';

export interface ListProps extends UiBoxProps {
  tenantCode: string
  currentApp?: AppName
}

const List: FC<ListProps> = ({
  tenantCode,
  currentApp = undefined
}) => {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data, isLoading } = useQuery<account.Subscription, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createTenantAdminApiRequest)({});
    },
    {
      enabled: !isApiPreparing
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!data) {
    return (
      <UiStack py={8}>
        <BaseMessageBarError>
          Failed to load your current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <UiStack spacing={16} justifyContent={'space-between'} alignItems={'stretch'} flexGrow={1}>
      <UiStack spacing={4} flexGrow={1}>
        <UiStack spacing={4} pt={8}>
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseAppAccountCard
              navLink={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}
              isSelected={currentApp === 'account'}
            />
          </UiStack>
        </UiStack>
        <BaseDividerHorizontal height={8} />
        <UiText variant={'title'}>Modules enabled</UiText>
        {isProductEnabled(data, SubscriptionProduct.Registration) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseAppRegistrationCard
              navLink={generatePageUrl('RegistrationManageEvent', { tenantCode })}
              isSelected={currentApp === 'registration'}
            />
          </UiStack>
        )}
        {isProductEnabled(data, SubscriptionProduct.EventWebsite) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseEventWebsiteCard
              navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'eventWebsite'}
            />
          </UiStack>
        )}

        {isProductEnabled(data, SubscriptionProduct.DigitalPoster) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseDigitalPosterCard
              navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'digitalPoster'}
            />
          </UiStack>
        )}
        {isProductEnabled(data, SubscriptionProduct.LiveStreaming) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseLiveStreamingCard
              navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'liveStreaming'}
            />
          </UiStack>
        )}
        {isProductEnabled(data, SubscriptionProduct.SponsorExhibitor) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseSponsorExhibitorCard
              navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'sponsorExhibitor'}
            />
          </UiStack>
        )}
        {isProductEnabled(data, SubscriptionProduct.Webinar) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseWebinarCard
              navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'webinar'}
            />
          </UiStack>
        )}

        {isProductEnabled(data, SubscriptionProduct.AbstractManagement) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseAbstractCard
              navLink={generatePageUrl('AbstractGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'abstract'}
            />
          </UiStack>
        )}
        {isProductEnabled(data, SubscriptionProduct.MobileEventApp) && (
          <UiStack bgColor={'#fff'} p={4} px={8} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
            <BaseEventAppCard
              navLink={generatePageUrl('MobileAppGeneralLanding', { tenantCode })}
              isSelected={currentApp === 'eventApp'}
            />
          </UiStack>
        )}
      </UiStack>
      <BaseDividerHorizontal height={0} />
      <UiStack alignItems={'stretch'}>
        <BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}>
          <UiHStack
            flexGrow={1}
            justifyContent={'space-between'}
            p={4}
            px={8}
            bgColor={'#fff'}
            borderRadius={uiStyles.borderRadius}
            {...uiStyles.hover}
          >
            <UiText variant={'title'}>Manage modules</UiText>
            <UiIconCaretRight color={'primary.500'} size={'3xl'} />
          </UiHStack>
        </BaseRouterLink>
      </UiStack>
    </UiStack>
  );
};

export default List;
