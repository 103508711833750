import { type FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton,
  UiHStack,
  UiIconUser,
  UiText,
  uiStyles,
  UiIconPencilSimple
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';
import TenantSelection from './TenantSelection';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiRequest } from '@/api/tenantClient';

export interface LoginPasswordFormProps extends UiHStackProps {
  onLoginSuccess: () => void
  changeEmail: () => void
  adminUser: account.AdminUserData
}

interface FormData {
  password: string
  // tenantCode: string
}

const formSchema = Yup.object().shape({
  password: Yup.string().required('Password is required.')
  // tenantCode: Yup.string().required('Tenant code is required.'),
});

const inputWidth = '420px';

const LoginPasswordForm: FC<LoginPasswordFormProps> = ({
  onLoginSuccess,
  changeEmail,
  adminUser
}) => {
  // const [password, setPassword] = useState('');
  const [tenant, setTenant] = useState<account.AdminUserDataTenant | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { persistAdminAuth } = useAdminAuth();
  const navigate = useNavigate();
  const createTenantApiRequest: () => ApiRequest = useCallback(
    () => {
      if (tenant?.id) {
        return {
          method: 'POST',
          endpoint: {
            host: tenant.apiEndpoint,
            path: ''
          }
        };
      } else {
        return {
          method: 'POST',
          endpoint: {
            path: ''
          }
        };
      }
    },
    [
      tenant?.id
    ]
  );

  const queryClient = useQueryClient();
  const { mutate: loginAdminUser, isLoading } = useMutation<account.TenantAdminUserSession, Error, account.TenantAdminUserLoginRequest>({
    mutationFn: async (request: account.TenantAdminUserLoginRequest) => {
      return await account.loginTenantAdminUser(createTenantApiRequest)(request);
    },
    onSuccess: (result) => {
      if (!result.authToken || !result.user?.id) {
        setErrors(['The combination of the email and the password is invalid.']);
        return;
      }
      persistAdminAuth(result);
      if (tenant?.code) {
        navigate(generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant.code }));
      }
      // void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
    },
    onError: () => {
      // setErrors([error.message ?? 'Failed to save the attendee category.']);
      setErrors(['Login failed (connection error).']);
    }
  });

  const submitForm = async (values: FormData) => {
    if (values.password) {
      if (tenant) {
        loginAdminUser({
          tenantApiEndpoint: tenant?.code,
          email: adminUser.email,
          password: values.password
        });
      }
    }
  };

  // Select the first tenant as default.
  useEffect(
    () => {
      if (Array.isArray(adminUser.tenants) && adminUser.tenants.length > 0) {
        setTenant(adminUser.tenants[0]);
      }
    },
    [adminUser.tenants]
  );

  return (
    <UiStack spacing={6}>
      <UiStack>
        <UiHStack
          // enableDivider={true}
          // spacing={4}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          maxW={inputWidth}
          spacing={0}
          p={0}
        >
          <UiStack
            p={6}
            // bgColor={'gray.200'}
            borderLeftRadius={uiStyles.borderRadius}
            borderRightWidth={'1px'}
            borderRightColor={'gray.200'}
            borderRightStyle={'solid'}
          >
            <UiIconUser size={'2xl'} color={'primary.500'} />
          </UiStack>
          <UiHStack p={6} spacing={4} flexGrow={1}>
            <UiText color={'text.secondary'} flexGrow={1}>
              {adminUser.email}
            </UiText>
            <UiStack {...uiStyles.hover} onClick={changeEmail}>
              <UiIconPencilSimple color={'primary.500'} size={'2xl'} />
            </UiStack>
          </UiHStack>
        </UiHStack>
      </UiStack>
      <UiStack>
        <UiHStack>
          <UiText>Tenant</UiText>
          <UiText color={'gray.600'} variant='title'>*</UiText>
        </UiHStack>
        {Array.isArray(adminUser.tenants) && adminUser.tenants.length > 0 ? (
          <TenantSelection
            tenants={adminUser.tenants}
            selectedTenant={tenant}
            onTenantChange={(newTenant) => setTenant(newTenant)}
          />
        ) : (
          <BaseMessageBarError>
            No tenant is found for this user.
          </BaseMessageBarError>
        )}
      </UiStack>
      <Formik
        initialValues={{
          password: ''
          // tenantCode: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={formSchema}
        onSubmit={async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
          setSubmitting(true);
          await submitForm(values);
          setSubmitting(false);
        }}
      >
        <Form
          style={{
            display: 'flex'
          }}
        >
          <UiStack bgColor={''} alignItems={'flex-start'}>
            {errors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} pb={2}>
                {errors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index} width={inputWidth}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup minW={inputWidth} maxW={inputWidth}>
              <BaseFormInputField
                name="password"
                label="Password"
                type={'password'}
                layout="stack"
              />
            </BaseFormFieldGroup>
            <UiStack flexDirection={'row-reverse'} py={4}>
              {isLoading
                ? (
                  <UiButton px={8} size={'lg'} colorScheme={'gray'} width={inputWidth}>
                  Processing...
                  </UiButton>
                ) : (
                  <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'} width={inputWidth}>
                  Next
                  </UiButton>
                )}
            </UiStack>
          </UiStack>
        </Form>
      </Formik>
    </UiStack>
  );
};

export default LoginPasswordForm;
