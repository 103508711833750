import { useMemo, type FC } from 'react';
import {
  uiStyles,
  UiIconCalendarBlank,
  type UiStackProps,
  UiIconHouse,
  UiStack,
  UiText,
  UiHStack,
  UiButton
} from '@/lib/ui';
import InfoField from './InfoField';
import { UiIconBrowser } from '@/lib/ui/Icon/UiIconBrowser';
import dayjs from 'dayjs';
import { type Event } from '@/api/registration';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

export interface InfoListProps extends UiStackProps {
  event: Event
}

const InfoCard: FC<InfoListProps> = ({
  event,
  ...props
}) => {
  const croppedWebsiteUrl = useMemo(() => {
    return event.websiteUrl.replace(/(^\w+:|^)\/\//, '');
  }, [event]);

  const whenText = useMemo(() => {
    if (event.startTime === event.endTime) {
      return dayjs(event.startTime).format('DD MMM YYYY');
    } else {
      return `${dayjs(event.startTime).format('DD MMM YYYY')} ~ ${dayjs(event.endTime).format('DD MMM YYYY')}`;
    }
  }, [event]);

  const formattedStartDate = dayjs(event.startDate).format('YYYY-MM-DD');
  const formattedEndDate = dayjs(event.endDate).format('YYYY-MM-DD');
  const formattedStartTime = dayjs(event.startTime).format('HH:mm');
  const formattedEndTime = dayjs(event.endTime).format('HH:mm');

  // Hack to hide package branding as they bypass regular CSS by using shadow DOM
  setInterval(function () {
    const hostElement = document.getElementById('atcb-btn-1-modal-host');
    if (hostElement?.shadowRoot) {
      const element = hostElement.shadowRoot.getElementById('atcb-reference');
      if (element) {
        element.style.display = 'none';
      }
    }
  }, 100);

  return (
    <UiStack
      spacing={8}
      borderRadius={uiStyles.borderRadius}
      enableDivider={true}
      {...props}
    >
      <UiHStack spacing={0} p={8} pb={0}>
        <UiText variant={'h6'}>{event.name}</UiText>
      </UiHStack>
      <UiStack
        spacing={8}
        borderRadius={uiStyles.borderRadius}
      >
        <InfoField
          Icon={UiIconBrowser}
          label={'Website'}
          description={croppedWebsiteUrl}
          url={event.websiteUrl}
        />
        <InfoField
          Icon={UiIconHouse}
          label={'Location'}
          url={event.mapUrl}
          description={event.location}
        />
        <InfoField
          Icon={UiIconCalendarBlank}
          label={'When'}
          description={whenText}
          url={'#'}
        >
          <AddToCalendarButton
            name={event.name}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
            startTime={formattedStartTime}
            endTime={formattedEndTime}
            location={event.location}
            options={['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo']}
            timeZone={event.timeZone}
            trigger="click"
            listStyle="modal"
            iCalFileName="Reminder-Event"
            debug={true}
          ></AddToCalendarButton>
        </InfoField>
      </UiStack>
    </UiStack>
  );
};

export default InfoCard;
