import { type FC } from 'react';
import { Textarea, type TextareaProps } from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui/styles';

export interface UiTextareaProps extends TextareaProps {
}

export const UiTextarea: FC<UiTextareaProps> = (props) => {
  return (
    <Textarea
      // maxWidth={'640px'}
      // height={'48px'}
      borderColor={'gray.300'}
      focusBorderColor={'primary.400'}
      size={'lg'}
      borderRadius={uiStyles.formElementBorderRadius}
      // focusBorderColor={'#fff'}
      // borderStyle={'none'}
      // borderBottomColor={'gray.300'}
      // borderBottomStyle={'solid'}
      // borderBottomWidth={'1px'}
      // borderRadius={0}
      // _focus={{
      //   borderStyle: 'none',
      //   borderBottomColor: 'primary.400',
      //   borderBottomWidth: '1px',
      //   borderBottomStyle: 'solid',
      // }}
      // px={0}
      {...props}
    />
  );
};
