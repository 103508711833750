import { type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconStackSimple,
  UiSpinner
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventQuery } from '@/registration/hook/useEventQuery';

export interface OtherSettingsProps {
  isActive?: boolean
}

const OtherSettings: FC<OtherSettingsProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: event, isLoading } = useEventQuery(eventId);

  return (
    <Step
      Icon={UiIconStackSimple}
      description={'Other settings'}
      isActive={isActive}
      isFirst={true}
      // stepNumber={5}
      // isSkipped={true}
      navLink={generatePageUrl('RegistrationBuildEventOtherSettings', { tenantCode, eventId })}
    >
      {isLoading ? (
        <UiStack>
          <UiSpinner size={'md'} color={'primary.500'} thickness='2px' />
        </UiStack>
      ) : (
        <UiStack spacing={0}>
          {event?.registrationMode && (
            <UiText variant={'body2'} color={'green.500'}>
              {event.registrationMode}
            </UiText>
          )}
        </UiStack>
      )}
    </Step>
  );
};

export default OtherSettings;
