import { type FC } from 'react';
import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import { type FieldMetadataDescriptionBlock } from './Field';

export interface FieldAttributes {
  description: string
}

export interface FieldDescriptionBlockFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataDescriptionBlock) => void
  fieldMetadata?: FieldMetadataDescriptionBlock
}

interface Errors {
  description?: string
}

const descriptionLimit = 500;

const FieldDescriptionBlockForm: FC<FieldDescriptionBlockFormProps> = ({
  onSave,
  fieldMetadata = undefined
}) => {
  const initValues: FieldAttributes = { description: '' };
  if (fieldMetadata) {
    initValues.description = fieldMetadata.description;
  }

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        if (!values.description) {
          errors.description = 'Description is required';
        } else if (values.description.length > descriptionLimit) {
          errors.description = `Description can not be more than ${descriptionLimit} charactors.`;
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        // alert(JSON.stringify(values, null, 2));
        // setSubmitting(false);
        if (fieldMetadata) {
          onSave({
            ...fieldMetadata,
            ...{
              type: 'descriptionBlock',
              description: values.description,
              canDelete: true,
              canEdit: true
            }
          });
        } else {
          onSave({
            id: uuidv4(),
            type: 'descriptionBlock',
            label: 'Description block',
            description: values.description,
            canDelete: true,
            canEdit: true
          });
        }
      }}
    >
      <Form
        style={{
          display: 'flex',
          marginTop: 0
        }}
      >
        <UiStack flexGrow={1}>
          <BaseFormFieldGroup>
            <BaseFormTextareaField
              name={'description'}
              label={'Description'}
              // isRequired={false}
              helperText={`Charactor limit ${descriptionLimit}.`}
            />
          </BaseFormFieldGroup>
          <UiStack flexDirection={'row-reverse'} pt={12}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
              Save
            </UiButton>
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default FieldDescriptionBlockForm;
