import { type FC, type ReactNode, useMemo, type PropsWithChildren } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles,
  UiImage
} from '@/lib/ui';
import Header from './Header';
import { getAppConfig } from '@/app/config';

export interface StoreLayoutProps extends PropsWithChildren {
  showHeader?: boolean
  children: ReactNode
}

const StoreLayout: FC<StoreLayoutProps> = ({
  showHeader = true,
  children
}) => {

  const app = getAppConfig();

  return (
    <UiStack
      // p={{base: 4, md: 8}}
      minHeight={'100vh'}
      alignItems={'stretch'}
      bgColor={'gray.100'}
      borderRadius={uiStyles.borderRadius}
      spacing={0}
      flexGrow={1}
    >
      {showHeader && <Header />}
      <UiStack
        // p={{base: 8}}
        p={8}
        pt={{ base: 0, lg: 8 }}
        spacing={0}
        flexGrow={1}
        alignItems={'stretch'}
      >
        <UiHStack flexGrow={1} justifyContent={'space-around'} alignItems={'flex-start'}>
          <UiStack
            // minW={320}
            maxW={1440}
            flexGrow={1}
            // py={{base: 8, md: 8}}
          >
            {children}
          </UiStack>
        </UiHStack>
      </UiStack>
    </UiStack>
  );
};

export default StoreLayout;
