import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import {
  type SubscriptionPackage,
  SubscriptionProduct
} from '@/api/constant/subscription';
import { isAddonAvailable } from '@/account/subscription/utils';
import { type SubscriptionServicePackName } from '@/api/account';

export interface SubscriptionEditor {
  subscription: account.Subscription
  isLoading: boolean
  selectPackage: (packageName: SubscriptionPackage) => void
  toggleAddon: (addonName: SubscriptionProduct) => void
  toggleServicePack: (servicePack: SubscriptionServicePackName) => void
}

export function useSubscriptionPlanEditor(): SubscriptionEditor {
  const [subscription, setSubscription] = useState<account.Subscription>(account.defaultSubscription);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data, isLoading } = useQuery<account.Subscription, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createTenantAdminApiRequest)({});
    },
    {
      enabled: !isApiPreparing
    }
  );

  useEffect(
    () => {
      if (data) {
        const newSubscription = { ...data };
        setSubscription(newSubscription);
      }
    },
    [data]
  );

  function selectPackage(packageName: SubscriptionPackage) {
    if (subscription.plan.package !== packageName) {
      const newSubscription = { ...subscription };
      newSubscription.plan.package = packageName;
      newSubscription.plan.addon.products = []; // This is important since Add-ons are different for different packages.
      setSubscription(newSubscription);
    }
  }

  function toggleAddon(addonName: SubscriptionProduct) {
    // Make sure the addon is NOT included in the package.
    if (isAddonAvailable(subscription, addonName)) {
      const newSubscription = { ...subscription };
      const existingPos = newSubscription.plan.addon.products.indexOf(addonName);
      if (existingPos >= 0) {
        // Remove the addon if exists.
        newSubscription.plan.addon.products.splice(existingPos, 1);
      } else {
        // Add the addon if not exists.
        newSubscription.plan.addon.products.push(addonName);
      }
      setSubscription(newSubscription);
    }
  }

  function toggleServicePack(servicePack: SubscriptionServicePackName) {
    const newSubscription = { ...subscription };
    const existingPos = newSubscription.plan.addon.products.indexOf(servicePack);
    if (existingPos >= 0) {
      // The service pack has been enabled, now let's remove it.
      newSubscription.plan.addon.products.splice(existingPos, 1);
    } else {
      // The service pack is not enabled. Let's add it in and remove other service packs if enabled.
      const allServicePacks = [SubscriptionProduct.ServicePack1, SubscriptionProduct.ServicePack2, SubscriptionProduct.ServicePack3];
      newSubscription.plan.addon.products = newSubscription.plan.addon.products.filter((addon: SubscriptionProduct) => !allServicePacks.includes(addon));
      newSubscription.plan.addon.products.push(servicePack);
    }
    setSubscription(newSubscription);
  }

  return {
    subscription,
    isLoading,
    selectPackage,
    toggleAddon,
    toggleServicePack
  };
}
