import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import { sellerHost } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface InvoiceFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  attendeeCategoryId?: number
}

interface FormData {
  accountName: string
  bsb: string
  accountNumber: string
  isEnabled?: boolean
}

interface FormErrors {
  accountName?: string
  bsb?: string
  accountNumber?: string
  isEnabled?: string
}

const initFormData: FormData = {
  accountName: '',
  bsb: '',
  accountNumber: '',
  isEnabled: false
};

const isEnabledOptions: Option[] = [
  { value: true, label: 'Enable' },
  // { value: 'Medical', label: 'Medical', isDisabled: true },
  { value: false, label: 'Disable' }
];

const InvoiceForm: FC<InvoiceFormProps> = ({
  // tenantCode,
  onClose,
  onSaveSuccess,
  isVisible,
  attendeeCategoryId = undefined
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData): FormErrors => {
        const errors: FormErrors = {};
        return errors;
      }}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        // await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Set your bank account'}
        size={'xl'}
        // isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => (
              <BaseMessageBarError key={index}>
                {error}
              </BaseMessageBarError>
            ))}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'isEnabled'}
            label={'Enable this method?'}
            options={isEnabledOptions}
          />
          <BaseFormInputField
            name={'accountName'}
            label={'Account name'}
          />
          <BaseFormInputField
            name={'bsb'}
            label={'BSB'}
          />
          <BaseFormInputField
            name={'accountNumber'}
            label={'Account number'}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default InvoiceForm;
