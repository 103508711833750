import { type FC, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { type Options } from 'react-select';
import {
  UiButton,
  type UiHStackProps,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseButtonRouterLink from '@/base/Button/RouterLink';
import { generatePageUrl } from '@/app/pages';

export interface TeamFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  name: string
  description: string
  members: string[]
}

interface Errors {
  name?: string
  description?: string
  members?: string
}

const teamOptions: Option[] = [
  { value: '1', label: 'Derek Li' },
  // { value: 'Medical', label: 'Medical', isDisabled: true },
  { value: '2', label: 'Tony Fu' },
  { value: '3', label: 'Anthony James' }
];

const initFormData = {
  name: '',
  description: '',
  members: []
};

const TeamForm: FC<TeamFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  return (
    <Formik
      initialValues={formData}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData): Errors => {
        const errors: Errors = {};
        // if (!values.name) {
        //   errors.name = 'Required';
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.name = 'Invalid email address';
        // }
        // return errors;
        return {
          // name: 'adfasdf',
        };
      }}
      onSubmit={async (
        values: FormData
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        alert(JSON.stringify(values, null, 2));
        // setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Invite admin user'}
        size={'lg'}
        // isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'name'}
            label={'Name'}
          />
          <BaseFormInputField
            name={'description'}
            label={'Description'}
            isRequired={false}
          />
          <BaseFormSelectField
            name={'members'}
            label={'Admin users'}
            options={teamOptions}
            // helper={(
            //   <BaseButtonRouterLink
            //     to={generatePageUrl('AccountAccessAdminUser')}
            //     description={'You will be redirected to members page'}
            //   >
            //     Manage members
            //   </BaseButtonRouterLink>
            // )}
            // isRequired={false}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default TeamForm;
