import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
// import FormCard from './FormCard';
import { UiHStack, UiText, UiSimpleGrid } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type InfoForm, type InfoFormType } from '@/api/registration';
import NewForm from './NewForm';
import FormCard from './FormCard';

export interface FormListProps {
  formType: InfoFormType
}

const FormList: FC<FormListProps> = ({ formType }) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const infoFormsQuery = useQuery(
    [registration.infoFormsQueryKey, { eventId, formType }],
    async () => {
      const result = await registration.loadInfoForms(createTenantAdminApiRequest)({ formType, eventId });
      const items = result.items;
      const parsedItems = items.map((item) => {
        item.config = JSON.parse(item.config);
        return item as unknown as InfoForm;
      });
      return parsedItems;
    },
    {
      enabled: !isApiPreparing
    }
  );

  return (
    <QueryContainer query={infoFormsQuery}>
      {(infoForms) => (
        <>
          <UiHStack justifyContent={'space-between'}>
            <NewForm />
            <UiText>Total: {infoForms.length}</UiText>
          </UiHStack>
          {infoForms && infoForms.length > 0 && (
            <UiSimpleGrid spacing={8} columns={2}>
              {infoForms.map((formItem) => (
                <FormCard key={formItem.id} infoForm={formItem} />
              ))}
            </UiSimpleGrid>
          )}
        </>
      )}
    </QueryContainer>
  );
};

export default FormList;
