import { type FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiHStack,
  type UiHStackProps,
  UiImage,
  uiStyles
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import { type registration } from '@/api';
import EventForm from './EventForm';

dayjs.extend(utc);

export interface EventRowProps extends UiHStackProps {
  event: registration.Event
  isSelected?: boolean
}

const EventRow: FC<EventRowProps> = ({
  event,
  isSelected = false,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        p={4}
        // px={8}
        flexGrow={1}
        spacing={4}
        // bgColor={isSelected ? 'primary.50' : 'transparent'}
        {...uiStyles.hover}
        {...props}
        onClick={onToggle}
      >
        <UiImage
          src={event.menuLogo}
          borderRadius={uiStyles.borderRadius}
          maxWidth={'64px'}
        />
        <BaseTextList
          flexGrow={1}
        >
          <BaseTextListItem variant='body1' fontWeight={400}>{event.name}</BaseTextListItem>
          {/* <BaseTextListItem variant='body2' color={'text.secondary'}>{text.trimText(data.description, 50)}</BaseTextListItem> */}
          {event.startDate && event.endDate ? (
            <BaseTextListItem variant='caption' color={'text.secondary'}>
              {dayjs(event.startDate).format('DD MMM YYYY')} - {dayjs(event.endDate).format('DD MMM YYYY')}
            </BaseTextListItem>
          ) : null}
        </BaseTextList>
        {/* <EventStateTag state={data.state}/> */}
        {/* {isSelected && (<UiIconCheck size={'3xl'} weight={'bold'} color={'green.500'}/>)} */}
      </UiHStack>
      <EventForm
        event={event}
        isVisible={isOpen}
        onClose={onToggle}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default EventRow;
