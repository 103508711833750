import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconChartLine } from '@/lib/ui';

export interface DataInsightCardProps {
  isSelected?: boolean
  navLink?: string
}

const DataInsightCard: FC<DataInsightCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      icon={(<UiIconChartLine size={'3xl'} color={'gray.800'} />)}
      name={'Data insight'}
      description={'Charts, reports and analysis.'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default DataInsightCard;
