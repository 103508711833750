import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { type EventSettings } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useIsSetMutation() {
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  return useMutation<EventSettings, Error, { eventId: string, key: keyof EventSettings, bool: boolean }>(
    async ({ eventId, key, bool }): Promise<EventSettings> => {
      const result = await registration.updateIsSetEventSetting(createTenantAdminApiRequest)({ eventId, key, isSet: bool });
      return result.items?.[0]?.config;
    },
    {
      onSuccess: async (_, { eventId }) => {
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      }
    }
  );
}
