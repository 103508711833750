import { type FC } from 'react';
import {
  Funnel
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconFunnelProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconFunnel: FC<UiIconFunnelProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Funnel}
      {...props}
    />
  );
};
