import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI
} from '@/api/tenantClient';

export const addressQueryKey = 'registration.addressList';

export interface Address {
  id: number
  street: string
  city: string
  country: string
  state: string
  postcode: string
  visitorId?: number
  userId?: number
  type: 'billing' | 'shipping'
}

export interface AddressQueryRequest {
  id: string
}

export function loadAddress(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: AddressQueryRequest
  ): Promise<ApiResponse<Address[]>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/addresses/${params.id}`;
    return await callApi<Address[]>(request);
  }
}

export interface AddressSaveRequestItem extends Omit<Address, 'id'> {}

export interface AddressSaveRequest {
  addresses: AddressSaveRequestItem[]
}

export function saveAddresses(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: AddressSaveRequest
  ): Promise<ApiResponse<Address[]>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/addresses';
    request.payload = {
      addresses: params.addresses
    };
    return await callApi<Address[]>(request);
  }
}

export interface AddressDeleteRequest {
  id: number
}

export function deleteAddress(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: AddressDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/addresses/' + params.id;
    await deleteAPI(request);
  }
}
