import { type FC } from 'react';
import { UiText, type UiTextProps, type UiTextVariant } from '@/lib/ui';

export interface ListItemProps extends UiTextProps {
  variant: UiTextVariant
  color?: UiTextProps['color']
}

const ListItem: FC<ListItemProps> = ({
  variant,
  color = 'text.primary',
  ...props
}) => {
  return (
    <UiText
      variant={variant}
      p={0}
      m={0}
      mt={[0, '0 !important']}
      color={color}
      {...props}
    />
  );
};

export default ListItem;
