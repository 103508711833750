import { type Event } from '../registration';
import { type ApiResponse, type ApiRequest, properCallApi } from '../tenantClient';

export interface EventSaveRequestItem {
  id?: string
  eventType: string
  name: string
  location: string
  shortName: string
  tagline: string
  websiteUrl: string
  mapUrl: string
  primaryColour: string
  secondaryColour: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  tmpCoverImage?: string
  tmpMenuLogo?: string
}

export interface EventSaveRequest {
  event: EventSaveRequestItem
}

export function createEvent(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSaveRequest): Promise<ApiResponse<Event>> => {
    if (params.event.id) {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/events/' + params.event.id;
      request.payload = params;
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/events';
      request.payload = params;
    }
    return await properCallApi<ApiResponse<Event>>(request);
  };
};
