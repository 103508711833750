// import logo from './logo.svg';
// import './App.css';
import { type FC } from 'react';
import { getAppConfig } from '@/app/config';
import { ProviderTheme } from '@/app/ProviderTheme';
import { ProviderQuery } from '@/app/ProviderQuery';
import { Router } from '@/app/ProviderPage';
import { ProviderConfig } from '@/app/ProviderConfig';
import { ProviderAdminAuth } from '@/app/ProviderAdminAuth';

const App: FC = () => {
  return (
    <ProviderConfig config={getAppConfig()}>
      <ProviderTheme>
        <ProviderQuery>
          <ProviderAdminAuth>
            <Router />
          </ProviderAdminAuth>
        </ProviderQuery>
      </ProviderTheme>
    </ProviderConfig>
  );
};

export default App;
