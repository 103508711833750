import { type FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiStack, UiText, uiStyles } from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { registration } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { type Order } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import RegistrationDetails from '../PaymentResult/RegistrationDetails';

const InvoiceResult: FC = () => {
  const [searchParams] = useSearchParams();
  const orderHash = searchParams.get('orderHash');
  const [error, setError] = useState(false);
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data, isLoading } = useQuery<ApiResponseSingle<Order>, Error>({
    queryKey: [registration.orderQueryKey, { orderHash }],
    queryFn: async () => await registration.loadOrder(createTenantApiRequest)(orderHash!),
    enabled: !!orderHash && !isApiPreparing
  });

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={4}>
          <UiStack>
            {error && <UiText variant={'h6'}>Invoice creation failed</UiText>}
            {data?.item && (
              <UiText variant={'h6'}>Invoice creation successful</UiText>
            )}
          </UiStack>
          {error && <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'}>{'Something went wrong, please try again later'}</BaseMessageBarInfo>}
          {data?.item && (
            <>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'green.100'}>
                { "We've sent the receipt with your payment details to your email. It might take several minutes to arrive. Please also check your spam." }
              </BaseMessageBarInfo>
              <RegistrationDetails order={data.item} /></>
          )}
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={32} />
    </RegisterLayout>
  );
};

export default InvoiceResult;
