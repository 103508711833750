import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiStack,
  UiText,
  uiStyles,
  UiHStack,
  UiButton,
  UiIconShare
} from '@/lib/ui';
import dayjs from 'dayjs';
import { type Order } from '@/api/registration/order';
import { useEventQuery } from '@/registration/hook/useEventQuery';

export interface RegistrationDetailsProps {
  order: Order
}

const RegistrationDetails: FC<RegistrationDetailsProps> = ({ order }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { data: event } = useEventQuery(order.eventId);

  return (
    <>
      <UiStack
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        {...uiStyles.hover}
        onClick={onToggle}
        justifyContent={'space-between'}
        px={{ base: 4, lg: 8 }}
        py={8}
        spacing={4}
        enableDivider={true}
      >
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Serial</UiText>
          <UiText variant='body1'>{order.serial}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Name</UiText>
          <UiText variant='body1'>{order.attendee.name}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Email</UiText>
          <UiText variant='body1'>{order.attendee.email}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Event</UiText>
          <UiText variant='body1'>{order.event}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Total Price</UiText>
          <UiText variant='body1'>{order.totalPrice}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Purchased</UiText>
          <UiText variant='body1'>{dayjs(order.purchasedAt).format('DD MMM YYYY, h:mm A')}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Tickets</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='caption'>{'Name'}</UiText>
          <UiText variant='caption'>{'Quantity'}</UiText>
        </UiHStack>
        {order.tickets.map((ticket, index) => (
          <UiHStack key={index} justifyContent={'space-between'}>
            <UiText variant='body2'>{ticket.name}</UiText>
            <UiText variant='body2'>{ticket.quantity}</UiText>
          </UiHStack>
        ))}
      </UiStack>
      <UiHStack justifyContent={'flex-end'}>
        {/* <UiButton
          variant={'ghost'}
          colorScheme={'primary'}
          leftIcon={(<UiIconShare size={'3xl'} color={'primary.500'} />)}
        >
          Share
        </UiButton> */}
        <UiButton
          px={8}
          size={'lg'}
          shadow={'base'}
          colorScheme={'primary'}
          onClick={() => window.open(event?.websiteUrl, '_blank')}
        >
          Go to {order.event} website
        </UiButton>
      </UiHStack>
    </>
  );
};

export default RegistrationDetails;
