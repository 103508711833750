import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  UiTable,
  UiThead,
  UiTbody,
  UiTr,
  UiTh,
  UiTd,
  UiTableContainer,
  uiStyles,
  UiStack,
  UiText,
  UiHStack,
  UiIconEye,
  UiIconList
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface PaymentTableProps {
}

const thStyles = {
  // fontSize: 'lg',
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6
};

export type BillStatus = 'paid' | 'unpaid' | 'processing';

const tableRows = [
  ['11202310', 'N/A', '$11,000', 'unpaid'],
  ['11202309', '2023/09/15', '$11,000', 'paid'],
  ['11202308', '2023/08/15', '$9,500', 'paid']
];

const PaymentTable: FC<PaymentTableProps> = () => {
  return (
    <>
      <UiHStack flexGrow={1} justifyContent={'space-between'} pb={4}>
        <UiText variant={'title'}>History</UiText>
        <UiStack {...uiStyles.hover}>
          <UiIconList size={'4xl'} color={'primary.500'}/>
        </UiStack>
      </UiHStack>
      <UiTableContainer>
        <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
          {/* <UiTableCaption>Imperial to metric conversion factors</UiTableCaption> */}
          <UiThead>
            <UiTr>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Invoice number</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Paid at</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Amount</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Status</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}></UiText></UiTh>
            </UiTr>
          </UiThead>
          <UiTbody>
            {tableRows.map((row, rowIndex) => {
              return (
                <UiTr key={rowIndex} {...uiStyles.hover}>
                  {row.map((col, colIndex) => {
                    return (
                      <UiTd {...tdStyles} key={colIndex}>{col}</UiTd>
                    );
                  })}
                  <UiTd {...tdStyles}>
                    <UiStack {...uiStyles.hover}>
                      <UiIconEye size={'3xl'} color={'primary.400'}/>
                      {/* <UiText variant={'body1'} color={'primary.400'}>More</UiText> */}
                    </UiStack>
                  </UiTd>
                </UiTr>
              );
            })}
          </UiTbody>
        </UiTable>
      </UiTableContainer>
    </>
  );
};

export default PaymentTable;
