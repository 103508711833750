import { type FC, useState, useEffect } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import { type TicketType, discountTypeOptions } from '@/api/constant';
import { UiButton, UiHStack, type UiHStackProps, UiStack, UiSwitch, UiText, uiStyles, UiSpinner } from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getProductType, type TicketDiscountSaveRequestItem } from '@/api/registration';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { mapTicketDiscountToDiscountRuleOption } from '@/registration/buildEvent/MainEventTickets/ManageDiscounts';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface DiscountRuleOption {
  id: number | string
  discountCodeId: number
  discountCodeName: string
  discountCodeDescription: string
  discountCodeType: Option | undefined
  discountCodeAmount: number | undefined
  discountCodeActive: boolean | undefined
}

export interface TicketOption {
  value: number
  label: string
}

export interface FormData {
  copyTicket: number | null
  discountCodeId: string
  discountCodeActive: Record<string, boolean | undefined>
  'type': Record<string, string | undefined>
  'amount': Record<string, number | undefined>
}

const generateInitialFormData = (discountOptions: DiscountRuleOption[], ticketOptions: TicketOption[]): FormData => {
  const initialFormData: FormData = {
    copyTicket: ticketOptions[0].value,
    discountCodeId: '',
    type: {},
    amount: {},
    discountCodeActive: {}
  };

  discountOptions.forEach((option) => {
    initialFormData.type[option.id] = option.discountCodeType?.value as string ?? undefined;
    initialFormData.amount[option.id] = option.discountCodeAmount ?? 0;
    initialFormData.discountCodeActive[option.id] = option.discountCodeActive ?? false;
  });

  return initialFormData;
};

const generateNewFormData = (existingDiscountOptions: DiscountRuleOption[], newDiscountOptions: DiscountRuleOption[], ticketOptions: TicketOption[]): FormData => {
  const newFormData: FormData = {
    discountCodeActive: {},
    copyTicket: ticketOptions[0].value ?? null,
    discountCodeId: '',
    type: {},
    amount: {}
  };

  existingDiscountOptions.forEach((option) => {
    const newOptionData = newDiscountOptions.find((newOption) => newOption.discountCodeId === option.discountCodeId);
    if (!newOptionData) {
      throw new Error(`No matching option found for id ${option.id}`);
    }
    newFormData.type[option.id] = newOptionData.discountCodeType?.value as string ?? '';
    newFormData.amount[option.id] = newOptionData.discountCodeAmount ?? 0;
    newFormData.discountCodeActive[option.id] = newOptionData.discountCodeActive ?? false;
  });

  return newFormData;
};

export interface DiscountRulesFormProps extends UiHStackProps {
  onClose: () => void
  onSave: () => void
  isVisible: boolean
  discountOptions: DiscountRuleOption[]
  ticketOptions: TicketOption[]
  ticketId: number
  ticketType: TicketType
}

const DiscountRulesForm: FC<DiscountRulesFormProps> = ({ onClose, onSave, isVisible, discountOptions, ticketOptions, ticketId, ticketType }) => {
  const [formData, setFormData] = useState<FormData>(generateInitialFormData(discountOptions, ticketOptions));
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [loadingDiscountOptions, setLoadingDiscountOptions] = useState(true);
  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  useEffect(() => {
    setFormData(generateInitialFormData(discountOptions, ticketOptions));
    setLoadingDiscountOptions(false);
  }, [discountOptions, ticketOptions]);

  const validate = (values: FormData) => {
    const errors: Record<string, string> = {};

    discountOptions.forEach((option) => {
      if (values.discountCodeActive[option.id]) {
        if (!values.type[option.id]) {
          errors[`type[${option.id}]`] = 'Type is required.';
        }
        if (!values.amount[option.id]) {
          errors[`amount[${option.id}]`] = 'Amount is required.';
        }
      }
    });

    return errors;
  };

  const { mutate, isLoading } = useMutation(
    async (ticketDiscounts: TicketDiscountSaveRequestItem[]) => {
      return await registration.saveTicketDiscounts(createTenantAdminApiRequest)({ ticketDiscounts });
    },
    {
      onSuccess: () => {
        setLoadingDiscountOptions(true);
        void queryClient.invalidateQueries([registration.ticketDiscountOptionQueryKey, { eventId, ticketId, ticketType }]);
        void queryClient.invalidateQueries([registration.ticketListQueryKey, { eventId, ticketType }]);
        setLoadingDiscountOptions(false);
        onClose();
      },
      onError: (error: any) => {
        setSaveErrors([error.message ?? 'Failed to save the host.']);
      }
    }
  );

  const submitForm = async (values: FormData) => {
    if (ticketType) {
      const ticketDataArray: TicketDiscountSaveRequestItem[] = Object.keys(values.type)
        .map((id) => {
          const option = discountOptions.find((discountOption) => String(discountOption.id) === String(id));
          if (!option) {
            throw new Error(`No matching option found for id ${id}`);
          }

          return {
            id,
            active: values.discountCodeActive[id]!,
            discountId: option.discountCodeId,
            ticketId,
            ticketType: getProductType(ticketType),
            discountType: Number(values.type[id]),
            amount: values.amount[id]!
          };
        });
      mutate(ticketDataArray);
    }
  };

  const loadDiscountOptions = async (copyTicket: number) => {
    const response = await registration.loadTicketDiscountOptions(createTenantAdminApiRequest)({ eventId, ticketId: copyTicket, ticketType: getProductType(ticketType) });
    const ticketDiscountOptionData = (response.items as unknown) as registration.TicketDiscountOption[];

    const options: DiscountRuleOption[] = ticketDiscountOptionData.map(mapTicketDiscountToDiscountRuleOption);

    return { options };
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      onSubmit={async (
        values: FormData,
        { setSubmitting, setFieldValue, setValues }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values, setValues, setFieldValue }) => {
        const handleApplyClick = async () => {
          // Fetch new DiscountRuleOptions based on the selected ticket
          const { options: newDiscountOptions } = await loadDiscountOptions(values.copyTicket!);
          // Generate new form data based on the fetched DiscountRuleOptions
          const newFormData = generateNewFormData(discountOptions, newDiscountOptions, ticketOptions);
          await setValues(newFormData);
        };

        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Manage discounts'}
            size={'2xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                ))}
              </UiStack>
            )}
            <UiHStack spacing={8} alignItems={'flex-end'}>
              <BaseFormSelectField
                name={'copyTicket'}
                label={'Clone discount code rules from ticket'}
                options={ticketOptions}
                isRequired={false}
                layout={'stack'}
              />
              <UiButton colorScheme={'primary'} onClick={() => { void handleApplyClick(); }}>Apply</UiButton>
            </UiHStack>
            <BaseDividerHorizontal height={8} />
            {loadingDiscountOptions ? (
              <UiSpinner />
            ) : (
              <UiStack spacing={4} enableDivider={true}>
                <UiText>All discount codes</UiText>
                {discountOptions.map((option) => (
                  <UiHStack spacing={8} p={4} borderRadius={uiStyles.borderRadius} key={option.id}>
                    <UiStack>
                      <UiSwitch
                        isChecked={values.discountCodeActive[option.id]}
                        onChange={(e) => { void setFieldValue(`discountCodeActive[${option.id}]`, e.target.checked); }}
                      />
                    </UiStack>
                    <UiStack minW={200}>
                      <UiText>{option.discountCodeName}</UiText>
                      <UiText variant={'body2'} color={'text.secondary'}>{option.discountCodeDescription}</UiText>
                    </UiStack>
                    <BaseFormSelectField
                      name={`type[${option.id}]`}
                      label={'Type'}
                      options={discountTypeOptions}
                      layout={'stack'}
                      isRequired={!!values.discountCodeActive[option.id]}
                    />
                    <BaseFormInputField
                      name={`amount[${option.id}]`}
                      label={'Amount'}
                      type={'number'}
                      layout={'stack'}
                      isRequired={!!values.discountCodeActive[option.id]}
                    />
                  </UiHStack>
                ))}
              </UiStack>
            )}
          </BaseFormDrawer>
        );
      }}
    </Formik>
  );
};

export default DiscountRulesForm;
