import { type BackgroundProps, type EffectProps } from '@chakra-ui/react';

export interface HoverFlexOptions {
  bgColor?: BackgroundProps['bgColor']
  opacity?: EffectProps['opacity']
  scale?: number
}

export const uiStyles = {
  hover: {
    // transform: 'opacity',
    transition: 'all .2s ease-in-out',
    // transitionDuration: '0.2s',
    // transitionTimingFunction: 'ease-in-out',
    opacity: 1,
    _hover: {
      opacity: 0.6,
      // transform: 'opacity',
      transition: 'all .2s ease-in-out',
      // transitionDuration: '0.2s',
      // transitionTimingFunction: 'ease-in-out',
      cursor: 'pointer'
    }
  },
  hoverFlex: (options: HoverFlexOptions) => {
    const transform = options.scale ? `scale(${options.scale})` : '';
    return {
      transition: 'all .2s ease-in-out',
      _hover: {
        bgColor: options.bgColor ?? 'none',
        transition: 'all .2s ease-in-out',
        transform,
        opacity: options.opacity ?? 1,
        cursor: 'pointer'
      }
    };
  },
  glass: {
    shadow: 'base',
    bgColor: 'whiteAlpha.600',
    borderWidth: '2px',
    borderColor: 'whiteAlpha.700',
    borderStyle: 'solid'
  },
  transition: {
    zoomIn: {
      transform: 'scale'
    }
  },
  borderRadius: 8,
  formElementBorderRadius: 8,
  cardShadow: 'sm'
};
