import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
// import { sellerHost } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import * as Yup from 'yup';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSettingsPrivacy, type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface TermsAndConditionProps extends UiHStackProps {
  isVisible: boolean
  onClose: () => void
  privacy: EventSettingsPrivacy | undefined
}

const formSchema = Yup.object().shape({
  termsAndConditionsUrl: Yup.string()
    .url('Invalid link')
    .required('Terms and Condition URL is required.')
});

interface FormData {
  termsAndConditionsUrl: string | undefined
};

const TermsAndConditionForm: FC<TermsAndConditionProps> = ({
  privacy,
  onClose,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { mutate, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save']);
    }
  });

  const submitForm = async (values: FormData) => {
    mutate({
      eventId,
      config: {
        privacy: {
          isEnabled: true,
          privacyPolicyUrl: privacy?.privacyPolicyUrl,
          termsAndConditionsUrl: values?.termsAndConditionsUrl
        }
      }
    }, {
      onSuccess: onClose
    });
  };
  return (
    <Formik
      initialValues={{ termsAndConditionsUrl: privacy?.termsAndConditionsUrl ?? '' }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title="Edit Terms and Condition"
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name="termsAndConditionsUrl"
            label="Terms and Condition URL"
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default TermsAndConditionForm;
