/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode } from 'react';
import {
  UiHStack,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalOverlay,
  UiText
} from '@/lib/ui';

export interface FullScreenProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

const FullScreen: FC<FullScreenProps> = ({
  children,
  isOpen,
  onClose
}) => {
  return (
    <>
      <UiModal
        onClose={onClose}
        size={'full'}
        isOpen={isOpen}
        motionPreset={'scale'}
        scrollBehavior={'inside'}
      >
        <UiModalOverlay />
        <UiModalContent>
          {/* <UiModalHeader>Modal Title</UiModalHeader> */}
          <UiHStack
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            p={6}
            // px={8}
            bgColor={'blackAlpha.100'}
          >
            <UiText
              display={{ base: 'none', md: 'flex' }}
            >
              Press "esc" key to close.
            </UiText>
            <UiModalCloseButton alignSelf={'flex-end'} p={4} size={'lg'} />
          </UiHStack>
          <UiModalBody mt={{ base: 4, md: 0 }}>
            {children}
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default FullScreen;
