import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconUserCircle } from '@/lib/ui';

export interface IntegrationCardProps {
  isSelected?: boolean
  navLink?: string
}

const IntegrationCard: FC<IntegrationCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/integrations.jpeg'}
      name={'Integrations'}
      description={'Enable and manage integrations with other systems'}
      isSelected={isSelected}
      // bgColor={'red'}
      navLink={navLink}
    />
  );
};
export default IntegrationCard;
