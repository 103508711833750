import { useEffect, type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import { UiGrid } from '@/lib/ui';
import Ticket from './Ticket';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useVisitor } from '@/app/ProviderVisitor';

export interface TicketListProps {
  ticketType: TicketType
  onEmpty?: () => void
}

const TicketList: FC<TicketListProps> = ({ ticketType, onEmpty }) => {
  const { eventId } = useRegisterRoute();
  const { visitor } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const ticketQuery = useQuery<registration.TicketListData, Error>({
    queryKey: [registration.ticketListQueryKey, { eventId, ticketType }],
    queryFn: async () => {
      const response = await registration.loadTicketListByAttendeeCategory(createTenantApiRequest)({
        eventId,
        attendeeCategoryId: visitor!.attendeeCategoryId,
        ticketType,
        visitorId: visitor!.id
      });
      return response.items;
    },
    enabled: !!visitor && !isApiPreparing
  });

  useEffect(() => {
    if (!ticketQuery.isLoading && !ticketQuery.data?.length) {
      onEmpty?.();
    }
  }, [ticketQuery.isLoading, ticketQuery.data, onEmpty]);

  return (
    <QueryContainer query={ticketQuery}>
      {data => (
        <UiGrid
          templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap={8}
        >
          {data.map((ticketData) => (
            <Ticket
              key={ticketData.id}
              ticket={ticketData}
            />
          ))}
        </UiGrid>
      )}
    </QueryContainer>
  );
};

export default TicketList;
