import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  UiSwitch,
  uiStyles
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useDisclosure } from '@chakra-ui/react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const Merchandise: FC = () => {
  const { isOpen: isSkipped, onToggle: onSkipToggle } = useDisclosure();
  const { tenantCode, eventId } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'merchandise'}
          />
        )}
        message={'Please design your badge.'}
      >
        <UiHStack pb={4}>
          {isSkipped
            ? (
              <UiText variant={'title'} color={'primary.500'}>
                Step skipped
              </UiText>
            ) : (
              <UiText variant={'title'} color={'text.secondary'}>
                Skip this step
              </UiText>
            )}
          <UiSwitch colorScheme={'primary'} onChange={onSkipToggle} size={'lg'} />
        </UiHStack>
        {isSkipped
          ? (
            <UiStack pb={8} px={8}>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>There are no function tickets for this event.</BaseMessageBarInfo>
            </UiStack>
          ) : (
            <UiStack />
          )}
        <UiStack flexGrow={1}>
          {/* <UiText>Same as main tickets</UiText> */}
        </UiStack>
        <UiHStack
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <BaseRouterLink to={generatePageUrl('RegistrationBuildEventMerchandise', { tenantCode, eventId })}>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'}>
              Next
            </UiButton>
          </BaseRouterLink>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default Merchandise;
