import { type FC } from 'react';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import TermsAndConditionForm from './Form/TermsAndConditionForm';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

export interface TermsAndConditionsProps extends UiHStackProps {}

const TermsAndConditions: FC<TermsAndConditionsProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  return (
    <QueryContainer query={eventQuery}>
      {(eventSettings) => (
        <>
          <UiHStack
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            // alignItems={'flex-start'}
            // justifyContent={'flex-start'}
            spacing={0}
            minWidth={'300px'}
            py={6}
            px={8}
            bgColor={'#fff'}
            borderRadius={uiStyles.borderRadius}
          >
            <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'} flexGrow={1}>
              <UiText variant={'body1'} fontWeight={600}>Terms and conditions url</UiText>
              <UiText variant={'body2'} color={eventSettings.privacy?.termsAndConditionsUrl ? 'text.secondary' : 'red.500'}>{eventSettings.privacy?.termsAndConditionsUrl ?? 'Not provided'}</UiText>
            </UiStack>
            <UiHStack
              spacing={0}
              justifyContent={'flex-end'}
              onClick={onToggle}
            >
              <UiButton px={0} variant={'ghost'} {...uiStyles.hover} colorScheme={'primary'}>Edit</UiButton>
            </UiHStack>
          </UiHStack>
          <TermsAndConditionForm
            isVisible={isOpen}
            onClose={onToggle}
            privacy={eventSettings.privacy}
          />
        </>
      )}
    </QueryContainer>

  );
};

export default TermsAndConditions;
