import { type FC, ReactNode } from 'react';
import BaseRouterLink from '@/base/Router/Link';
import {
  UiHStack,
  type UiHStackProps,
  UiIconCheck,
  UiImage,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseInfoBlockGroup from '@/base/InfoBlock/Group';
import BaseInfoBlockItem from '@/base/InfoBlock/Item';

export interface TeamCardProps extends UiHStackProps {
  name: string
  numeOfMembers: number
  description?: string
  navLink?: string
}

const TeamCard: FC<TeamCardProps> = ({
  name,
  numeOfMembers,
  description,
  navLink = undefined,
  ...props
}) => {
  return (
    // We need this UiStack to make sure the card will grow to its max width.
    <BaseRouterLink to={navLink ?? ''}>
      <UiStack flexGrow={1} {...props}>
        <BaseInfoBlockGroup
          enableShadow={true}
          enableSeparator={false}
          bgColor={'#fff'}
          shadow={'none'}
          p={4}
          minWidth={360}
          {...uiStyles.hover}
          borderRadius={uiStyles.borderRadius}
        >
          <BaseInfoBlockItem p={4}>
            <UiStack flexGrow={1} justifyContent={'space-between'}>
              <UiText variant={'title'}>{name}</UiText>
              <UiText variant={'body2'} color={'text.secondary'}>{description}</UiText>
            </UiStack>
          </BaseInfoBlockItem>
          <BaseInfoBlockItem p={4} pt={2}>
            <UiHStack flexGrow={1} justifyContent={'space-between'}>
              <UiText variant={'body1'}>Admin users</UiText>
              <UiText variant={'body2'}>{numeOfMembers}</UiText>
            </UiHStack>
          </BaseInfoBlockItem>
        </BaseInfoBlockGroup>
      </UiStack>
    </BaseRouterLink>
  );
};

export default TeamCard;
