import { type FC, useState } from 'react';
import {
  UiButton
} from '@/lib/ui';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { type PaymentIntent } from '@/api/registration/payment';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { getAppConfig } from '@/app/config';
import { type CartDisplay } from '@/api/registration';

export interface PayByStripeProps {
  setErrors: React.Dispatch<React.SetStateAction<string[]>>
  options: PaymentIntent
  cart: CartDisplay
}

const PayByStripe: FC<PayByStripeProps> = ({
  setErrors,
  options,
  cart,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { tenantCode, eventId } = useRegisterRoute();
  const appConfig = getAppConfig();
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setStripeLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const baseUrl = appConfig.host;
    const returnRoute = generatePageUrl('RegistrationRegisterPaymentResult', { tenantCode, eventId }, { orderHash: options.order.secureHash });

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: `${baseUrl}${returnRoute}` }
    });

    setStripeLoading(false);

    if (result.error.message) {
      setErrors([result.error.message]);
    }
  };

  return (
    <form onSubmit={(e) => { void handleSubmit(e); }}>
      <PaymentElement />
      <UiButton mt={4} px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type='submit' isLoading={stripeLoading} >
          Pay
      </UiButton>
    </form>
  );
};

export default PayByStripe;
