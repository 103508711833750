import { type ElementType, type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiStack,
  UiFlex,
  UiHStack,
  uiStyles,
  UiBox
} from '@/lib/ui';
import { smartWindowWidth, menuWidth } from './viewport';
// import { useWindowSize } from '@/lib/hook';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import BaseSmartButton from '@/base/Layout/SmartButton';
import SmartWindowDrawer from '@/base/Layout/SmartWindowDrawer';
import { useWindowScrollY } from '@/lib/hook';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseAppAccountCard from '@/base/App/AccountCard';

export interface LayoutProps {
  children: ReactNode
  smartWindow?: ReactNode
  breadcrumb?: ReactNode
  appSwitch?: ReactNode
  isSmartWindowVisible?: boolean
  sideMenu?: ReactNode
  smartButton?: ReactNode
  disableTopNav?: boolean
  MainTransition?: ElementType | null
}

const topNavHeight = 112;

const bgGradients = [
  'linear(160deg, primary.50 10%, indigo.50 10%, gray.200 90%, indigo.50 10%, primary.50 10%)'
  // 'linear(160deg, primary.50 10%, indigo.50 20%, gray.200 100%)',
];

export const Layout: FC<LayoutProps> = ({
  children,
  smartWindow = undefined,
  MainTransition = BaseTransitionSlideLeft,
  disableTopNav = false,
  sideMenu = undefined,
  appSwitch = undefined,
  breadcrumb = undefined,
  smartButton = undefined
}) => {
  const { isOpen: isSmartWindowVisible, onToggle: onSmartWindowToggle } = useDisclosure();
  const [topNavOpacity, setTopNavOpacity] = useState(1);
  const [topNavScale, setTopNavScale] = useState(1);
  const windowScrollY = useWindowScrollY();

  useEffect(
    () => {
      setTopNavOpacity(1 - 2 * windowScrollY / topNavHeight);
      setTopNavScale(Math.min(1 - (0.15 * windowScrollY) / topNavHeight, 1));
    },
    [
      windowScrollY
    ]
  );

  // const { clientWidth: windowWidth } = useWindowSize();
  // const mainWindowTranslate = useMemo(
  //   () => {
  //     const props = {
  //       transform: 'translateX(0)',
  //       transitionDuration: '0.5s',
  //       transitionTimingFunction: 'ease-in-out'
  //     };
  //     if (isSmartWindowVisible) {
  //       props.transform = `translateX(-${smartWindowWidth}px)`;
  //     }
  //     return props;
  //   },
  //   [isSmartWindowVisible]
  // );

  return (
    <UiStack
      spacing={0}
      // p={4}
      minHeight={'100vh'}
      alignItems={'stretch'}
      bgGradient={bgGradients[0]}
      // pb={`${topNavHeight - 32}px`}
      // bg={'url("/image/bg/bg-gradient-green-blue-orange.jpg") no-repeat center'}
      // bg={'center / cover no-repeat url("/image/bg/bg-gradient-blue-orange-red.jpg")'}
      // bgGradient='radial(indigo.100, indigo.600)'
    >
      {!disableTopNav && (
        <UiStack
          // bgColor={'whiteAlpha.600'}
          position={'fixed'}
          width={'100%'}
          height={`${topNavHeight}px`}
          // bgColor={'gray.100'}
          opacity={topNavOpacity}
          transform={`scale(${topNavScale})`}
          px={8}
          py={8}
          // bgColor={'green'}
          // pt={4}
          // shadow={'base'}
          // bgGradient='linear(to-b, gray.100, indigo.50)'
        >
          <UiHStack
            // bgGradient='linear(to-b, primary.50, gray.100)'
            bgColor={'transparent'}
            justifyContent={'space-around'}
          >
            {appSwitch ?? (<UiBox />)}
            {breadcrumb ? (
              <UiStack flexGrow={1} px={8}>
                {breadcrumb}
              </UiStack>
            ) : (
              <UiStack flexGrow={1} />
            )}
            {smartButton ? (
              <UiBox
                {...uiStyles.hover}
                onClick={onSmartWindowToggle}
              >
                {smartButton}
              </UiBox>
            ) : (
              <BaseSmartButton onClick={onSmartWindowToggle} />
            )}
          </UiHStack>
        </UiStack>
      )}
      <UiHStack
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        overflowX={'hidden'}
        flexGrow={1}
        zIndex={99}
        mt={`${topNavHeight}px`}
        spacing={0.5}
      >
        {sideMenu ? (
          <UiStack
            minWidth={menuWidth}
            maxWidth={menuWidth}
            flexGrow={1}
            // pr={0.5}
          >
            {sideMenu}
          </UiStack>
        ) : null}
        <UiFlex
          flexGrow={1}
        >
          {/* {...mainWindowTranslate} */}
          <UiStack
            flexGrow={1}
          >
            { MainTransition ? (
              <MainTransition
                // shadow={'sm'}
              >
                {children}
              </MainTransition>
            ) : (
              <UiFlex
                flexGrow={1}
              >
                {children}
              </UiFlex>
            )}
          </UiStack>
        </UiFlex>
        {!!smartWindow && (
          <SmartWindowDrawer
            isOpen={isSmartWindowVisible}
            onClose={onSmartWindowToggle}
          >
            {smartWindow}
          </SmartWindowDrawer>
        )}
      </UiHStack>
      {/* <UiStack */}
      {/*  width={'100%'} */}
      {/*  height={`${topNavHeight}px`} */}
      {/*  px={8} */}
      {/*  pb={8} */}
      {/* > */}
      {/*  <UiHStack */}
      {/*    // bgGradient='linear(to-b, primary.50, gray.100)' */}
      {/*    bgColor={'transparent'} */}
      {/*    justifyContent={'space-around'} */}
      {/*  > */}
      {/*    <BaseAppAccountCard /> */}
      {/*  </UiHStack> */}
      {/* </UiStack> */}
    </UiStack>
  );
};

export default Layout;
