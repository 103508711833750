import { type FC, useState, useMemo } from 'react';
import { Form, Formik, type FormikHelpers } from 'formik';
import {
  UiGrid,
  UiText,
  type UiHStackProps,
  UiHStack,
  UiSwitch,
  uiStyles,
  UiStack,
  UiButton
} from '@/lib/ui';
import country from 'country-list-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import * as Yup from 'yup';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { type AddressSaveRequestItem } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useVisitor } from '@/app/ProviderVisitor';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

export interface AddressFormProps extends UiHStackProps {}

export interface AdressFormData {
  billingStreet: string
  billingCity: string
  billingCountry: string
  billingState: string
  billingPostcode: string
  hasShipping: boolean
  shippingStreet: string
  shippingCity: string
  shippingCountry: string
  shippingState: string
  shippingPostcode: string
}

const formSchema = Yup.object().shape({
  billingStreet: Yup.string()
    .required('Street address is required.'),
  billingCity: Yup.string()
    .required('City is required.'),
  billingCountry: Yup.string().required('Country is required.'),
  billingState: Yup.string()
    .required('State is required.'),
  billingPostcode: Yup.string()
    .required('Postcode is required.'),
  hasShipping: Yup.boolean(),
  shippingStreet: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping street address is required.')
    }),
  shippingCity: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping city is required.')
    }),
  shippingCountry: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Country is required.')
    }),
  shippingState: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping state is required.')
    }),
  shippingPostcode: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping postcode is required.')
    })
});

const AddressForm: FC<AddressFormProps> = () => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { toNextStep, toPreviousStep } = useRegisterSteps();
  const queryClient = useQueryClient();
  const { visitorId } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const countryOptions: Option[] = useMemo(
    () => country.names().map((countryName) => ({
      value: countryName,
      label: countryName
    })), []);

  const { mutateAsync, isLoading } = useMutation<{}, Error, AdressFormData>({
    mutationFn: async (addressData: AdressFormData) => {
      const billingAddress: AddressSaveRequestItem = {
        street: addressData.billingStreet,
        city: addressData.billingCity,
        country: addressData.billingCountry,
        state: addressData.billingState,
        postcode: addressData.billingPostcode,
        visitorId,
        type: 'billing'
      };

      const shippingAddress: AddressSaveRequestItem | null = addressData.hasShipping ? {
        street: addressData.shippingStreet,
        city: addressData.shippingCity,
        country: addressData.shippingCountry,
        state: addressData.shippingState,
        postcode: addressData.shippingPostcode,
        visitorId,
        type: 'shipping'
      } : { ...billingAddress, type: 'shipping' };

      const addresses: AddressSaveRequestItem[] = [billingAddress];
      if (shippingAddress) {
        addresses.push(shippingAddress);
      }
      return await registration.saveAddresses(createTenantApiRequest)({ addresses });
    },
    onSuccess: () => {
      toNextStep();
      void queryClient.invalidateQueries({ queryKey: [registration.addressQueryKey] });
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the address.']);
    }
  });

  const submitForm = async (values: AdressFormData) => {
    await mutateAsync(values);
  };

  return (
    <Formik
      initialValues={{
        billingStreet: '',
        billingCity: '',
        billingCountry: '',
        billingState: '',
        billingPostcode: '',
        hasShipping: false,
        shippingStreet: '',
        shippingCity: '',
        shippingCountry: '',
        shippingState: '',
        shippingPostcode: ''
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: AdressFormData,
        { setSubmitting }: FormikHelpers<AdressFormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <BaseTransitionSlideLeft>
            <UiStack flexGrow={1}>
              <BaseTransitionSlideLeft>
                <UiGrid
                  templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                  gap={12}
                  alignItems={'stretch'}
                  flexGrow={1}
                >
                  <BaseFormFieldGroup>
                    <UiText variant={'title'}>Billing address</UiText>
                    <BaseFormInputField
                      name={'billingStreet'}
                      label={'Street address'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingCity'}
                      label={'Town/suburb/City'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingState'}
                      label={'State'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingPostcode'}
                      label={'Postcode'}
                      layout={'stack'}
                    />
                    <BaseFormSelectField
                      name={'billingCountry'}
                      label={'Country'}
                      options={countryOptions}
                      layout={'stack'}
                    />
                  </BaseFormFieldGroup>
                  <BaseFormFieldGroup>
                    <UiHStack>
                      <UiSwitch
                        colorScheme={'primary'}
                        onChange={() => { void setFieldValue('hasShipping', !values.hasShipping); }}
                        isChecked={values.hasShipping}
                        size={'lg'}
                      />
                      <UiText variant={'title'}>Shipping address</UiText>
                    </UiHStack>
                    {values.hasShipping ? (
                      <>
                        <BaseFormInputField
                          name={'shippingStreet'}
                          label={'Street address'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingCity'}
                          label={'Town/suburb/City'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingState'}
                          label={'State'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingPostcode'}
                          label={'Postcode'}
                          layout={'stack'}
                        />
                        <BaseFormSelectField
                          name={'shippingCountry'}
                          label={'Country'}
                          options={countryOptions}
                          layout={'stack'}
                        />
                      </>
                    ) : (
                      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>Same as billing</BaseMessageBarInfo>
                    )}
                  </BaseFormFieldGroup>
                </UiGrid>
              </BaseTransitionSlideLeft>
            </UiStack>
          </BaseTransitionSlideLeft>
          <UiStack height={32} />
          <LayoutFooter justifyContent={'flex-end'}>
            <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
              Previous
            </UiButton>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type="submit" isLoading={isLoading}>
              Pay
            </UiButton>
          </LayoutFooter>
        </Form>
      )}
    </Formik>
  );
};

export default AddressForm;
