import { type FC } from 'react';
// import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
// import { UiStack } from '@/lib/ui';
import { UiStack, UiText, uiStyles } from '@/lib/ui';
import { type FieldMetadata } from '@/base/FormBuilder/Field';

interface InformationListProps {
  fieldsMetadata: FieldMetadata[] | undefined
  response: Record<string, any>
}

const InformationList: FC<InformationListProps> = ({ fieldsMetadata, response }) => {
  return (
    <UiStack
      spacing={4}
      py={8}
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      // borderWidth={'1px'}
      // borderColor={'gray.300'}
      // borderStyle={'solid'}
      enableDivider={true}
    >
      {fieldsMetadata
        ?.filter(fieldMetadata => fieldMetadata.type !== 'sectionBreak')
        ?.map((fieldMetadata) => (
          <InformationItem
            key={fieldMetadata.id}
            fieldMetadata={fieldMetadata}
            response={response} />
        ))}
    </UiStack>
  );
};

interface InformationItemProps {
  fieldMetadata: FieldMetadata
  response: Record<string, any>
}

const InformationItem: FC<InformationItemProps> = ({ fieldMetadata, response }) => {
  if (fieldMetadata.type === 'input') {
    return (
      <UiStack spacing={0} px={8}>
        <UiText variant={'body2'} color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.label.toLowerCase()]}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'select') {
    return (
      <UiStack spacing={0} px={8}>
        <UiText variant={'body2'} color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.label.toLowerCase()]}</UiText>
      </UiStack>
    );
  }
  if (fieldMetadata.type === 'descriptionBlock') {
    return (
      <UiStack spacing={0} px={8}>
        <UiText variant={'body2'} color={'text.secondary'}>{fieldMetadata.label}</UiText>
        <UiText>{response[fieldMetadata.label.toLowerCase()]}</UiText>
      </UiStack>
    );
  }
  return undefined;
};

export default InformationList;
