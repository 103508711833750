import { type SubscriptionPackage, SubscriptionProduct } from '@/api/constant/subscription';
import { type account } from '@/api';

export function isPackageEnabled(subscription: account.Subscription, packageName: SubscriptionPackage): boolean {
  return subscription.plan.package === packageName;
}

export function getAddonsEnabled(subscription: account.Subscription): SubscriptionProduct[] {
  return subscription.plan.addon.products;
}

export function getPackageProducts(subscription: account.Subscription, pack: SubscriptionPackage): SubscriptionProduct[] {
  return subscription[pack].products;
}

export function isProductEnabled(subscription: account.Subscription, productName: SubscriptionProduct): boolean {
  return getProductsEnabled(subscription).includes(productName);
}

export function getProductsEnabled(subscription: account.Subscription): SubscriptionProduct[] {
  return getPackageProducts(subscription, subscription.plan.package).concat(getAddonsEnabled(subscription));
}

export function isAddonAvailable(subscription: account.Subscription, addonName: SubscriptionProduct): boolean {
  return !subscription[subscription.plan.package].products.includes(addonName);
}

export function isAddonEnabled(subscription: account.Subscription, addonName: SubscriptionProduct): boolean {
  return isAddonAvailable(subscription, addonName) && isProductEnabled(subscription, addonName);
}

export type AddonPrices = {
  [M in SubscriptionProduct]?: number
}

export interface FirstYearPrice {
  total: number
  package: number
  addons: AddonPrices
  registration: number
  abstractSubmission: number
  digitalPoster: number
}

export type AddonMonths = {
  [M in SubscriptionProduct]?: number
}

export interface PriceContext {
  defaultAddonMonths: number
  addonMonths: AddonMonths
  numOfRegistrations?: number
  numOfAbstractSubmissions?: number
  numOfDigitalPosters?: number
}

export function calculateFirstYearPrice(subscription: account.Subscription, context: PriceContext): FirstYearPrice {
  const price: FirstYearPrice = {
    total: 0,
    package: 0,
    addons: {},
    registration: 0,
    abstractSubmission: 0,
    digitalPoster: 0,
  };

  // Package price
  if (subscription.plan.package) {
    price.package = subscription[subscription.plan.package].yearlyPrice;
  }

  // Addon price
  if (Array.isArray(subscription.plan.addon.products) && subscription.plan.addon.products.length > 0) {
    for (const prod of subscription.plan.addon.products) {
      if (context.addonMonths) {
        price.addons[prod] = subscription.products[prod].monthlyPrice * (context.addonMonths[prod] ?? context.defaultAddonMonths);
        price.total += price.addons[prod] ?? 0;
      }
    }
  }

  // Cost per registration in total
  if (isProductEnabled(subscription, SubscriptionProduct.Registration)) {
    // We do fix cost per abstract submission for now.
    price.registration = subscription.products[SubscriptionProduct.Registration].cprRate * (context.numOfRegistrations ?? 0);
  }

  // Cost per abstract submission in total
  if (isProductEnabled(subscription, SubscriptionProduct.AbstractManagement)) {
    // We do fix cost per abstract submission for now.
    price.abstractSubmission = subscription.products[SubscriptionProduct.AbstractManagement].cpsRate * (context.numOfAbstractSubmissions ?? 0);
  }

  // Cost per digital poster upload in total
  if (isProductEnabled(subscription, SubscriptionProduct.DigitalPoster)) {
    // We do fix cost per digital poster upload for now.
    price.digitalPoster = subscription.products[SubscriptionProduct.DigitalPoster].cppRate * (context.numOfDigitalPosters ?? 0);
  }

  // Calculate the total price.
  price.total += price.package + price.registration + price.abstractSubmission + price.digitalPoster;
  return price;
}
