import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconBroadcast } from '@/lib/ui';

export interface WebinarCardProps {
  isSelected?: boolean
  navLink?: string
}

const WebinarCard: FC<WebinarCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      icon={(<UiIconBroadcast size={'3xl'} color={'gray.800'} />)}
      name={'Webinar'}
      description={'Manage your own webinars.'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default WebinarCard;
