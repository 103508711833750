import { type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconClipboardText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface PersonalInfoProps {
  isActive?: boolean
}

const PersonalInfo: FC<PersonalInfoProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconClipboardText}
      description={'Personal information'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventPersonalInfoForm', { tenantCode, eventId })}
      isSet={eventSettings?.personalInformation?.isSet}
      // stepNumber={3}
    >
      <UiStack>
        <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.personalInformation?.totalFields ?? 0} Fields</UiText>
        {/* <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>Fields (max)</UiText>
          <UiText variant={'body2'} color={'text.primary'}>23</UiText>
        </UiStack> */}
        {/* <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>Sections</UiText>
          <UiText variant={'body2'} color={'text.primary'}>3</UiText>
        </UiStack> */}
      </UiStack>
    </Step>
  );
};

export default PersonalInfo;
