import { type FC } from 'react';
import {
  type UiBoxProps, UiHStack, UiIconHeadset, UiIconNavigationArrow,
  UiStack,
  uiStyles, UiText
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import AppWayfinderCard from '@/base/App/AppWayfinderCard';

export interface AppWayFinderProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const AppWayFinder: FC<AppWayFinderProps> = ({
  onToggle = undefined,
  subscription = undefined
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? getAddonsEnabled(subscription).includes(SubscriptionProduct.AppWayfinder) : false}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <AppWayfinderCard/>
          {subscription && (
            <PriceDisplay price={subscription.products[SubscriptionProduct.AppWayfinder].monthlyPrice} suffix={' / month'} />
          )}
        </UiHStack>
        <BaseDividerHorizontal height={4} />
        <UiText variant='body1' color={'text.secondary'}>Provide a Way-finder URL in the app.</UiText>
      </UiStack>
    </AddonCard>
  );
};

export default AppWayFinder;
