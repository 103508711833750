import { type PropsWithChildren, type FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ProviderVisitor, useVisitor } from '@/app/ProviderVisitor';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const RouteGuard: FC<PropsWithChildren> = ({ children }) => {
  const { eventId, tenantCode } = useRegisterRoute();
  const location = useLocation();
  const navigate = useNavigate();
  const { visitorId } = useVisitor();

  useEffect(() => {
    const defaultUrl = generatePageUrl('RegistrationRegisterCategory', { tenantCode, eventId });
    if (!visitorId && location.pathname !== defaultUrl) {
      navigate(defaultUrl);
    }
  }, []);

  return children;
};

const RegisterLayout: FC = () => (
  <ProviderVisitor>
    <RouteGuard>
      <Outlet />
    </RouteGuard>
  </ProviderVisitor>
);

export default RegisterLayout;
