import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText,
  UiHStack,
  UiTag,
  UiIconInfo, UiButton, UiIconDotsThree, UiIconDotsThreeOutline, UiIconCheckCircle
} from '@/lib/ui';
import AboutOnline from './AboutOnline';
import OnlinePaymentForm from '@/registration/buildEvent/PaymentMethods/OnlinePaymentForm';
// import { text } from '@/util';

export interface OnlineProps extends UiHStackProps {}

const Online: FC<OnlineProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isAboutOpen, onToggle: onAboutToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'center'}
        // {...uiStyles.hover}
        // alignItems={'flex-start'}
        // justifyContent={'flex-start'}
        spacing={4}
        py={6}
        px={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'} fontWeight={600}>Credit Card payments</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>Set up your own online payment gateway</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          <UiTag py={2} px={4} colorScheme={'red'}>Not enabled</UiTag>
          {/* <UiStack {...uiStyles.hover} onClick={onAboutToggle}> */}
          {/*  <UiIconInfo size={'3xl'} color={'primary.500'} /> */}
          {/* </UiStack> */}
          <UiHStack
            spacing={0}
            justifyContent={'space-between'}
          >
            <UiButton
              px={0}
              variant={'ghost'}
              {...uiStyles.hover}
              colorScheme={'primary'}
              onClick={onToggle}
            >
              Settings
            </UiButton>
          </UiHStack>
        </UiHStack>
      </UiHStack>
      <OnlinePaymentForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
      <AboutOnline
        onClose={onAboutToggle}
        isOpen={isAboutOpen}
      />
    </>
  );
};

export default Online;
