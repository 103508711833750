import { useState, type FC, useMemo } from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiButton,
  UiHStack,
  UiText,
  UiIconCurrencyCircleDollar,
  uiStyles
} from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import { type PaymentIntent, type PreparePaymentRequest } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import { registration } from '@/api';
import { locale } from '@/lib/util';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useVisitor } from '@/app/ProviderVisitor';
import Cart from '@/registration/component/Register/ShoppingCart/Cart';

export interface PaymentByInvoiceDrawerProps extends Omit<UiDrawerProps, 'children'> {
}

const { createFormatPrice } = locale;

const PaymentByInvoiceDrawer: FC<PaymentByInvoiceDrawerProps> = ({
  ...props
}) => {
  const queryClient = useQueryClient();
  const { visitorId } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [errors, setErrors] = useState<string[]>([]);
  const priceCurrency = 'AUD';
  const priceLocale = 'en-AU';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );
  const navigate = useNavigate();
  const { tenantCode, eventId } = useRegisterRoute();

  const { data: cart, isLoading: isCartLoading } = useCartQuery(visitorId);

  const { mutate: preparePaymentMutate, isLoading: isPreparePaymentLoading } = useMutation<ApiResponse<PaymentIntent>, Error, PreparePaymentRequest>({
    mutationFn: async (preparePaymentData: PreparePaymentRequest) => {
      return await registration.preparePayment(createTenantApiRequest)(preparePaymentData);
    },
    onSuccess: (order) => {
      void queryClient.invalidateQueries({ queryKey: [registration.paymentIntentQueryKey] });
      const orderResponse = order.items?.[0].order;
      if (orderResponse) {
        navigate(generatePageUrl('RegistrationRegisterInvoiceResult', { tenantCode, eventId }, { orderHash: orderResponse.secureHash }));
      }
    },
    onError: (error: any) => {
      setErrors([error.message ?? 'Failed to save the host.']);
    }
  });

  const onSubmit = () => {
    if (!cart) {
      return;
    }

    const preparePaymentData: PreparePaymentRequest = {
      cartId: cart.id,
      locale: 'aud',
      gateway: 'invoice'
    };
    preparePaymentMutate(preparePaymentData);
  };

  return (
    <>
      {cart && (
        <UiDrawer placement={'right'} size={'xl'} {...props}>
          <UiDrawerOverlay />
          <UiDrawerContent bgColor={'gray.100'}>
            <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
            <UiDrawerBody px={0} pb={16} alignItems={'stretch'}>
              <UiStack
                px={{ base: 4, lg: 8 }}
                py={8}
                flexGrow={1}
                spacing={6}
              >
                <UiText variant={'h6'}>Pay by invoice</UiText>
                <BaseMessageBarInfo
                  borderRadius={uiStyles.borderRadius}
                  bgColor={'#fff'}
                >
                We will send you an email with payment details.
                </BaseMessageBarInfo>
                <BaseDividerHorizontal height={2} />
                <Cart />
              </UiStack>
              <UiHStack px={{ base: 4, lg: 8 }} justifyContent={'flex-end'}>
                <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={onSubmit} isDisabled={cart.cartItems.length < 1} isLoading={isPreparePaymentLoading}>
                Confirm
                </UiButton>
              </UiHStack>
            </UiDrawerBody>
          </UiDrawerContent>
        </UiDrawer>
      )}
    </>
  );
};

export default PaymentByInvoiceDrawer;
