import { type FC } from 'react';
import {
  UiSpinner
} from '@/lib/ui';

export interface SpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const Spinner: FC<SpinnerProps> = ({ size }) => {
  return (
    <UiSpinner
      thickness='4px'
      speed='0.8s'
      emptyColor='primary.50'
      color='primary.300'
      size={size ?? 'xl'}
    />
  );
};

export default Spinner;
