import { type FC } from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
// import './index.css';
// import { routes } from './pages';
import { accountRoutes } from '@/account/pages';
import { registrationRoutes } from '@/registration/pages';
import { abstractRoutes } from '@/abstract/pages';
import { productWebsiteRoutes } from '@/productWebsite/pages';
import { mobileAppRoutes } from '@/mobileApp/pages';
import { presentationRoutes } from '@/presentation/pages';
import { eventWebsiteRoutes } from '@/eventWebsite/pages';

export const router = createBrowserRouter([
  {
    path: '/',
    children: Object.values(accountRoutes)
  },
  {
    path: '/registration/:tenantCode/',
    children: Object.values(registrationRoutes)
  },
  {
    path: '/',
    children: Object.values(abstractRoutes)
  },
  {
    path: '/',
    children: Object.values(productWebsiteRoutes)
  },
  {
    path: '/',
    children: Object.values(mobileAppRoutes)
  },
  {
    path: '/',
    children: Object.values(presentationRoutes)
  },
  {
    path: '/',
    children: Object.values(eventWebsiteRoutes)
  }
]);

export const Router: FC = () => (
  <RouterProvider router={router} />
);
