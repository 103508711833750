import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconGauge, UiImage } from '@/lib/ui';

export interface AccountCardProps {
  isSelected?: boolean
  navLink?: string
}

const AccountCard: FC<AccountCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      // icon={<UiImage src={'/image/app/arinex-one-colour.png'} height={'32px'} borderRadius={8} />}
      icon={(<UiIconGauge size={'3xl'} color={'gray.800'} />)}
      name={'Global dashboard'}
      description={'Your end to end event solutions'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default AccountCard;
