import { type ElementType, type FC } from 'react';
import {
  UiHStack,
  UiStack,
  UiText,
  type UiStackProps,
  uiStyles,
  UiLink,
  UiIconArrowSquareOut
} from '@/lib/ui';

export interface InforListProps extends UiStackProps {
  Icon: ElementType
  label: string
  description: string
  url?: string
  children?: React.ReactNode
}

const InfoField: FC<InforListProps> = ({
  Icon,
  label,
  description,
  url = undefined,
  children = null,
  ...props
}) => {
  // const styles = url ? uiStyles.hover : {};

  return (
    <UiHStack
      alignItems={'center'}
      spacing={4}
      px={8}
      // p={4}
      {...props}
    >
      <Icon size={'3xl'} color={'text.primary'} />
      <UiHStack spacing={4} flexGrow={1} justifyContent={'space-between'}>
        <UiStack spacing={0}>
          <UiText variant={'body1'} color={'text.secondary'}>{label}</UiText>
          <UiText variant={'title'} fontWeight={'bold'}>{description}</UiText>
        </UiStack>
        <UiHStack>
          {children ?? (url &&
            <UiLink href={url} target={'_blank'} {...uiStyles.hover}>
              <UiIconArrowSquareOut size={'2xl'} color={'primary.500'} />
            </UiLink>
          )}
        </UiHStack>
      </UiHStack>
    </UiHStack>
  );
};

export default InfoField;
