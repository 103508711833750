import { type FC, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack, UiHStack, UiButton } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { registration } from '@/api';
import {
  IsRequiredType,
  type DiscountCode,
  type DiscountCodeSaveRequest
} from '@/api/registration';
import dayjs from 'dayjs';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseFormSelectField from '@/base/Form/SelectField';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface CodeFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  discountCode?: DiscountCode
}

interface FormData {
  name: string
  description: string
  discountCode: string
  startDate: string
  endDate: string
  idPhotocopy: IsRequiredType
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 charactors.'),
  discountCode: Yup.string().required('Discount Code is required.'),
  startDate: Yup.date().required('Start Date is required.'),
  endDate: Yup.date().required('End Date is required.'),
  idPhotocopy: Yup.string().required('This field is required.')
});

const CodeForm: FC<CodeFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  discountCode
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const [discountCodeValue, setDiscountCodeValue] = useState<string>('');
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { mutate, isLoading } = useMutation<{}, Error, DiscountCodeSaveRequest>(
    {
      mutationFn: async (data: DiscountCodeSaveRequest) => {
        return await registration.saveDiscountCode(createTenantAdminApiRequest)(data);
      },
      onSuccess: () => {
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId }] });
        void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
        onClose();
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the host.']);
      }
    }
  );

  const submitForm = async (values: FormData) => {
    mutate({
      id: discountCode?.id,
      eventId,
      name: values.name,
      description: values.description,
      discountCode: values.discountCode,
      startDate: values.startDate,
      endDate: values.endDate,
      idPhotocopy: values.idPhotocopy,
      active: true
    });
  };

  const generateCode = () => {
    return [...Array(8)]
      .map(() => Math.random().toString(36)[2])
      .join('');
  };

  return (
    <Formik
      initialValues={
        discountCode
          ? {
            ...discountCode,
            startDate: dayjs(discountCode.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(discountCode.endDate).format('YYYY-MM-DD')
          }
          : {
            name: '',
            description: '',
            discountCode: discountCodeValue,
            startDate: '',
            endDate: '',
            idPhotocopy: IsRequiredType.NOT_REQUIRED
          }
      }
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >{({ setFieldValue, values }) => {
        const descriptionCharacterCount = values.description ? 100 - values.description.length : 100;

        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={discountCode ? 'Edit Discount' : 'Add Discount'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField name="name" label="Name" layout="stack" />
              <BaseFormTextareaField
                name="description"
                label="Description"
                layout="stack"
                maxLength={100}
                helperText={`100 character limit (${descriptionCharacterCount} characters remaining)`}
              />
              <BaseFormInputField
                name="discountCode"
                label="Discount Code"
                layout="stack"
              />
              <UiHStack alignItems="flex-start">
                <UiButton px={6}
                  py={4}
                  size={'md'}
                  colorScheme={'primary'}
                  onClick={() => {
                    const code = generateCode();
                    void setFieldValue('discountCode', code);
                  }}>
                  Generate
                </UiButton>
              </UiHStack>
              <UiHStack alignItems="flex-start">
                <BaseFormInputField
                  name="startDate"
                  label="Start date"
                  layout="stack"
                  type="date"
                />
                <BaseFormInputField
                  name="endDate"
                  label="End date"
                  layout="stack"
                  type="date"
                />
              </UiHStack>
              <BaseFormSelectField
                name={'idPhotocopy'}
                label={'ID Photocopy'}
                layout="stack"
                options={[
                  { value: IsRequiredType.NOT_REQUIRED, label: 'Not required' },
                  { value: IsRequiredType.REQUIRED, label: 'Required' },
                  { value: IsRequiredType.OPTIONAL, label: 'Optional' }
                ]}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default CodeForm;
