import { useMemo, type FC } from 'react';
import {
  UiStack,
  UiText,
  UiHStack,
  uiStyles,
  UiSimpleGrid,
  UiBox,
  UiButton, UiIconCaretRight, UiIconCalculator
} from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { type account } from '@/api';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import { calculateFirstYearPrice, getAddonsEnabled, getProductsEnabled } from '@/account/subscription/utils';
import { subscriptionPackageLabels } from '@/api/constant/subscription';
import ReviewDrawer from '@/account/subscription/PlanManagement/ReviewDrawer';

export interface PriceSummaryProps {
  subscription: account.Subscription
}

const PlanSummary: FC<PriceSummaryProps> = ({
  subscription
}) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <UiStack
        position={'fixed'}
        bottom={0}
        left={0}
        // height={'120px'}
        bgColor={'#fff'}
        zIndex={99}
        alignItems={'stretch'}
        width={'100%'}
        borderTopColor={'gray.300'}
        borderTopWidth={'1px'}
        borderTopStyle={'solid'}
      >
        <UiSimpleGrid columns={{ base: 2, lg: 4 }} gap={8} p={8} py={6}>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Package
            </UiText>
            <UiText variant={'title'}>
              {subscriptionPackageLabels[subscription.plan.package]}
              {/* <PriceDisplay price={subscriptionPrice.package}/> (12 months) */}
            </UiText>
          </UiStack>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Add-ons
            </UiText>
            <UiText variant={'title'}>
              {getAddonsEnabled(subscription).length ?? 0}
              {/* <PriceDisplay price={subscriptionPrice.addon} /> (12 months) */}
            </UiText>
          </UiStack>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Total price
            </UiText>
            <UiHStack
              justifyContent={'flex-start'}
              alignItems={'center'}
              onClick={onToggle}
              {...uiStyles.hover}
            >
              <UiIconCalculator color={'primary.500'} size={'2xl'} weight={'bold'} />
              <UiText
                size={'md'}
                color={'primary.500'}
                variant={'title'}
              >
                Calculate
              </UiText>
            </UiHStack>
          </UiStack>
          <UiStack spacing={1} alignItems={'flex-end'} justifyContent={'flex-end'}>
            <UiStack justifyContent={'flex-start'} alignItems={'flex-start'}>
              <UiButton size={'md'} colorScheme={'primary'} py={2} onClick={onToggle}>
                Save this plan
              </UiButton>
            </UiStack>
          </UiStack>
        </UiSimpleGrid>
      </UiStack>
      <ReviewDrawer
        subscription={subscription}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default PlanSummary;
