import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconFileArrowUp } from '@/lib/ui';

export interface AbstractCardProps {
  isSelected?: boolean
  navLink?: string
  showDescription?: boolean
}

const AbstractCard: FC<AbstractCardProps> = ({
  isSelected = false,
  navLink = '',
  showDescription = true,
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/abstract.jpeg'}
      icon={(<UiIconFileArrowUp size={'3xl'} color={'gray.800'}/>)}
      name={'Abstract management'}
      description={showDescription ? 'Abstract submission & review' : undefined}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default AbstractCard;
