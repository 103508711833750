import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import PersonalInfoForm from './PersonalInfoForm';

const PersonalInfo: FC = () => {
  return (
    <RegisterLayout>
      {/* <BaseTransitionSlideLeft> */}
      <PersonalInfoForm />
      {/* </BaseTransitionSlideLeft> */}
    </RegisterLayout>
  );
};

export default PersonalInfo;
