import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconScreencast } from '@/lib/ui';

export interface LiveStreamingCardProps {
  isSelected?: boolean
  navLink?: string
}

const LiveStreamingCard: FC<LiveStreamingCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      icon={(<UiIconScreencast size={'3xl'} color={'gray.800'} />)}
      name={'Live streaming'}
      description={'Watch and interact with live events online.'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default LiveStreamingCard;
