import { type FC, useMemo } from 'react';
import { IconButton } from '@chakra-ui/react';
import {
  UiStack,
  UiText,
  UiIconCurrencyCircleDollar,
  uiStyles,
  UiHStack,
  UiGrid,
  UiIconPlusCircle,
  UiIconMinusCircle,
  UiIconXCircle
} from '@/lib/ui';
import { locale } from '@/lib/util';
import { type CartItem, type CartItemDeleteRequest, type CartItemUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import Spinner from '@/base/Loading/Spinner';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const { createFormatPrice } = locale;

export interface ItemProps {
  itemId: number
  name: string
  price: number
  quantity: number
  cartId: number
  visitorId: number
}
// NOTE: hard code
const priceLocale = 'en-AU';
const priceCurrency = 'AUD';

const Item: FC<ItemProps> = ({
  itemId,
  name,
  price,
  quantity,
  cartId,
  visitorId
}) => {
  const queryClient = useQueryClient();

  const { createTenantApiRequest } = useTenantApi();
  const { eventId } = useRegisterRoute();
  const { data: eventSetting } = useEventSettingsQuery(eventId);

  const displayPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency })((price ?? 0) * 100),
    [price]
  );

  const { mutate: updateMutate, isLoading: updateMutateIsLoading } = useMutation<ApiResponse<CartItem>, Error, CartItemUpdateRequest>({
    mutationFn: async (data: CartItemUpdateRequest) => {
      return await registration.updateCartItem(createTenantApiRequest)(data);
    },
    onSuccess: (result) => {
      void queryClient.invalidateQueries({ queryKey: [registration.cartQueryKey, { visitorId }] });
    },
    onError: () => {
      // NOTE: handle error
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate: deleteMutate, isLoading: deleteMutateIsLoading } = useMutation<void, Error, CartItemDeleteRequest>({
    mutationFn: async (request: CartItemDeleteRequest) => {
      await registration.deleteCartItem(createTenantApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.cartQueryKey, { visitorId }] });
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const updateQuantity = (q: number) => {
    updateMutate({ id: itemId, quantity: q, cartId });
  };

  const deleteCartItem = () => {
    deleteMutate({ id: itemId });
  };

  return (
    <UiStack
      p={8}
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
    >
      <UiGrid
        templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
        gap={8}
        alignItems={'flex-end'}
      >
        <UiStack>
          <UiText variant={'title'}>{name}</UiText>
          <UiHStack justifyContent={'flex-start'}>
            <UiIconCurrencyCircleDollar size={'2xl'} color={'text.primary'} />
            <UiText>
              Price
            </UiText>
            <UiText>
              {displayPrice}
            </UiText>
          </UiHStack>
          <UiHStack
            {...uiStyles.hover}
            pt={2}
            onClick={deleteCartItem}
          >
            {deleteMutateIsLoading ? <Spinner size="sm" /> : <UiIconXCircle color={'red.500'} size={'2xl'} />}
            <UiText color={'red.500'}>Remove</UiText>
          </UiHStack>
        </UiStack>
        <UiStack flexGrow={1}>
          <UiHStack
            spacing={4}
            alignItems={'center'}
            // justifyContent={{base: 'flex-end', lg: 'flex-end'}}
            justifyContent={'flex-end'}
          >
            {
              eventSetting?.groupRegistration.isEnabled && (
                <UiStack
                  {...uiStyles.hover}
                >
                  <IconButton
                    aria-label={'Remove'}
                    icon={<UiIconMinusCircle color={'primary.500'} size={'4xl'} weight={'duotone'} />}
                    onClick={() => updateQuantity(quantity - 1) } />
                </UiStack>
              )
            }
            {
              updateMutateIsLoading
                ? <Spinner size="md" />
                : <UiText variant={'title'}>{quantity}</UiText>
            }
            {
              eventSetting?.groupRegistration.isEnabled && (
                <UiStack
                  {...uiStyles.hover}
                >
                  <IconButton
                    aria-label={'Add'}
                    icon={<UiIconPlusCircle color={'primary.500'} size={'4xl'} weight={'duotone'} />}
                    onClick={() => updateQuantity(quantity + 1)} />
                </UiStack>
              )
            }
          </UiHStack>
        </UiStack>
      </UiGrid>
    </UiStack>
  );
};

export default Item;
