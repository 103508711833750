import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconTicket } from '@/lib/ui';

export interface RegistrationCardProps {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const RegistrationCard: FC<RegistrationCardProps> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconTicket size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      // iconUrl={'/image/app/registration.jpeg'}
      name={'Registration management'}
      description={'Customise experience, sell tickets'}
      isSelected={isSelected}
      // bgColor={isEnabled ? '#fff' : 'transparent'}
    />
  );
};
export default RegistrationCard;
