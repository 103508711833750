import { type FC } from 'react';
import { Form } from 'formik';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps
} from '@/lib/ui';
import List, { type AppName } from './List';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface DrawerProps {
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
  currentAppName?: AppName;
}

const ListDrawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  currentAppName = undefined,
}) => {
  const {tenant} = useTenantRoute();

  return (
    <UiDrawer
      placement={'left'}
      size={'md'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={0} bgColor={'gray.100'}>
          <UiStack alignItems={'stretch'} p={8}>
            <List currentApp={currentAppName} tenantCode={tenant?.code ?? ''}/>
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default ListDrawer;
