/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { InfoFormType } from '@/api/registration';
import FormList from './FormList';

const PersonalInfoForm: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'personalInformation', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set if there are fields in the personal info form.
  useEffect(
    () => {
      return () => {
        if (eventSettings &&
          eventSettings?.personalInformation.totalFields > 0 &&
          !eventSettings.personalInformation.isSet
        ) {
          mutate({ eventId, key: 'personalInformation', bool: true });
        }
      };
    },
    [
      eventSettings?.personalInformation.totalFields,
      eventSettings?.personalInformation.isSet
    ]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'personalInfo'}
          />
        )}
        message={'Group your input fields.'}
      >
        <UiStack
          // pt={4}
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <FormList formType={InfoFormType.PERSONAL} />
        </UiStack>
        <UiHStack
          p={8}
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <UiText px={4} color={'text.secondary'}>
            Please make sure you've designed the form to collect the attendee's personal information.
          </UiText>
          <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
            Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default PersonalInfoForm;
