import { type SetStateAction, useMemo } from 'react';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  type ColumnDef,
  type PaginationState
} from '@tanstack/react-table';
import {
  UiButtonIcon,
  UiHStack,
  UiTable,
  UiTableContainer,
  UiTbody,
  UiTd,
  UiText,
  UiTh,
  UiThead,
  UiTr,
  uiStyles,
  UiStack
} from '@/lib/ui';
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from '@phosphor-icons/react';

export interface queryParams {
  page?: number
}

export interface DataTableProps<Data extends object> {
  data: Data[]
  columns: Array<ColumnDef<Data, any>>
  pagination: PaginationState
  setPagination: React.Dispatch<SetStateAction<PaginationState>>
  total?: number
}

export function DataTable<Data extends object>({
  data,
  columns,
  pagination,
  setPagination,
  total
}: DataTableProps<Data>) {
  const pageCount = useMemo(() =>
    Math.floor(((total ?? 1) - 1) / pagination.pageSize)
  , [total, pagination.pageSize]);
  // const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      // sorting,
      pagination
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    // onSortingChange: setSorting,
    manualPagination: true
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    // debugTable: true
  });

  return (
    <UiTableContainer>
      <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
        <UiThead>
          {table.getHeaderGroups().map((headerGroup) => (
            <UiTr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <UiTh
                  key={header.id}
                  p={6}
                  // onClick={header.column.getToggleSortingHandler()}
                >
                  <UiText>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </UiText>
                  {/* {header.column.getIsSorted() ? (
                    header.column.getIsSorted() === 'desc' ? (
                      <TriangleDownIcon aria-label="sorted descending" mx={2} />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" mx={2} />
                    )
                  ) : null} */}
                </UiTh>
              ))}
            </UiTr>
          ))}
        </UiThead>
        <UiTbody>
          {table.getRowModel().rows.map((row) => (
            <UiTr key={row.id}>
              {row.getVisibleCells().map((cell) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <UiTd key={cell.id} isNumeric={meta?.isNumeric}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </UiTd>
                );
              })}
            </UiTr>
          ))}
        </UiTbody>
      </UiTable>
      <UiStack height={4} />
      <UiHStack justifyContent="flex-end" p={4} px={8} bgColor={'#fff'} borderRadius={uiStyles.borderRadius} spacing={4}>
        <UiButtonIcon
          icon={<CaretDoubleLeft size={24} />}
          isDisabled={!table.getCanPreviousPage()}
          onClick={() => table.setPageIndex(0)}
          aria-label={'go first'}
        />
        <UiButtonIcon
          icon={<CaretLeft size={24} />}
          isDisabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
          aria-label={'go first'}
        />
        <UiButtonIcon
          icon={<CaretRight size={24} />}
          isDisabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
          aria-label={'go first'}
        />
        <UiButtonIcon
          icon={<CaretDoubleRight size={24} />}
          isDisabled={!table.getCanNextPage()}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          aria-label={'go first'}
        />
        <UiHStack>
          <UiText fontWeight={500}>Page</UiText>
          <NumberInput
            size='md'
            maxW={16}
            min={1}
            max={pageCount}
            onChange={value => {
              const page = Number(value) - 1;
              table.setPageIndex(page);
            }}
            value={table.getState().pagination.pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </UiHStack>
        <UiText fontWeight={500}> of {pageCount}</UiText>
      </UiHStack>
    </UiTableContainer>
  );
}
