import { type FC, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { type Options } from 'react-select';
import {
  UiButton,
  UiDivider,
  UiHStack,
  type UiHStackProps,
  UiIconPaperPlaneTilt,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
// import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseSelectField from '@/base/Form/SelectField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import BaseButtonRouterLink from '@/base/Button/RouterLink';
import BaseContentHubCard from '@/base/App/ContentHubCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseSpeakerToolsCard from '@/base/App/SpeakerToolsCard';
import BaseIntegrationCard from '@/base/App/IntegrationCard';

export interface PermissionFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  name: string
  email: string
  teams: string[]
  status: string
}

interface Errors {
  name?: string
  email?: string
  teams?: string
  status?: string
}

const statuses = ['active', 'inactive'];

// const teamOptions: Option[] = [
//   { value: 'admin', label: 'Admin' },
//   // { value: 'Medical', label: 'Medical', isDisabled: true },
//   { value: 'regoManagement', label: 'Rego management' },
//   { value: 'finance', label: 'Finance' }
// ];

const initFormData = {
  name: '',
  email: '',
  teams: [],
  status: ''
};

const PermissionForm: FC<PermissionFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  return (
    <Formik
      initialValues={formData}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData): Errors => {
        const errors: Errors = {};
        // if (!values.name) {
        //   errors.name = 'Required';
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.name = 'Invalid email address';
        // }
        // return errors;
        return {
          // name: 'adfasdf',
        };
      }}
      onSubmit={async (
        values: FormData
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        alert(JSON.stringify(values, null, 2));
        // setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Invite admin user'}
        size={'lg'}
        // isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <UiStack pb={8} spacing={4}>
            <UiStack spacing={4}>
              <UiStack bgColor={'blackAlpha.50'} p={4} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
                <BaseEventWebsiteCard isSelected={true} />
              </UiStack>
              <UiStack bgColor={'blackAlpha.50'} p={4} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
                <BaseSpeakerToolsCard />
              </UiStack>
              <UiStack bgColor={'blackAlpha.50'} p={4} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
                <BaseIntegrationCard />
              </UiStack>
              <UiStack bgColor={'blackAlpha.50'} p={4} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
                <BaseContentHubCard />
              </UiStack>
              <UiStack bgColor={'blackAlpha.50'} p={4} borderRadius={uiStyles.borderRadius} {...uiStyles.hover}>
                <BaseEventAppCard />
              </UiStack>
            </UiStack>
            <UiStack>
              <BaseButtonRouterLink
                to={generatePageUrl('AccountSubscriptionPlan', { tenantCode: 'dev' })}
                description={'You will be redirected to modules page'}
              >
                Enable other modules
              </BaseButtonRouterLink>
            </UiStack>
          </UiStack>
          <BaseSelectField
            name={'permission'}
            label={'Permission'}
            options={[
              { value: 'edit', label: 'Edit' },
              { value: 'view', label: 'View' }
            ]}
          />
          {/* <UiStack>
            <UiMenu>
              <UiMenuButton as={UiButton}>Select permission</UiMenuButton>
              <UiMenuList>
                <UiMenuItem>Edit</UiMenuItem>
                <UiMenuItem>View</UiMenuItem>
              </UiMenuList>
            </UiMenu>
          </UiStack> */}
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default PermissionForm;
