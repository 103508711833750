import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  // UiBox,
  // UiHStack,
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
  // UiGrid,
} from '@/lib/ui';
import TicketForm from './TicketForm';
import { type TicketType } from '@/api/constant';

export interface NewTicketProps extends UiHStackProps {
  eventId: string
  ticketType: TicketType
}

const NewTicket: FC<NewTicketProps> = ({ eventId, ticketType }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        // bgColor={'gray.50'}
        borderRadius={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        // flexGrow={1}
        onClick={onToggle}
        {...uiStyles.hover}
        // width={'64px'}
        // flexGrow={}
        p={0}
      >
        <UiIconPlusCircle
          size={56}
          color={'primary.600'}
          // width={64}
          weight={'light'}
        />
        <UiText variant={'title'} color={'primary.500'}>Add</UiText>
      </UiHStack>
      <TicketForm
        eventId={eventId}
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        ticketType={ticketType}
      />
    </>
  );
};

export default NewTicket;
