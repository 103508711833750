import { useQuery } from '@tanstack/react-query';
import { type Event, eventQueryKey } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useEventQuery(eventId: string) {
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();
  return useQuery<Event, Error>({
    queryKey: [eventQueryKey],
    queryFn: async () => {
      const result = await registration.loadEvent(createTenantApiRequest)({ eventId });
      return result.items?.[0];
    },
    enabled: !isApiPreparing
  });
}
