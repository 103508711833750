import { getAppConfig } from '@/app/config';

const appConfigApiHost = getAppConfig().api.globalHost;

export interface RequestEndpoint {
  host?: string
  path: string
  query?: Record<string, string>
}

export function getRequestUrl(requestEndpoint: RequestEndpoint): string {
  let _queryString = '';
  if (requestEndpoint.query) {
    _queryString = '?' + (new URLSearchParams(requestEndpoint.query)).toString();
  }
  const _host = requestEndpoint.host ?? appConfigApiHost;
  return `${_host}${requestEndpoint.path}${_queryString}`;
}

export interface ApiRequest {
  // url: string;
  endpoint: RequestEndpoint
  authToken?: string
  payload?: {}
  headers?: HeadersInit
  method?: string
  // transformData?: <Data>(data: object) => Data|Error;
}

const defaultFetchOptions: RequestInit = {
  method: 'POST',
  // mode: 'cors',
  // credentials: 'include',
  headers: {
    'Content-Type': 'application/json'
  }
  // body: ''
};

export interface ResponseError {
  message: string
  code?: number
}

export async function callApi<ApiData>(request: ApiRequest): Promise<ApiData> {
  // Merge all the custom headers.
  const options = {
    ...defaultFetchOptions,
    ...request.method ? { method: request.method } : {},
    header: {
      ...defaultFetchOptions.headers,
      ...request.headers ?? {},
      ...request.authToken ? { 'access-token': request.authToken } : {}
    },
    ...request.payload ? { body: JSON.stringify(request.payload) } : {}
  };
  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 201) {
    // Recieved the successful reponse from the backend.
    const jsonData = await fetchResponse.json();
    return (jsonData as ApiData);
  } else {
    if (fetchResponse.status === 401) {
      throw new Error('Access denied.');
    } else {
      throw new Error('Failed API call.');
    }
  }
}
