import { type FC, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiSpinner, UiStack, UiText, UiVStack, uiStyles } from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import RegistrationDetails from './RegistrationDetails';
import { registration } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { type Order } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { AbsoluteCenter } from '@chakra-ui/react';
import { useTenantApi } from '@/account/hook/useTenantApi';

const PAID = 'Paid';

const PaymentResult: FC = () => {
  const [searchParams] = useSearchParams();
  const orderHash = searchParams.get('orderHash');
  const [error, setError] = useState(false);
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data, isLoading, remove, refetch } = useQuery<ApiResponseSingle<Order>, Error>({
    queryKey: [registration.orderQueryKey, { orderHash }],
    queryFn: async () => {
      return await registration.loadOrder(createTenantApiRequest)(orderHash!);
    },
    enabled: !!orderHash && !isApiPreparing
  });

  useEffect(() => {
    if (error) {
      return;
    }

    const interval = setInterval(() => {
      if (Array.isArray(data?.errors) && data.errors.length > 0) {
        setError(true);
        remove();
        clearInterval(interval);
      } else if (data?.item && data.item.status !== PAID) {
        void refetch();
      } else if (data?.item && data.item.status === PAID) {
        remove();
        clearInterval(interval);
      }
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      if (data?.item?.status !== PAID || (Array.isArray(data?.errors) && data.errors.length > 0)) {
        setError(true);
      }
    }, 10000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [data, refetch, remove]);

  if ((isLoading || !data || data?.item?.status !== PAID) && !error) {
    return (
      <RegisterLayout>
        <AbsoluteCenter>
          <UiVStack spacing={4}>
            <UiSpinner />
            <UiText variant={'h6'}>{"We're completing your order, please wait"}</UiText>
          </UiVStack>
        </AbsoluteCenter>
      </RegisterLayout>
    );
  }

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={4}>
          <UiStack>
            {error && <UiText variant={'h6'}>Registration failed</UiText>}
            {data?.item?.status === PAID && (
              <UiText variant={'h6'}>Registration successful</UiText>
            )}
          </UiStack>
          {error && <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'}>{'Something went wrong, please try again later'}</BaseMessageBarInfo>}
          {data?.item?.status === PAID && (
            <>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'green.100'}>
                { "We've sent the receipt with your registration details to your email. It might take several minutes to arrive. Please also check your spam." }
              </BaseMessageBarInfo>
              <RegistrationDetails order={data.item} /></>
          )}
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={32} />
    </RegisterLayout>
  );
};

export default PaymentResult;
