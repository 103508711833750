import { type FC } from 'react';
import { Card, type CardProps } from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui';

export interface UiCardProps extends CardProps {
  enableShadow?: boolean
  enableBorderRadius?: boolean
}

export const UiCard: FC<UiCardProps> = ({
  enableShadow = true,
  enableBorderRadius = true,
  ...props
}) => {
  return (
    <Card
      shadow={enableShadow ? 'base' : 'none'}
      borderRadius={enableBorderRadius ? uiStyles.borderRadius : 'none'}
      {...props}
    />
  );
};
