import { type FC } from 'react';
import {
  MinusCircle
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconMinusCircleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconMinusCircle: FC<UiIconMinusCircleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={MinusCircle}
      {...props}
    />
  );
};
