import { type FC, type PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { saveItem, loadItem } from '@/api/local';

export interface ProviderAdminAuthProps extends PropsWithChildren {
  // config: AppAdminAuth
}

function getCurrentAdminAuthKey(): string {
  return 'ADMIN_AUTH_CURRENT';
}

export interface AdminAuth extends account.TenantAdminUserSession {
  // user: account.TenantAdminUserSession;
  // authToken: string;
}

const defaultAdminAuth: AdminAuth = account.tenantAdminUserDefaultData;

interface AdminAuthContextValue {
  adminAuth: AdminAuth
  setAdminAuth: (adminAuth: AdminAuth) => void
}

const AdminAuthContext = createContext<AdminAuthContextValue>({
  adminAuth: defaultAdminAuth,
  setAdminAuth: (adminAuth: AdminAuth) => {}
});

export const ProviderAdminAuth: FC<ProviderAdminAuthProps> = ({
  ...props
}) => {
  const [adminAuth, setAdminAuth] = useState(() => {
    // Is there an existing admin auth locally?
    const localItem = loadItem<AdminAuth>({
      key: getCurrentAdminAuthKey()
    });
    if (localItem.item?.authToken && localItem.item?.user?.id) {
      return localItem.item;
    }

    // No local admin auth is found. Return the default 'empty' one.
    return defaultAdminAuth;
  });

  return (
    <AdminAuthContext.Provider value={{
      adminAuth,
      setAdminAuth
    }}
    {...props} />
  );
};

export function useAdminAuth() {
  const { adminAuth, setAdminAuth } = useContext(AdminAuthContext) as Required<AdminAuthContextValue>;

  const persistAdminAuth = (adminAuthData: AdminAuth) => {
    saveItem({
      key: getCurrentAdminAuthKey(),
      item: JSON.stringify(adminAuthData)
    });
    setAdminAuth(adminAuthData);
  };

  return {
    adminAuth,
    updateAdminAuth: setAdminAuth,
    persistAdminAuth
  };
}
