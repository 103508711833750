import { useCallback, type FC } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText, UiButton, UiTag, UiDrawer, UiDrawerOverlay, UiDrawerCloseButton, UiDrawerContent, UiDrawerBody, UiDrawerFooter
} from '@/lib/ui';
// import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import AttendeeCategoriesForm from '@/registration/buildEvent/AdditionalInfoForm/AttendeeCategoriesForm';
import { useDisclosure } from '@chakra-ui/react';
import { InfoFormType, type InfoForm, type InfoFormDeleteRequest } from '@/api/registration';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
// import { text } from '@/util';

export interface FormCardProps extends UiHStackProps {
  infoForm: InfoForm
}

const FormCard: FC<FormCardProps> = ({ infoForm }) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate: deleteMutate, isLoading: isDeleteMutationLoading } = useMutation<ApiResponseSingle<{}>, Error, InfoFormDeleteRequest>({
    mutationFn: async (params: InfoFormDeleteRequest) => {
      return await registration.deleteInfoForm(createTenantAdminApiRequest)(params);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.ADDITIONAL }] });
      // NOTE: handle error
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponseSingle<{}>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneInfoForm(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: () => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    deleteMutate({ id: infoForm.id });
  }, [infoForm.id, deleteMutate]);

  const onConfirm = useCallback(() => {
    mutateClone(infoForm.id);
  }, [infoForm, mutateClone]);

  return (
    <>
      <UiStack>
        <UiStack
          flexGrow={1}
          // p={8}
          py={6}
          borderRadius={uiStyles.borderRadius}
          bgColor={'#fff'}
          enableDivider={true}
          spacing={4}
        >
          <UiHStack
            spacing={8}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            onClick={() => {}}
            px={8}
          >
            <UiStack spacing={0}>
              <UiText>Fields</UiText>
              <UiText>{infoForm.config.totalFields}</UiText>
            </UiStack>
            <UiHStack spacing={8}>
              <UiButton
                p={0}
                variant={'ghost'}
                colorScheme={'red'}
                {...uiStyles.hover}
                isLoading={isDeleteMutationLoading}
                onClick={onDelete}
              >
              Delete form
              </UiButton>
              <UiButton
                px={0}
                variant={'ghost'}
                {...uiStyles.hover}
                colorScheme={'primary'}
                onClick={onToggleConfirmClone}
              >
              Clone
              </UiButton>
              <BaseRouterLink
                to={generatePageUrl('RegistrationBuildEventBuildAdditionalInfoForm', { tenantCode, eventId }, { id: infoForm.id })}
              >
                <UiButton
                  p={0}
                  variant={'ghost'}
                  colorScheme={'primary'}
                  {...uiStyles.hover}
                  onClick={onToggle}
                >
              Edit form
                </UiButton>
              </BaseRouterLink>
            </UiHStack>
          </UiHStack>
          <UiHStack spacing={2} justifyContent={'space-between'} alignItems={'flex-end'} px={8}>
            <UiStack>
              <UiText variant={'body1'}>Attendee categories</UiText>
              <UiHStack>
                {infoForm.attendeeCategories.map(attendeeCategory => (
                  <UiTag key={attendeeCategory.id} p={4} py={2}>{attendeeCategory.name}</UiTag>
                ))}
              </UiHStack>
            </UiStack>
            <UiButton
              p={0}
              variant={'ghost'}
              colorScheme={'primary'}
              {...uiStyles.hover}
              onClick={onToggle}
            >
            Edit
            </UiButton>
          </UiHStack>
        </UiStack>
        <AttendeeCategoriesForm
          onSaveSuccess={() => {}}
          isVisible={isOpen}
          onClose={onToggle}
          infoForm={infoForm}
        />
      </UiStack>
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={8}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
          Do you confirm to clone this additional information form?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
            No
              </UiButton>
              <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
            Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
    </>
  );
};

export default FormCard;
