import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const infoFormResponseQueryKey = 'registration.infoFormResponseList';

export interface InfoFormResponse {
  id: number
  response: Record<string, any>
  eventId: string
  ownerId: number
  ownerType: 'Visitor' | 'User'
  infoFormId: number
}

export enum InfoFormType {
  PERSONAL = 'personal',
  ADDITIONAL = 'additional'
};

export interface InfoFormResponseQueryRequest {
  visitorId: number
  type: InfoFormType
}

export function loadVisitorInfoFormResponse(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: InfoFormResponseQueryRequest
  ): Promise<ApiResponseSingle<InfoFormResponse>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_form_responses/' + params.visitorId.toString();
    request.endpoint.query = {
      type: params.type
    };
    return await properCallApi<ApiResponseSingle<InfoFormResponse>>(request);
  };
}
