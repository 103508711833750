import { type FC } from 'react';
import Layout from '@/productWebsite/component/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine,
  UiLink,
} from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import {generatePageUrl} from "@/app/pages";

const Landing: FC = () => {
  return (
    <Layout>
      <UiStack spacing={4}>
        <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
          This is a temporary index page. In the future, the links below should be embedded into the Interfly CSM website.
        </BaseMessageBarInfo>
        <UiLink
          href={generatePageUrl('AccountLoginLoginEmail')}
          target={'_blank'}
          {...uiStyles.hover}
        >
          <UiHStack>
            <UiText color={'primary.500'}>Admin login</UiText>
          </UiHStack>
        </UiLink>
      </UiStack>
    </Layout>
  );
};

export default Landing;
