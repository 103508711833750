import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { eventSettingsQueryKey, type EventSettings } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';

// We do not need to wrap every react query or mutations into hook. The reason why we do it here is because this query here will be used in a lot of components.
export function useEventSettingsQuery(eventId: string) {
  // Load event settings from the backend.
  const { createTenantApiRequest, createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  return useQuery<EventSettings, Error>({
    queryKey: [eventSettingsQueryKey, { eventId }],
    queryFn: async (): Promise<EventSettings> => {
      let result = await registration.loadEventSettings(createTenantApiRequest)({ eventId });
      if (result.items?.length === 0) {
        result = await registration.createEventSettings(createTenantAdminApiRequest)({ eventId });
      }
      return result.items?.[0]?.config;
    },
    enabled: !isApiPreparing
  });
}
