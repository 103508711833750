import { type ReactNode, type FC } from 'react';
import {
  type UiBoxProps,
  UiHStack,
  uiStyles,
  UiStack,
  UiText,
  UiScaleFade,
  UiFade,
  UiIconCheck,
  UiIconPlus,
  UiSwitch,
  UiTag,
  UiIconCheckCircle,
} from '@/lib/ui';

export interface AddonCardProps extends UiBoxProps {
  isEnabled: boolean
  children: ReactNode
  onToggle?: (isEnabled: boolean) => void
}

const AddonCard: FC<AddonCardProps> = ({
  isEnabled,
  children,
  onToggle = undefined
}) => {
  return (
    <UiStack
      bgColor={'#fff'}
      shadow={'none'}
      // p={{base: 4, lg: 8}}
      p={8}
      pb={6}
      spacing={4}
      minWidth={300}
      borderRadius={uiStyles.borderRadius}
      // onClick={() => onToggle ? onToggle(!isEnabled) : {}}
      borderWidth={'2px'}
      borderColor={isEnabled ? 'green.500' : 'whiteAlpha.800'}
      borderStyle={'solid'}
    >
      <UiStack
        transform={`scale(${isEnabled ? 0.98 : 1})`}
        transition={'all .2s ease-in-out'}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <UiStack flexGrow={1}>
          {children}
        </UiStack>
        {onToggle && (
          <UiHStack justifyContent={'flex-end'} alignItems={'center'}>
            {/*<UiFade in={isEnabled}>*/}
            {/*  <UiIconCheckCircle color={'green.500'} size={'4xl'}/>*/}
            {/*</UiFade>*/}
            <UiStack py={2}>
              <UiSwitch
                colorScheme={'green'}
                size={'lg'}
                isChecked={isEnabled}
                onChange={() => onToggle(!isEnabled)}
              />
            </UiStack>
          </UiHStack>
        )}
      </UiStack>
      {/*{onToggle && (*/}
      {/*  <UiHStack justifyContent={'flex-end'}>*/}
      {/*    {isEnabled ? (*/}
      {/*      <UiStack*/}
      {/*        p={2}*/}
      {/*        borderWidth={'2px'}*/}
      {/*        borderColor={'primary.500'}*/}
      {/*        borderStyle={'solid'}*/}
      {/*        borderRadius={'50%'}*/}
      {/*        {...uiStyles.hoverFlex({ bgColor: 'primary.100' })}*/}
      {/*      >*/}
      {/*        <UiIconCheck color={'primary.500'} weight={'bold'} size={'xl'} />*/}
      {/*      </UiStack>*/}
      {/*    ) : (*/}
      {/*      <UiStack*/}
      {/*        p={2}*/}
      {/*        borderWidth={'2px'}*/}
      {/*        borderColor={'gray.800'}*/}
      {/*        borderStyle={'solid'}*/}
      {/*        borderRadius={'50%'}*/}
      {/*        transform={'scale(1.15)'}*/}
      {/*        {...uiStyles.hoverFlex({ bgColor: 'gray.100' })}*/}
      {/*      >*/}
      {/*        <UiIconPlus color={'gray.800'} weight={'bold'} size={'xl'} />*/}
      {/*      </UiStack>*/}
      {/*    )}*/}
      {/*  </UiHStack>*/}
      {/*)}*/}
    </UiStack>
  );
};

export default AddonCard;
