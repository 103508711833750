import {
  SubscriptionPackage,
  SubscriptionPaymentPlan,
  SubscriptionProduct,
  SubscriptionUnitCostType,
  SubscriptionState
} from '@/api/constant/subscription';

import { type ApiRequest } from '@/api/tenantClient';

export const subscriptionQueryKey = 'account.subscription';

export interface SubscriptionProductBase {
  monthlyPrice: number
  yearlyPrice: number
}

// Modules
export interface SubscriptionRegistration extends SubscriptionProductBase {
  cprRate: number // Cost per registration
  cprType: SubscriptionUnitCostType
}
export interface SubscriptionAbstractManagement extends SubscriptionProductBase {
  cpsRate: number // Cost per submission
  cpsType: SubscriptionUnitCostType
}
export interface SubscriptionContentDeliveryPlatform extends SubscriptionProductBase {
}
export interface SubscriptionMobileEventApp extends SubscriptionProductBase {
}
export interface SubscriptionDigitalPoster extends SubscriptionProductBase {
  cppRate: number // // Cost per poster
  cppType: SubscriptionUnitCostType
}
export interface SubscriptionLiveStreaming extends SubscriptionProductBase {
}
export interface SubscriptionSponsorExhibitor extends SubscriptionProductBase {
}
export interface SubscriptionWebinar extends SubscriptionProductBase {
}
export interface SubscriptionDataInsight extends SubscriptionProductBase {
}
export interface SubscriptionServicePack extends SubscriptionProductBase {
  hours: number // Always monthly
}
export interface SubscriptionMultiLingualSupport extends SubscriptionProductBase {
}
export interface SubscriptionLiveTranslation extends SubscriptionProductBase {
}
export interface SubscriptionAICaptioning extends SubscriptionProductBase {
}
export interface SubscriptionAppWayfinder extends SubscriptionProductBase {
}
export interface SubscriptionContentHub extends SubscriptionProductBase {
}

/**
 * DO NOT change the keys here since the backend will use the same keys to locate the products.
 */
export interface SubscriptionProducts {
  [SubscriptionProduct.Registration]: SubscriptionRegistration
  [SubscriptionProduct.AbstractManagement]: SubscriptionAbstractManagement
  [SubscriptionProduct.EventWebsite]: SubscriptionContentDeliveryPlatform
  [SubscriptionProduct.MobileEventApp]: SubscriptionMobileEventApp
  [SubscriptionProduct.DigitalPoster]: SubscriptionDigitalPoster
  [SubscriptionProduct.LiveStreaming]: SubscriptionLiveStreaming
  [SubscriptionProduct.SponsorExhibitor]: SubscriptionSponsorExhibitor
  [SubscriptionProduct.Webinar]: SubscriptionWebinar
  [SubscriptionProduct.DataInsight]: SubscriptionDataInsight
  [SubscriptionProduct.MultiLingualSupport]: SubscriptionMultiLingualSupport
  [SubscriptionProduct.LiveTranslation]: SubscriptionLiveTranslation
  [SubscriptionProduct.AILiveCaptioning]: SubscriptionAICaptioning
  [SubscriptionProduct.AppWayfinder]: SubscriptionAppWayfinder
  [SubscriptionProduct.ContentHub]: SubscriptionContentHub
  [SubscriptionProduct.ServicePack1]: SubscriptionServicePack
  [SubscriptionProduct.ServicePack2]: SubscriptionServicePack
  [SubscriptionProduct.ServicePack3]: SubscriptionServicePack
}

export type SubscriptionServicePackName = SubscriptionProduct.ServicePack1 | SubscriptionProduct.ServicePack2 | SubscriptionProduct.ServicePack3;

export interface Subscription {
  products: SubscriptionProducts
  [SubscriptionPackage.Pack1]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  [SubscriptionPackage.Pack2]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  plan: {
    state: SubscriptionState
    from: number // timestamp
    to: number // timestamp
    currency: string
    package: SubscriptionPackage
    addon: {
      paymentPlan: SubscriptionPaymentPlan
      from: number // timestamp
      to: number // timestamp
      products: SubscriptionProduct[]
    }
  }
}

export const defaultSubscription: Subscription = {
  products: {
    registration: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cprRate: 3,
      cprType: SubscriptionUnitCostType.Fixed,
    },
    abstractManagement: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cpsRate: 6,
      cpsType: SubscriptionUnitCostType.Fixed,
    },
    eventWebsite: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    mobileEventApp: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    digitalPoster: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cppRate: 5,
      cppType: SubscriptionUnitCostType.Fixed,
    },
    liveStreaming: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    sponsorExhibitor: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    webinar: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    dataInsight: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    servicePack1: {
      monthlyPrice: 4000,
      yearlyPrice: 38000,
      hours: 20
    },
    servicePack2: {
      monthlyPrice: 7500,
      yearlyPrice: 70000,
      hours: 40
    },
    servicePack3: {
      monthlyPrice: 11000,
      yearlyPrice: 105000,
      hours: 60
    },
    multiLingualSupport: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    liveTranslation: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    aiLiveCaptioning: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    appWayfinder: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    contentHub: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    }
  },
  pack1: {
    yearlyPrice: 28355,
    products: [
      SubscriptionProduct.Registration,
      SubscriptionProduct.EventWebsite,
      SubscriptionProduct.Webinar,
      SubscriptionProduct.SponsorExhibitor,
      SubscriptionProduct.LiveStreaming,
      SubscriptionProduct.DataInsight
    ]
  },
  pack2: {
    yearlyPrice: 39500,
    products: [
      SubscriptionProduct.Registration,
      SubscriptionProduct.EventWebsite,
      SubscriptionProduct.Webinar,
      SubscriptionProduct.SponsorExhibitor,
      SubscriptionProduct.LiveStreaming,
      SubscriptionProduct.DataInsight,
      SubscriptionProduct.AbstractManagement,
      SubscriptionProduct.DigitalPoster,
      SubscriptionProduct.MobileEventApp
    ]
  },
  plan: {
    state: SubscriptionState.InReview,
    package: SubscriptionPackage.Pack2,
    from: 1705792147,
    to: 1716250147,
    currency: 'AUD',
    addon: {
      paymentPlan: SubscriptionPaymentPlan.Quarterly,
      from: 1705792147,
      to: 1716250147,
      products: []
    }
  }
};

export function getDefaultServicePack(): SubscriptionServicePackName {
  return SubscriptionProduct.ServicePack1;
}

export interface SubscriptionLoadRequest {}

export const loadSubscription = (createTenantAdminRequest: () => ApiRequest) => {
  const apiRequest = createTenantAdminRequest();
  return async (
    params: SubscriptionLoadRequest
  ): Promise<Subscription> => {
    apiRequest.endpoint.path = '/api/v1/subscriptions';
    apiRequest.payload = params;
    return defaultSubscription;
    // return await properCallApi<Subscription>(request);
    // return subscriptionDefaultData;
  };
};
