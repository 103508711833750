import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  // UiBox,
  // UiHStack,
  type UiHStackProps,
  UiIconPlusCircle,
  UiStack,
  uiStyles // UiGrid,
} from '@/lib/ui';
import EventForm from './EventForm';

export interface NewEventRowProps extends UiHStackProps {
}

const NewEventRow: FC<NewEventRowProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack
        // bgColor={'gray.50'}
        borderRadius={'100%'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        // flexGrow={1}
        onClick={onToggle}
        {...uiStyles.hover}
        width={'64px'}
        // flexGrow={}
        p={0}
      >
        <UiIconPlusCircle
          size={64}
          color={'primary.600'}
          // width={64}
          weight={'light'}
        />
      </UiStack>
      <EventForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewEventRow;
