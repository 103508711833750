import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconBrowser } from '@/lib/ui/Icon/UiIconBrowser';

export interface EventWebsiteCardProps {
  isSelected?: boolean
  navLink?: string
}

const EventWebsiteCard: FC<EventWebsiteCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/event-web.jpeg'}
      icon={(<UiIconBrowser size={'3xl'} color={'gray.800'} />)}
      name={'Event website'}
      description={'Build the website for your events'}
      isSelected={isSelected}
      navLink={navLink}
      flexGrow={1}
    />
  );
};
export default EventWebsiteCard;
