import { type FC, type ReactNode } from 'react';
import BaseRouterLink from '@/base/Router/Link';
import {
  UiHStack,
  UiIconCheck,
  UiImage,
  UiStack,
  type UiStackProps,
  uiStyles
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';

export interface AppCardProps extends UiStackProps {
  icon?: ReactNode
  name: string
  description?: string
  isSelected?: boolean
  navLink?: string
}

const AppCard: FC<AppCardProps> = ({
  icon,
  name,
  description = undefined,
  isSelected = false,
  navLink = undefined,
  ...props
}) => {
  return (
    // We need this UiStack to make sure the card will grow to its max width.
    <UiStack flexGrow={1} {...props}>
      <BaseRouterLink to={navLink ?? ''} style={{cursor: 'inherit'}}>
        <UiHStack spacing={2} borderRadius={uiStyles.borderRadius}>
          {!!icon && icon}
          <BaseTextList
            flexGrow={1}
          >
            <BaseTextListItem variant='body1' fontWeight={600}>{name}</BaseTextListItem>
            {description && (
              <BaseTextListItem variant='body2' color={'text.secondary'}>{description}</BaseTextListItem>
            )}
          </BaseTextList>
          {isSelected && (
            <UiIconCheck color={'green.500'} weight={'bold'}/>
          )}
          {/* <UiIconCaretRight size={'36px'} color={'primary.500'}/> */}
        </UiHStack>
      </BaseRouterLink>
    </UiStack>
  );
};

export default AppCard;
