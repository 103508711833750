import { useParams, type Params } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';

interface RouteParams extends Params {
  tenantCode: string
}

export function useTenantRoute() {
  const params = useParams<RouteParams>();
  const tenantCode = params.tenantCode ?? 'dev';
  const { data, isLoading } = useQuery<account.TenantLoadByCodeResponse, Error>(
    [account.tenantQueryKey],
    async () => {
      return await account.loadTenantByCode({ code: tenantCode });
    },
    {
      enabled: !!tenantCode,
      cacheTime: Infinity
    }
  );

  return {
    ...params,
    isLoading,
    tenant: data?.item
  };
}
