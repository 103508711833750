import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface SponsorExhibitorProps extends UiBoxProps {
  isSelected: boolean
  bgColor?: ModuleCardProps['bgColor']
}

const SponsorExhibitor: FC<SponsorExhibitorProps> = ({
  isSelected,
  bgColor = 'blackAlpha.50'
}) => {
  return (
    <ModuleCard
      isSelected={isSelected}
      bgColor={bgColor}
    >
      <BaseAppSponsorExhibitorCard />
    </ModuleCard>
  );
};

export default SponsorExhibitor;
