import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconHeadset } from '@/lib/ui';

export interface ServicePack1Props {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const ServicePack1Card: FC<ServicePack1Props> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconHeadset size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'Service pack 1'}
      isSelected={isSelected}
    />
  );
};
export default ServicePack1Card;
