import { type ReactNode, type FC } from 'react';
import {
  type UiBoxProps,
  UiHStack,
  uiStyles,
  UiStack,
  UiText,
  UiScaleFade,
  UiFade
} from '@/lib/ui';

export interface ModuleCardProps extends UiBoxProps {
  isSelected: boolean
  children: ReactNode
  bgColor?: UiBoxProps['bgColor']
}

const ModuleCard: FC<ModuleCardProps> = ({
  isSelected,
  // priceShown,
  children,
  bgColor = 'blackAlpha.50'
}) => {
  return (
    <UiStack
      bgColor={bgColor}
      shadow={'none'}
      p={6}
      py={4}
      spacing={8}
      minWidth={300}
      borderRadius={uiStyles.borderRadius}
      // transform={"scale(0.9)"}
    >
      <UiHStack
        // p={4}
        borderRadius={uiStyles.borderRadius}
        justifyContent={'space-between'}
      >
        {children}
      </UiHStack>
    </UiStack>
  );
};

export default ModuleCard;
