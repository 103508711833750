import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiStack } from '@/lib/ui';
import TicketList from './TicketList';
import { TicketType } from '@/api/constant';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

const MainTickets: FC = () => {
  const { toNextStep, toPreviousStep, isReviewing } = useRegisterSteps();

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1}>
          <UiStack pb={4}>
            {/* <UiText variant={'h6'}>Main event tickets</UiText> */}
          </UiStack>
          <TicketList ticketType={TicketType.Main} onEmpty={toNextStep} />
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={32} />
      <LayoutFooter justifyContent={'flex-end'}>
        <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
          {isReviewing ? 'Back' : 'Previous'}
        </UiButton>
        <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
          {isReviewing ? 'Done' : 'Next'}
        </UiButton>
      </LayoutFooter>
    </RegisterLayout>
  );
};

export default MainTickets;
