import {type FC, ReactNode} from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerProps,
} from '@/lib/ui';

export interface SmartWindowDrawerProps {
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
  children: ReactNode
}

const SmartWindowDrawer: FC<SmartWindowDrawerProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <UiDrawer
      placement={'right'}
      size={'md'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} bgColor={'gray.100'}>
          {children}
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default SmartWindowDrawer;
