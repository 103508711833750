import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles, UiIconCurrencyCircleDollar
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import TicketList from './TicketList';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { TicketType } from '@/api/constant';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';

const MainEventTickets: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'mainEventTicket', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'mainEventTickets'}
          />
        )}
        message={'List your event tickets.'}
      >
        <UiStack
          // pt={4}
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <UiStack alignItems={'flex-start'}>
            <UiHStack bgColor={'#fff'} py={4} px={8} borderRadius={uiStyles.borderRadius}>
              <UiIconCurrencyCircleDollar />
              <UiText>AUD</UiText>
              <UiText color={'text.secondary'}>(you can change the currency by editing the event)</UiText>
              {/* <UiButton variant={'ghost'} colorScheme={'primary'}>Change</UiButton> */}
            </UiHStack>
          </UiStack>
          <BaseDividerHorizontal height={4} />
          <TicketList ticketType={TicketType.Main} />
        </UiStack>
        <UiHStack justifyContent={'space-between'} p={8}>
          <UiStack flexGrow={1} />
          <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
              Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default MainEventTickets;
