import { useEffect, useState } from 'react';

export function useWindowScrollY(): number {
  const [scrollY, setScrollY] = useState(0);
  useEffect(
    () => {
      const onScroll = () => setScrollY(window.scrollY);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  );
  return scrollY;
}
