import { type FC } from 'react';
import Cart from './Cart';

const ShoppingCart: FC = () => {
  return (
    <Cart />
  );
};

export default ShoppingCart;
