import { type FC, useMemo, useCallback } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton,
  UiStack
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { saveInfoFormResponse, type InfoFormResponseSaveRequest } from '@/api/registration/info_form_response';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type InfoFormByAttendeeCategoryQuery, type InfoForm, InfoFormType, loadVisitorInfoFormResponse, type InfoFormResponse } from '@/api/registration';
import { useVisitor } from '@/app/ProviderVisitor';
import { registration } from '@/api';
import { Skeleton } from '@chakra-ui/react';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

export interface AdditionalInfoFormProps {}

const AdditionalInfoForm: FC<AdditionalInfoFormProps> = () => {
  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { toNextStep, toPreviousStep, isReviewing } = useRegisterSteps();
  const { visitorId, isLoadingVisitor } = useVisitor();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const additionalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [registration.infoFormResponseQueryKey, { visitorId, type: InfoFormType.ADDITIONAL }],
    queryFn: async () => {
      const response = await loadVisitorInfoFormResponse(createTenantApiRequest)({
        visitorId: visitorId!,
        type: InfoFormType.ADDITIONAL
      });
      return response.item;
    },
    enabled: !!visitorId && !isApiPreparing
  });

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>(
    [registration.infoFormByAttendeeCategoryQueryKey, { visitorId, formType: InfoFormType.ADDITIONAL }],
    async () => {
      const params: InfoFormByAttendeeCategoryQuery = { formType: InfoFormType.ADDITIONAL, visitorId: visitorId! };
      const result = await registration.loadInfoFormByAttendeeCategory(createTenantApiRequest)(params);
      return {
        ...result.item,
        config: JSON.parse(result.item.config) as InfoForm['config']
      };
    },
    {
      enabled: !isApiPreparing && !!visitorId
    }
  );

  const utils = useMemo(
    () => createUtils((infoFormByAttendeeCategoryQuery.data?.config?.fieldsMetadata ?? [])),
    [infoFormByAttendeeCategoryQuery.data?.config?.fieldsMetadata]);

  const { mutate, isLoading } = useMutation<{}, Error, InfoFormResponseSaveRequest>(
    {
      mutationFn: async (data: InfoFormResponseSaveRequest) => {
        return await saveInfoFormResponse(createTenantApiRequest)(data);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([registration.infoFormResponseQueryKey, { visitorId, type: InfoFormType.ADDITIONAL }]);
        toNextStep();
      }
      // onError: (error) => {}
    }
  );

  const submitForm = useCallback(async (values: FormData) => {
    mutate({
      eventId,
      formType: 'additional',
      response: values,
      ownerId: visitorId!,
      ownerType: 'Visitor',
      infoFormId: infoFormByAttendeeCategoryQuery?.data?.id
    });
  }, [eventId, infoFormByAttendeeCategoryQuery, mutate, visitorId]);

  if (infoFormByAttendeeCategoryQuery.isLoading || isLoadingVisitor || !infoFormByAttendeeCategoryQuery?.data?.id) {
    return <Skeleton height='80px' />;
  }

  return (
    <QueryContainer query={infoFormByAttendeeCategoryQuery}>
      {(infoForm: InfoForm) => (
        <QueryContainer query={additionalInfoQuery}>
          {(additionInfo) => (
            <Formik
              initialValues={utils.getFieldsValues(additionInfo.response)}
              validateOnChange={false}
              validateOnBlur={false}
              validate={(values: FormData) => {
                const errors: Errors = {};
                // This is an example to check 'required' fields. We might validator others in the future.
                for (const fieldId in values) {
                  const fieldMetadata = utils.getFieldMetadata(fieldId);
                  if (fieldMetadata) {
                    // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
                    if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
                      errors[fieldId] = 'The field is required';
                    }
                  }
                }
                return errors;
              }}
              onSubmit={async (
                values,
                { setSubmitting }: FormikHelpers<FormData>
              ) => {
                setSubmitting(true);
                await submitForm(values);
                setSubmitting(false);
              }}
            >
              <Form>
                <BaseTransitionSlideLeft>
                  <UiStack flexGrow={1}>
                    <FormGenerator
                      fieldsLayout={infoForm.config.fieldsLayout}
                      fieldsMetadata={infoForm.config.fieldsMetadata}
                    />
                  </UiStack>
                </BaseTransitionSlideLeft>
                <UiStack height={32} />
                <LayoutFooter justifyContent={'flex-end'}>
                  <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                    {isReviewing ? 'Back' : 'Previous'}
                  </UiButton>
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                    {isReviewing ? 'Done' : 'Next'}
                  </UiButton>
                </LayoutFooter>
              </Form>
            </Formik>
          )}
        </QueryContainer>

      )}
    </QueryContainer>
  );
};

export default React.memo(AdditionalInfoForm);
