import { useCallback, useMemo, type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import CategoryForm from './CategoryForm';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useSearchParams } from 'react-router-dom';

const Category: FC = () => {
  const { toNextStep } = useRegisterSteps();
  const toNextPage = useCallback(() => toNextStep(), [toNextStep]);
  const [searchParams] = useSearchParams();
  const attendeeCategoryId = useMemo(() =>
    Object.fromEntries(searchParams)?.attendeeCategory
      ? Number(Object.fromEntries(searchParams)?.attendeeCategory)
      : undefined
  , [searchParams]);
  return (
    <RegisterLayout>
      <CategoryForm
        onSaveSuccess={toNextPage}
        attendeeCategoryId={attendeeCategoryId}
      />
    </RegisterLayout>
  );
};

export default Category;
