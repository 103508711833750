import { getProductType, type TicketData } from '@/api/registration';
import { useCartQuery } from './useCartQuery';
import { useVisitor } from '@/app/ProviderVisitor';
import { useMemo } from 'react';
import { useEventSettingsQuery } from './useEventSettingsQuery';
import { useRegisterRoute } from './useRegisterRoute';

export function useIsAlreadyInCart(ticket: TicketData) {
  const { visitorId } = useVisitor();
  const { eventId } = useRegisterRoute();
  const { data: cart } = useCartQuery(visitorId);
  const { data: eventSetting } = useEventSettingsQuery(eventId);
  const result = useMemo(
    () => {
      if (eventSetting?.groupRegistration.isEnabled) return false; // group registration can buy multiple ticket
      return !!cart?.cartItems
        .find((cartItem) => (cartItem.productId === ticket.id && cartItem.productType === getProductType(ticket.type)));
    }, [cart?.cartItems, eventSetting?.groupRegistration, ticket.id, ticket.type]);
  return result;
}
