import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  // UiBox,
  // UiHStack,
  type UiHStackProps,
  UiIconPlusCircle,
  UiStack,
  uiStyles,
  UiHStack
  // UiGrid,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import TeamForm from './TeamForm';

export interface NewEventRowProps extends UiHStackProps {
}

const NewEventRow: FC<NewEventRowProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <UiHStack>
        <UiStack
          // bgColor={'gray.50'}
          borderRadius={'100%'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          // flexGrow={1}
          onClick={onToggle}
          {...uiStyles.hover}
          width={'64px'}
          // flexGrow={}
          p={0}
        >
          <UiIconPlusCircle
            size={64}
            color={'primary.600'}
            // width={64}
            weight={'light'}
          />
        </UiStack>
        {/* <UiText variant={'title'}>Add</UiText> */}
      </UiHStack>
      <TeamForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewEventRow;
