import { type FC } from 'react';
import
// RGL,
{
  Responsive as ResponsiveGridLayout,
  WidthProvider,
  type ResponsiveProps
  // type ReactGridLayoutProps,
} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

const ResponsiveReactGridLayout = WidthProvider(ResponsiveGridLayout);

export interface GridProps extends ResponsiveProps {
}

const Grid: FC<GridProps> = (props) => {
  return (
    <ResponsiveReactGridLayout
      // layout={layout}
      rowHeight={112}
      margin={[16, 48]}
      containerPadding={[0, 0]}
      // onLayoutChange={onLayoutChange}
      // onDrop={onDrop}
      // WidthProvider option
      // measureBeforeMount={false}
      // breakpoints={{ '2xl': 1536, xl: 1280, lg: 992, md: 768, sm: 480, base: 0 }}
      // cols={{ '2xl': 2, xl: 2, lg: 2, md: 1, sm: 1, base: 1 }}

      breakpoints={{ lg: 992, md: 768, base: 0 }} // The numbers here are from ChakraUI https://chakra-ui.com/docs/styled-system/responsive-styles
      cols={{ lg: 2, md: 1, base: 1 }}
      isDroppable={false}
      isResizable={false}
      isDraggable={false}
      {...props}
    />
  );
};

export default Grid;
