import { type FC } from 'react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface NewFormProps extends UiHStackProps {
}

const NewForm: FC<NewFormProps> = () => {
  const { tenantCode, eventId } = useRegisterRoute();

  return (
    <BaseRouterLink
      to={generatePageUrl('RegistrationBuildEventBuildPersonalInfoForm', { tenantCode, eventId })}
    >
      <UiHStack
        // bgColor={'gray.50'}
        borderRadius={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        // flexGrow={1}
        {...uiStyles.hover}
        // width={'64px'}
        // flexGrow={}
        p={0}
      >
        <UiIconPlusCircle
          size={56}
          color={'primary.600'}
          // width={64}
          weight={'light'}
        />
        <UiText variant={'title'} color={'primary.500'}>Add</UiText>
      </UiHStack>
    </BaseRouterLink>
  );
};

export default NewForm;
