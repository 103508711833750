import { type FC, type ReactNode } from 'react';
import {
  UiDivider,
  UiVStack,
  type UiStackProps,
  uiStyles
} from '@/lib/ui';
import { useConfig } from '@/app/ProviderConfig';

export interface BlockListProps extends UiStackProps {
  enableSeparator?: boolean
  header?: ReactNode
  footer?: ReactNode
}

export const BlockList: FC<BlockListProps> = ({
  // data,
  enableSeparator = true,
  header = undefined,
  footer = undefined,
  children,
  ...props
}) => {
  return (
    <UiVStack
      borderRadius={uiStyles.borderRadius}
      alignItems={'stretch'}
      {...props}
    >
      {header && header}
      <UiVStack
        p={0}
        spacing={0}
        alignItems={'stretch'}
        divider={enableSeparator
          ? (
            <UiDivider
              height={'1px'}
              backgroundColor={'blackAlpha.300'}
            />
            )
          : undefined}
      >
        {children}
      </UiVStack>
      {footer && footer}
    </UiVStack>
  );
};

export default BlockList;
