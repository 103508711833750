import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiText,
  uiStyles,
  UiHStack,
  UiIconCaretRight,
  UiIconCreditCard
} from '@/lib/ui';
import PayByCardDrawer, { type CreditCardGateway } from './PayByCardDrawer';

export interface PayByCardProps {
  creditCardGateway: CreditCardGateway
}

const PayByCard: FC<PayByCardProps> = ({
  creditCardGateway
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        {...uiStyles.hover}
        onClick={onToggle}
        justifyContent={'space-between'}
        px={{ base: 4, lg: 8 }}
        py={4}
        spacing={4}
      >
        <UiIconCreditCard size={'4xl'} color={'green.400'} />
        <UiText variant={'title'} flexGrow={1}>Pay by credit card</UiText>
        <UiIconCaretRight size={'4xl'} color={'primary.500'} />
      </UiHStack>
      <PayByCardDrawer
        creditCardGateway={creditCardGateway}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default PayByCard;
