import { type FC } from 'react';
import CategoryCard from './CategoryCard';
import {UiGrid, UiStack} from '@/lib/ui';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

export interface CategoryListProps {}

const CategoryList: FC<CategoryListProps> = () => {
  const { eventId, tenantCode } = useRegisterRoute();
  const attendeeCategoryQuery = useAttendeeCategoryQuery(eventId);

  return (
    <UiStack
      spacing={4}
      alignItems={'stretch'}
      flexGrow={1}
    >
      <QueryContainer query={attendeeCategoryQuery}>
        {(data) => (
          <>
            { data.map((attendeeCategory) => (
              <CategoryCard
                eventId={eventId}
                tenantCode={tenantCode}
                key={attendeeCategory.id}
                data={attendeeCategory} />
            ))}
          </>
        )}
      </QueryContainer>
    </UiStack>
  );
};

export default CategoryList;
