import { type FC } from 'react';
import {
  UiStack,
  UiIconIdentificationCard
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface BadgesProps {
  isActive?: boolean
}

const Badges: FC<BadgesProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconIdentificationCard}
      description={'Badges design'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventBadge', { tenantCode, eventId })}
      isSet={eventSettings?.badge?.isSet}
      isSkipped={!eventSettings?.badge?.isEnabled}
      isLast={true}
      // stepNumber={10}
    >
      <UiStack>
        {/* <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>Badges</UiText>
          <UiText variant={'body2'} color={'text.primary'} fontWeight={600}>0</UiText>
        </UiStack> */}
      </UiStack>
    </Step>
  );
};

export default Badges;
