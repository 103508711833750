import {
  type ApiRequest,
  deleteAPI,
  getRequestUrl,
  properCallApi,
  type ApiResponseSingle
} from '@/api/tenantClient';
import { type FieldsValues } from '@/base/FormGenerator/utils';

export const visitorQueryKey = 'registration.visitorList';

export interface RegistrationSteps {
  personalInformation: boolean
  mainEventTicket: boolean
  functionTicket: boolean
  workshopTicket: boolean
  tourTicket: boolean
  additionalInformation: boolean
}

export interface Visitor {
  id: number
  email: string
  attendeeCategoryId: number
  discountId?: number
  tmpIdPhotocopy?: File[]
  expiry: string
  termsAndConditionsAccepted: boolean
  privacyPolicyAccepted: boolean
  registrationSteps: RegistrationSteps
}

export interface VisitorQueryRequest {
  visitorId: number
}

export function loadVisitor(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: VisitorQueryRequest
  ): Promise<ApiResponseSingle<Visitor>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/visitors/' + params.visitorId;
    return await properCallApi<ApiResponseSingle<Visitor>>(request);
  };
}

export interface VisitorSaveRequest extends Omit<Visitor, 'id' | 'expiry' | 'registrationSteps'> {
  eventId: string
}

/**
 * @todo It should user callApi to call the backend API.
 */
export function saveVisitor(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: VisitorSaveRequest
  ): Promise<ApiResponseSingle<Visitor>> => {
    const formData = new FormData();
    formData.append('visitor[event_id]', params.eventId.toString());
    formData.append('visitor[attendee_category_id]', params.attendeeCategoryId.toString());
    formData.append('visitor[email]', params.email);
    params.discountId && formData.append('visitor[discount_id]', params.discountId.toString());
    params.tmpIdPhotocopy?.[0] && formData.append('visitor[tmp_id_photocopy]', params.tmpIdPhotocopy[0]);
    formData.append('visitor[termsAndConditionsAccepted]', params.termsAndConditionsAccepted.toString());
    formData.append('visitor[privacyPolicyAccepted]', params.privacyPolicyAccepted.toString());

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const fetchResponse = await fetch(getRequestUrl({ host: request.endpoint.host, path: '/api/v1/visitors' }), requestOptions);
    if (fetchResponse.status === 200 || fetchResponse.status === 201) {
      // Recieved the successful reponse from the backend.
      const jsonData = await fetchResponse.json();
      return (jsonData as ApiResponseSingle<Visitor>);
    } else {
      if (fetchResponse.status === 401) {
        throw new Error('Access denied.');
      } else {
        throw new Error('Failed API call.');
      }
    }
  };
}

export interface VisitorDeleteRequest {
  id: string
}

export function deleteVisitor(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: VisitorDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/visitors/' + params.id;
    await deleteAPI(request);
  };
}
