import { type FC } from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiButton,
  UiHStack
} from '@/lib/ui';
import Cart from './Cart';

export interface DrawerProps extends Omit<UiDrawerProps, 'children'> {}

const Drawer: FC<DrawerProps> = (props) => {
  return (
    <UiDrawer placement={'right'} size={'xl'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <UiDrawerBody px={0} pb={16} alignItems={'stretch'}>
          <UiStack
            px={{ base: 4, lg: 8 }}
            py={16}
            flexGrow={1}
          >
            <Cart />
          </UiStack>
          <UiHStack px={{ base: 4, lg: 8 }} justifyContent={'flex-end'}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={() => {}}>
              Checkout
            </UiButton>
          </UiHStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default Drawer;
