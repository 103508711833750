export const menuWidth = 400;
export const smartWindowVisibleWidth = 400;
export const smartWindowWidth = 400;

export interface WindowStates {
  isMenuVisible: boolean
  isSmartWindowVisible: boolean
}

export const getMainWindowOffset = (windowStates: WindowStates): number => {
  let offset = 0;
  if (!windowStates.isMenuVisible) {
    offset += menuWidth;
  }
  if (!windowStates.isSmartWindowVisible) {
    offset += smartWindowVisibleWidth;
  }
  return offset;
};
