import { type FC, useState } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  type registration
} from '@/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BaseBlockList from '@/base/BlockList';
import EventRow from './EventRow';

dayjs.extend(utc);

export interface EventListProps {
  queryParams: registration.EventListQueryRequest
}

const EventList: FC<EventListProps> = ({
  queryParams
}) => {
  return (
    <BaseBlockList
      bgColor={'#fff'}
      // shadow={'base'}
    >
      {/* {data?.items.map((eventData) => {
        return (
          <EventRow
            key={eventData.id}
            data={eventData}
            onClick={() => { setSelectedEventId(eventData.id); }}
            isSelected={selectedEventId === eventData.id}
          />
        );
      })} */}
    </BaseBlockList>
  );
};

export default EventList;
