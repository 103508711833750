import { type FC, type ReactElement, useState } from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import {
  UiVStack,
  UiStack,
  UiHStack,
  UiText,
  UiBox,
  UiImage,
  uiStyles,
  UiButton,
  UiIconSignOut,
  UiIconInfo,
  UiIconGear
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import { smartWindowVisibleWidth } from './viewport';

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export interface SmartWindowProps {
}

const SmartWindow: FC<SmartWindowProps> = () => {
  const [accountHeight, setAccountHeight] = useState('64px');

  return (
    <UiStack
      // enableShadow={false}
      // flexGrow={1}
      // alignItems={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      // minWidth={`${smartWindowVisibleWidth}px`}
      // maxWidth={`${smartWindowVisibleWidth}px`}
      flexGrow={1}
      // bgColor={'#fff'}
      pb={8}
      // px={8}
      // flexDirection={'column'}
      spacing={8}
      bgColor={'gray.100'}
      borderBottomRightRadius={uiStyles.borderRadius}
    >
      <UiVStack
        // alignItems={'center'}
        justifyContent={'flex-start'}
        // bgColor={'orange.600'}
        // height={accountHeight}
        overflowY={'hidden'}
        // flexGrow={1}
        // borderRadius={uiStyles.borderRadius}
        // onClick={() => {alert(111)}}
        transition={'height, padding, backgroundColor'}
        transitionDuration={'0.4s'}
        transitionTimingFunction={'ease-in-out'}
        p={8}
        // height={160}
        // bgColor={'gray.100'}
        // _hover={{
        //   height: '160px',
        //   padding: 4,
        //   backgroundColor: 'orange.600'
        // }}
        spacing={8}
      >
        <UiHStack
          alignItems={'center'}
          justifyContent={'space-between'}
          // flexGrow={1}
          {...uiStyles.hover}
          width={'100%'}
        >
          <UiImage
            width={accountHeight}
            height={accountHeight}
            src={'/image/dummy/user-avatar-2.webp'}
            borderRadius={uiStyles.borderRadius}
          />
          <BaseTextList
            flexGrow={1}
          >
            <BaseTextListItem variant='title' fontWeight={600}>derek.l@arinexgroup.com</BaseTextListItem>
            <BaseTextListItem variant='body1' >Arinex</BaseTextListItem>
          </BaseTextList>
        </UiHStack>
        <UiButton
          leftIcon={(<UiIconGear size={'2xl'} color={'#fff'}/>)}
          flexGrow={1}
          width={'100%'}
          // bgColor={'blackAlpha.100'}
          colorScheme={'primary'}
          // color={'primary.500'}
        >
          Manage account
        </UiButton>
      </UiVStack>
      {/* <UiStack
        p={4}
        borderRadius={uiStyles.borderRadius}
        bgColor={'orange.100'}
        width={'100%'}
      >
        <UiHStack>
          <UiIconInfo size={'3xl'} color={'orange.600'}/>
          <UiText>New orders</UiText>
        </UiHStack>
        <UiText>
          You've received 10 new orders in the last 24 hours. Here is the progress in your sales target.
        </UiText>
        <UiStack
          width={'100%'}
          alignItems={'center'}
          pt={4}
        >
          <UiBox width={'300px'} height={'300px'} p={0} m={0} bgColor={'transparent'} borderRadius={uiStyles.borderRadius}>
            <svg viewBox="0 0 300 300">
              <VictoryPie
                standalone={false}
                width={300} height={300}
                colorScale={['orange', '#fff']}
                data={[
                  { x: '85%', y: 85 }, { x: 2, y: 15 }
                ]}
                innerRadius={75} labelRadius={100}
                style={{ labels: { fontSize: 20, fill: '#fff' } }}
                // domainPadding={0}
                padding={0}
              />
              <VictoryLabel
                textAnchor={'middle'}
                style={{ fontSize: 20, fill: '#666' }}
                x={150} y={150}
                text={'Sales target'}
              />
            </svg>
          </UiBox>
        </UiStack>
        <UiStack
          // px={4}
          // py={6}
          pt={4}
          borderRadius={uiStyles.borderRadius}
          bgColor={'orange.100'}
          width={'100%'}
        >
          <UiText>
            Do you want to promote the remaining packages to potential sponsors?
          </UiText>
          <UiHStack flexDirection={'row-reverse'}>
            <UiButton ml={4}>Yes</UiButton>
            <UiButton variant={'unstyled'} bgColor={'transparent'} color={'primary.dark'} >No</UiButton>
          </UiHStack>
        </UiStack>
      </UiStack> */}
    </UiStack>
  );
};

export default SmartWindow;
