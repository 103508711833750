import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiHStack,
  UiIconCaretRight,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiStack,
  uiStyles
} from '@/lib/ui';
// import EventInfoList from '@/registration/component/Event/InfoList';
import EventInfoCard from '@/registration/component/Event/InfoCard';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventQuery } from '@/registration/hook/useEventQuery';

export interface EventInfoProps {
}

const EventInfo: FC<EventInfoProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const { data: event } = useEventQuery(eventId);

  return (
    <>
      <UiHStack
        alignItems={'center'}
        justifyContent={'space-between'}
        px={{ base: 4, lg: 8 }}
        // p={{base: 4, '2xl': 8}}
        // py={4}
        flexGrow={1}
        // bgColor={'blackAlpha.50'}
        onClick={onToggle}
        maxH={24}
        borderBottomWidth={'1px'}
        borderBottomStyle={'solid'}
        borderBottomColor={'gray.300'}
      >
        <UiStack spacing={0} p={{ base: 4, lg: 8 }}>
          {/* <UiIconInfo size={'3xl'}/> */}
          <UiText variant={'h6'}>{event?.name}</UiText>
        </UiStack>
        <UiHStack px={{ base: 4, lg: 8 }} py={4} justifyContent={'flex-end'} {...uiStyles.hover} alignItems={'center'}>
          <UiText variant={'title'} color={'primary.500'}>More</UiText>
          <UiIconCaretRight color={'primary.500'} size={'2xl'} weight={'bold'} />
        </UiHStack>
        {/* <UiText variant={'title'}>Event information</UiText> */}
      </UiHStack>
      <UiDrawer placement={'right'} size={'xl'} isOpen={isOpen} onClose={onToggle}>
        <UiDrawerOverlay />
        <UiDrawerContent bgColor={'gray.50'}>
          <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
          <UiDrawerBody px={0} pb={16} alignItems={'stretch'}>
            <UiStack
              pt={16}
              pb={8}
              flexGrow={1}
            >
              <UiHStack flexGrow={1} justifyContent={'space-around'} alignItems={'flex-start'}>
                <UiStack
                  // minW={320}
                  flexGrow={1}
                  // py={{base: 8, md: 8}}
                >
                  {event && (
                    <EventInfoCard event={event} />
                  )}
                </UiStack>
              </UiHStack>
            </UiStack>
          </UiDrawerBody>
        </UiDrawerContent>
      </UiDrawer>
    </>
  );
};

export default EventInfo;
