import { type FC } from 'react';
import { locale } from '@/lib/util';

const { createFormatPrice } = locale;
const formatPrice = createFormatPrice({ locale: 'en-AU', currency: 'AUD' });

export interface PriceDisplayProps {
  price: number // Dollars.
  suffix?: string
}

const PriceDisplay: FC<PriceDisplayProps> = ({
  price,
  suffix = ''
}) => {
  return `${formatPrice((price ?? 0) * 100)}${suffix}`;
};

export default PriceDisplay;
