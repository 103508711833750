import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppEventWebsiteCard from '@/base/App/EventWebsiteCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface ContentDeliveryPlatformProps extends UiBoxProps {
  isSelected: boolean
  isIncluded?: boolean
  onToggle?: (isSelected: boolean) => void
  bgColor?: ModuleCardProps['bgColor']
}

const ContentDeliveryPlatform: FC<ContentDeliveryPlatformProps> = ({
  isSelected,
  bgColor = 'blackAlpha.50'
}) => {
  return (
    <ModuleCard
      isSelected={isSelected}
      bgColor={bgColor}
    >
      <BaseAppEventWebsiteCard />
    </ModuleCard>
  );
};

export default ContentDeliveryPlatform;
