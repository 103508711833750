import { useQuery } from '@tanstack/react-query';
import { eventListQueryKey, type Event } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';

// We do not need to wrap every react query or mutations into hook. The reason why we do it here is because this query here will be used in a lot of components.
export function useEventListQuery() {
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();
  // Load event settings from the backend.
  return useQuery<ApiResponse<Event>, Error>({
    queryKey: [eventListQueryKey],
    queryFn: async () => {
      return await registration.loadEventList(createTenantApiRequest)();
    },
    enabled: !isApiPreparing
  });
}
