import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiGrid
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import Invoice from './Invoice';
import Online from './Online';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import NewPaymentMethod from './AddPayment';

const DiscountCodes: FC = () => {
  const { eventId } = useRegisterRoute();
  const { mutate, isLoading } = useIsSetMutation();
  const { toNextStep } = useBuildEventSteps();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'paymentMethod', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'paymentMethods'}
          />
        )}
        message={'Please provide your payment methods'}
      >
        <UiStack
          // pt={4}
          spacing={4}
          flexGrow={1}
          p={8}
        >
          {/* <NewPaymentMethod /> */}
          {/* <UiGrid */}
          {/*  templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }} */}
          {/*  gap={8} */}
          {/* > */}
          <Online />
          <Invoice />
          {/* </UiGrid> */}
        </UiStack>
        <UiHStack
          p={8}
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <UiButton
            size={'lg'}
            colorScheme={'primary'}
            type={'submit'}
            fontSize={'lg'}
            onClick={onNext}
            isLoading={isLoading}>
            Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default DiscountCodes;
