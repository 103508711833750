import { type FC } from 'react';
import { UiHStack, type UiHStackProps, UiStack } from '@/lib/ui';

export interface FooterProps extends UiHStackProps {

}

const Footer: FC<FooterProps> = (props) => {
  return (
    <UiHStack
      position={'fixed'}
      left={0}
      bottom={0}
      px={8}
      py={6}
      bgColor={'#fff'}
      width={'100%'}
      zIndex={999}
      borderTopWidth={'1px'}
      borderTopStyle={'solid'}
      borderTopColor={'gray.300'}
      {...props}
    />
  );
};

export default Footer;
