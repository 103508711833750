import { type FC, type ReactNode } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles
} from '@/lib/ui';
// import { RouteParams } from '@/app/pages';
// import Filter from './Filter';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';

export interface BodyProps {
  children: ReactNode
  basePadding?: number
  breadcrumbNodes?: BaseBreadcrumbBarNode[]
  isStandalone?: boolean
}

const Body: FC<BodyProps> = ({
  children,
  basePadding = 8,
  breadcrumbNodes = undefined,
  isStandalone = true
}) => {
  // const [ isSmartWindowVisible, setIsSmartWindowVisible ] = useState(false);
  // const [showFilter, setShowFilter] = useState<boolean>(false);
  // const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
  //   () => {
  //     return [
  //       {label: 'Dashboard'},
  //     ]
  //   },
  //   []
  // );

  // const onSmartButtonClick = () => {
  //   setIsSmartWindowVisible(!isSmartWindowVisible);
  // }

  return (
    <UiStack
      spacing={0.5}
      alignItems={'stretch'}
      flexGrow={1}
      // borderRightRadius={uiStyles.borderRadius}
      // borderLeftRadius={isStandalone ? uiStyles.borderRadius : 0}
      // shadow={'base'}
      // borderRadius={uiStyles.borderRadius}
    >
      {!!breadcrumbNodes && (
        <UiStack
          borderTopRadius={uiStyles.borderRadius}
          spacing={0}
          {...uiStyles.glass}
        >
          <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius}>
            <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes} />
          </UiStack>
        </UiStack>
      )}
      <BaseTransitionSlideLeft>
        <UiStack
          // borderRightRadius={uiStyles.borderRadius}
          // borderLeftRadius={isStandalone ? uiStyles.borderRadius : 0}
          // borderBottomRightRadius={uiStyles.borderRadius}
          // borderBottomLeftRadius={isStandalone ? uiStyles.borderRadius : 0}
          // borderTopRadius={0}
          // shadow={'inner'}
          spacing={0}
          alignItems={'stretch'}
          flexGrow={1}
          p={basePadding}
          {...uiStyles.glass}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default Body;
