import { useMemo, type FC, type ReactNode } from 'react';
import {
  UiStack,
  uiStyles
} from '@/lib/ui';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface LayoutBodyProps {
  children: ReactNode
  steps: ReactNode
  breadcrumbNodes?: BaseBreadcrumbBarNode[]
  message?: string
}

const LayoutBody: FC<LayoutBodyProps> = ({
  children,
  steps,
  breadcrumbNodes,
  message = undefined
}) => {
  const { tenantCode } = useRegisterRoute();

  const defaultBreadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => [
      { label: 'Manage' },
      { label: 'Registration setup', url: generatePageUrl('RegistrationManageEvent', { tenantCode }) },
      { label: 'Build' }
    ],
    [tenantCode]
  );

  return (
    <UiStack
      spacing={0.5}
      alignItems={'stretch'}
      flexGrow={1}
    >
      <UiStack
        alignItems={'stretch'}
        spacing={0}
        enableDivider={true}
        // borderTopRadius={uiStyles.borderRadius}
        {...uiStyles.glass}
      >
        <UiStack
          borderTopRadius={uiStyles.borderRadius}
          spacing={0}
        >
          <UiStack
            p={8}
            py={6}
            // borderRadius={uiStyles.borderRadius}
          >
            <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes ?? defaultBreadcrumbNodes} />
          </UiStack>
        </UiStack>
        <UiStack
          // borderBottomRadius={uiStyles.borderRadius}
          spacing={0}
        >
          <UiStack
            alignItems={'stretch'}
            justifyContent={'flex-start'}
            spacing={8}
          >
            {steps}
          </UiStack>
        </UiStack>
      </UiStack>
      <BaseTransitionSlideLeft>
        <UiStack
          alignItems={'stretch'}
          flexGrow={1}
          spacing={0}
          // borderBottomRadius={uiStyles.borderRadius}
          {...uiStyles.glass}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default LayoutBody;
