import { useMemo, type FC } from 'react';
import { UiHStack, type UiHStackProps, uiStyles } from '@/lib/ui';

export interface ItemProps extends UiHStackProps {
  enableBorderTopRadius?: boolean
  enableBorderBottomRadius?: boolean
  enableBoxShadow?: boolean
  onClick?: UiHStackProps['onClick']
  enableHover?: boolean
}

const Item: FC<ItemProps> = ({
  enableBorderTopRadius = false,
  enableBorderBottomRadius = false,
  enableBoxShadow = false,
  onClick = undefined,
  enableHover = false,
  ...props
}) => {
  const _hoverTransition = useMemo(
    () => {
      return enableHover
        ? {
            transform: 'opacity',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'ease-in-out',
            opacity: 1,
            _hover: {
              opacity: 0.5,
              transform: 'opacity',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'ease-in-out',
              cursor: 'pointer'
            }
          }
        : {};
    },
    [enableHover]
  );

  return (
    <UiHStack
      borderTopRadius={enableBorderTopRadius ? uiStyles.borderRadius : 'none'}
      borderBottomRadius={enableBorderBottomRadius ? uiStyles.borderRadius : 'none'}
      boxShadow={enableBoxShadow ? 'base' : 'none'}
      flexGrow={1}
      onClick={onClick}
      spacing={0}
      {..._hoverTransition}
      {...props}
    />
  );
};

export default Item;
