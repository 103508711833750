function encodeKey(key: string): string {
  return `AO_WEB_${key}`;
}

export interface SaveItemRequest {
  key: string;
  item: string;
}

export function saveItem(request: SaveItemRequest) {
  localStorage.setItem(encodeKey(request.key), request.item);
}


export interface LoadItemRequest {
  key: string;
}

export interface LoadItemReseponse<Item> {
  item: Item|null;
  error?: string;
}

export function loadItem<Item>(request: LoadItemRequest): LoadItemReseponse<Item> {
  const itemJsonString = localStorage.getItem(encodeKey(request.key));
  if (itemJsonString) {
    try {
      const item = JSON.parse(itemJsonString);
      return {
        item: item as Item,
      }
    } catch (e) {
      return {
        item: null,
        error: 'Failed to pase the item',
      };
    }
  }
  return {
    item: null,
    error: 'Item not found',
  };
}
