import { type FC, type PropsWithChildren } from 'react';
import { Field, type FieldProps } from 'formik';
import { UiCheckbox, UiFormControl, UiFormErrorMessage, UiHStack, UiVStack } from '@/lib/ui';

interface CheckboxFieldProps extends PropsWithChildren {
  name: string
}

const CheckboxField: FC<CheckboxFieldProps> = ({ name, children }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1}>
            <UiVStack alignItems={'stretch'}>
              <UiHStack spacing={2}>
                <UiCheckbox {...field} size={'lg'} colorScheme={'primary'} borderColor={'gray.500'} />
                {children}
              </UiHStack>
              {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
            </UiVStack>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default CheckboxField;
