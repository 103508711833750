import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton,
  UiText,
  UiLink,
  uiStyles, UiHStack
} from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import { useMutation } from '@tanstack/react-query';
import { registration } from '@/api';
import { type Visitor, type VisitorSaveRequest } from '@/api/registration/visitor';
import { useLocalStorage } from '@uidotdev/usehooks';
import CheckboxField from '@/base/Form/CheckboxField';
import DiscountCodeForm from './DiscountCodeForm';
import { IsRequiredType, type DiscountCode } from '@/api/registration';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useVisitor } from '@/app/ProviderVisitor';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseFormInputField from '@/base/Form/InputField';
import { HStack } from '@chakra-ui/react';

export interface CategoryFormProps extends UiHStackProps {
  attendeeCategoryId: number | undefined
  onSaveSuccess: () => void
}
export interface FormData {
  attendeeCategory: string | undefined
  email: string
  discountCode: string
  idPhotocopy: File[]
  isPrivacyPolicyAgreed: boolean
  isTermsAndConditionsAgreed: boolean
}

const initFormData: FormData = {
  attendeeCategory: '',
  email: '',
  discountCode: '',
  idPhotocopy: [],
  isPrivacyPolicyAgreed: false,
  isTermsAndConditionsAgreed: false
};

const basicSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  discountCode: Yup.string(),
  idPhotocopyBase64: Yup.string()
});

const CategoryForm: FC<CategoryFormProps> = ({
  attendeeCategoryId,
  onSaveSuccess
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const eventQuery = useEventSettingsQuery(eventId);

  const { setVisitorId } = useVisitor();
  const [, saveAttendeeCategoryId] = useLocalStorage<number | null>('attendeeCategoryId', attendeeCategoryId);
  const [, saveDiscountId] = useLocalStorage<number | null>('discountId', null);
  const [discountCode, setDiscountCode] = useState<DiscountCode>();
  const { createTenantApiRequest } = useTenantApi();

  const attendeeCategoryQuery = useAttendeeCategoryQuery(eventId);

  const options: CheckboxOption[] = attendeeCategoryQuery.data?.map((attendeeCategory) => ({
    value: attendeeCategory.id.toString(),
    label: attendeeCategory.name,
    description: attendeeCategory.description
  })) ?? [];

  const { mutateAsync, isLoading } = useMutation(
    async (visitorData: VisitorSaveRequest): Promise<Visitor> => {
      const response = await registration.saveVisitor(createTenantApiRequest)(visitorData);
      return response.item;
    },
    {
      onError: (error: any) => setSaveErrors([error.message ?? 'Failed to save the host.'])
    }
  );

  const formSchema = useMemo(() =>
    basicSchema.shape({
      ...attendeeCategoryQuery.data?.length
        ? { attendeeCategory: Yup.string().required('Please choose an attendee category') }
        : {},
      ...discountCode?.idPhotocopy === IsRequiredType.REQUIRED
        ? { idPhotocopy: Yup.array().min(1, 'ID Photocopy is required') }
        : {},
      ...eventQuery.data?.privacy.privacyPolicyUrl
        ? { isPrivacyPolicyAgreed: Yup.boolean().isTrue('Please agree with the privacy policy before continue') }
        : {},
      ...eventQuery.data?.privacy.termsAndConditionsUrl
        ? { isTermsAndConditionsAgreed: Yup.boolean().isTrue('Please agree with terms and condition before continue') }
        : {}
    })
  , [
    attendeeCategoryQuery.data?.length,
    discountCode?.idPhotocopy,
    eventQuery.data?.privacy.privacyPolicyUrl,
    eventQuery.data?.privacy.termsAndConditionsUrl
  ]);

  const onSubmit = useCallback(
    async (
      values: FormData,
      { setSubmitting }: FormikHelpers<FormData>
    ) => {
      setSubmitting(true);
      const visitorData = await mutateAsync({
        eventId,
        attendeeCategoryId: Number(values.attendeeCategory),
        email: values.email,
        tmpIdPhotocopy: values.idPhotocopy,
        termsAndConditionsAccepted: values.isTermsAndConditionsAgreed,
        privacyPolicyAccepted: values.isPrivacyPolicyAgreed,
        ...discountCode ? { discountId: discountCode.id } : {}
      });
      setVisitorId(visitorData.id);
      saveAttendeeCategoryId(visitorData.attendeeCategoryId);
      saveDiscountId(visitorData.discountId ?? null);
      setSubmitting(false);
      onSaveSuccess();
    }, [discountCode, eventId, onSaveSuccess, saveAttendeeCategoryId, saveDiscountId, setVisitorId, mutateAsync]);

  return (
    <Formik
      initialValues={{
        ...initFormData,
        attendeeCategory: attendeeCategoryId ? attendeeCategoryId.toString() : undefined
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <Form style={{ display: 'flex', flexDirection: 'column' }}>
        <BaseTransitionSlideLeft>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              ))}
            </UiStack>
          )}
          <BaseFormFieldGroup>
            {
              !attendeeCategoryId && options.length > 0 ? (
                <BaseFormCheckboxListField
                  label={'Attendee category'}
                  name={'attendeeCategory'}
                  options={options}
                  isMultiple={false}
                  layout={'stack'}
                  // helperText={'@todo This field might be hidden since admin users can choose to send attendee category specific urls to delegates.'}
                />
              ) : <></>
            }
            <UiStack spacing={4}>
              <BaseFormInputField
                name={'email'}
                label={'Email'}
                isRequired={true}
                layout={'stack'}
              />
            </UiStack>
            <DiscountCodeForm
              onUpdate={setDiscountCode}
            />
            <BaseDividerHorizontal height={1} />
            <UiStack spacing={4} pb={4}>
              {!!eventQuery.data?.privacy.privacyPolicyUrl && (
                <CheckboxField name="isPrivacyPolicyAgreed">
                  <HStack spacing={0}>
                    <UiText>{'I have read and agree to the\u00A0'}</UiText>
                    <UiLink target={'_blank'} href={eventQuery.data?.privacy.privacyPolicyUrl}>
                      <UiText color={'primary.500'} {...uiStyles.hover}>Privacy Policy</UiText>
                    </UiLink>
                  </HStack>
                </CheckboxField>
              )}
              {!!eventQuery.data?.privacy.termsAndConditionsUrl && (
                <CheckboxField name="isTermsAndConditionsAgreed">
                  <HStack spacing={0}>
                    <UiText>{'I have read and agree to the\u00A0'}</UiText>
                    <UiLink target={'_blank'} href={eventQuery.data?.privacy.termsAndConditionsUrl}>
                      <UiText color={'primary.500'} {...uiStyles.hover}>Terms and Conditions</UiText>
                    </UiLink>
                  </HStack>
                </CheckboxField>
              )}
            </UiStack>
          </BaseFormFieldGroup>
        </BaseTransitionSlideLeft>
        <UiStack height={32} />
        <LayoutFooter justifyContent={'flex-end'}>
          <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
            Next
          </UiButton>
        </LayoutFooter>
      </Form>
    </Formik>
  );
};

export default CategoryForm;
