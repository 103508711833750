import { type FC, type ReactNode } from 'react';
import {
  UiStack,
  type UiStackProps,
  UiDivider
} from '@/lib/ui';

export interface GroupProps extends UiStackProps {
  enableSeparator?: boolean
  footer?: ReactNode
}

export const Group: FC<GroupProps> = ({
  // data,
  enableSeparator = true,
  footer = undefined,
  children,
  ...props
}) => {
  return (
    <UiStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      divider={enableSeparator
        ? (
          <UiDivider
            height={'1px'}
            backgroundColor={'blackAlpha.300'}
          />
          )
        : undefined}
      {...props}
    >
      {children}
    </UiStack>
  );
};

export default Group;
