import { type FC } from 'react';
import { UiSimpleGrid } from '@/lib/ui';
import Registration from './Registration';
import AbstractManagement from './AbstractManagement';
import ContentDeliveryPlatform from './ContentDeliveryPlatform';
import MobileEventApp from './MobileEventApp';
import DigitalPoster from './DigitalPoster';
import LiveStreaming from './LiveStreaming';
import SponsorExhibitor from './SponsorExhibitor';
import Webinar from './Webinar';
import DataInsight from './DataInsight';
import { SubscriptionProduct } from '@/api/constant/subscription';

export interface ModuleListProps {
  modules: SubscriptionProduct[]
}

const ModuleList: FC<ModuleListProps> = ({
  modules
}) => {
  return (
    <>
      <UiSimpleGrid columns={{ base: 1, '2xl': 2 }} gap={4}>
        {modules.includes(SubscriptionProduct.Registration) && (
          <Registration isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.EventWebsite) && (
          <ContentDeliveryPlatform isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.Webinar) && (
          <Webinar isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.SponsorExhibitor) && (
          <SponsorExhibitor isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.LiveStreaming) && (
          <LiveStreaming isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.DataInsight) && (
          <DataInsight isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.AbstractManagement) && (
          <AbstractManagement isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.DigitalPoster) && (
          <DigitalPoster isSelected={true} />
        )}
        {modules.includes(SubscriptionProduct.MobileEventApp) && (
          <MobileEventApp isSelected={true} />
        )}
      </UiSimpleGrid>
    </>
  );
};

export default ModuleList;
