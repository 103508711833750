import { type FC } from 'react';
import {
  IdentificationCard
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconIdentificationCardProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconIdentificationCard: FC<UiIconIdentificationCardProps> = (props) => {
  return (
    <UiIcon
      IconComponent={IdentificationCard}
      {...props}
    />
  );
};
