export enum TicketType {
  Main = 1,
  Function = 2,
  Workshop = 3,
  Tour = 4,
}

export const ticketTypeLabels: Record<TicketType, string> = {
  [TicketType.Main]: 'Main ticket',
  [TicketType.Function]: 'Function',
  [TicketType.Workshop]: 'Workshop',
  [TicketType.Tour]: 'Tour'
};

export const ticketTypeOptions: Array<{ value: number, label: string }> = [
  { value: TicketType.Main, label: ticketTypeLabels[TicketType.Main] },
  { value: TicketType.Function, label: ticketTypeLabels[TicketType.Function] },
  { value: TicketType.Workshop, label: ticketTypeLabels[TicketType.Workshop] },
  { value: TicketType.Tour, label: ticketTypeLabels[TicketType.Tour] }
];

export enum TicketState {
  Valid = 1,
  Deleted = 2,
}

export const ticketStateLabels: Record<TicketState, string> = {
  [TicketState.Valid]: 'Valid',
  [TicketState.Deleted]: 'Deleted'
};

export const ticketStateOptions: Array<{ value: number, label: string }> = [
  { value: TicketState.Valid, label: ticketStateLabels[TicketState.Valid] },
  { value: TicketState.Deleted, label: ticketStateLabels[TicketState.Deleted] }
];
