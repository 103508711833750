import { type FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/BreadcrumbBody';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine
} from '@/lib/ui';
import { RouteParams, generatePageUrl } from '@/app/pages';
// import Filter from './Filter';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
// import AppList from './AppList';
import SideNav from '@/account/component/Layout/SideNav';
import SwitchApp from '@/account/component/Layout/SwitchApp';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import MemberTable from './MemberTable';
import ModulePermissions from './ModulePermissions';
import NewPermission from './NewPermission';
// import MemberTable from './MemberTable';

const TeamInfo: FC = () => {
  const [isSmartWindowVisible, setIsSmartWindowVisible] = useState(false);
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Users and access' },
        { label: 'Teams', url: generatePageUrl('AccountAccessTeam', { tenantCode: 'dev' }) },
        { label: 'Rego management' }
      ];
    },
    []
  );

  return (
    <>
      <BaseLayout
        MainTransition={null}
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        // sideMenu={(<SideNav activeNodes={['manage', 'team']}/>)}
        // breadcrumb={(<BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes}/>)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard />} currentAppName={'account'} />)}
      >
        <BaseLayoutBody
          breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'} p={8}>
            <NewPermission />
            {/* <UiText variant={'title'}>Permissions</UiText> */}
            <ModulePermissions />
            <UiStack py={4} pt={8}>
              <UiDivider borderColor={'gray.300'} />
            </UiStack>
            <UiText variant={'title'}>Users</UiText>
            <MemberTable />
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default TeamInfo;
