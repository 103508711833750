import {
  callApi,
  type ApiRequest,
  type ApiResponse
} from '@/api/tenantClient';

export interface InfoFormResponseSaveRequest {
  eventId: string
  formType: 'personal' | 'additional'
  response: Record<string, any>
  ownerId: number
  ownerType: string
  infoFormId?: number
}

export function saveInfoFormResponse(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: InfoFormResponseSaveRequest
  ): Promise<ApiResponse<{}>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/info_form_responses';
    request.payload = params;
    return await callApi<{}>(request);
  };
}
