import AdminAccess from '@/account/component/AdminAccess';
import { type FC } from 'react';
import { Outlet } from 'react-router-dom';

const BuildEventLayout: FC = () => (
  <AdminAccess>
    <Outlet />
  </AdminAccess>
);

export default BuildEventLayout;
