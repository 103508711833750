import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiStack, UiText } from '@/lib/ui';
import PayByCard from './PayByCard';
import PayByInvoice from './PayByInvoice';

const Payment: FC = () => {
  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={4}>
          <UiStack>
            <UiText variant={'h6'}>Choose payment method</UiText>
          </UiStack>
          <PayByCard creditCardGateway='Stripe' />
          <PayByInvoice />
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={32} />
    </RegisterLayout>
  );
};

export default Payment;
