import { type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconStackSimple
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface MainEventTicketsProps {
  isActive?: boolean
}

const MainEventTickets: FC<MainEventTicketsProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const {
    data: eventSettings
  } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconStackSimple}
      description={'Main event tickets'}
      isActive={isActive}
      // stepNumber={4}
      navLink={generatePageUrl('RegistrationBuildEventMainEventTickets', { tenantCode, eventId })}
      isSet={eventSettings?.mainEventTicket?.isSet}
    >
      <UiStack>
        <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.delegateType?.total} total</UiText>
      </UiStack>
    </Step>);
};

export default MainEventTickets;
