import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiText,
  uiStyles,
  UiHStack,
  UiIconCaretRight,
  UiIconCreditCard
} from '@/lib/ui';
import PayByInvoiceDrawer from './PayByInvoiceDrawer';

export interface PayByInvoiceProps {
}

const PayByInvoice: FC<PayByInvoiceProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        {...uiStyles.hover}
        onClick={onToggle}
        justifyContent={'space-between'}
        px={{ base: 4, lg: 8 }}
        py={4}
        spacing={4}
      >
        <UiIconCreditCard size={'4xl'} color={'indigo.400'} />
        <UiText variant={'title'} flexGrow={1}>Pay by invoice (offline)</UiText>
        <UiIconCaretRight size={'4xl'} color={'primary.500'} />
      </UiHStack>
      <PayByInvoiceDrawer
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default PayByInvoice;
