import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CodeCard from './CodeCard';
import { UiStack } from '@/lib/ui';
import { type DiscountCode } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';

dayjs.extend(utc);

export interface CodeListProps {
  // queryParams: registration.EventListQueryRequest;
}

const CodeList: FC<CodeListProps> = () => {
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const discountCodeQuery = useQuery<DiscountCode[], Error>({
    queryKey: [registration.discountCodeQueryKey, { eventId }],
    queryFn: async () => {
      const result = await registration.loadDiscountCodes(createTenantAdminApiRequest)({ eventId });
      return result.items?.[0];
    },
    enabled: !isApiPreparing
  });

  return (
    <UiStack
      spacing={4}
      alignItems={'stretch'}
      flexGrow={1}
    >
      <QueryContainer query={discountCodeQuery}>
        {(data) => (
          <>
            {data.map((discountCode) => (
              <CodeCard
                key={discountCode.id}
                discountCode={discountCode}
              />
            ))
            }
          </>
        )}
      </QueryContainer>

    </UiStack>
  );
};

export default CodeList;
