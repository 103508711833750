import { type FC, useEffect, useState } from 'react';
import {
  UiFlex,
  type UiFlexProps
} from '@/lib/ui';

export interface SlideLeftProps extends UiFlexProps {
  offset?: string
}

export const SlideLeft: FC<SlideLeftProps> = ({
  offset = '50%',
  ...props
}) => {
  const [transX, setTransX] = useState(offset);

  useEffect(
    () => {
      setTransX('0');
    },
    []
  );

  return (
    <UiFlex
      transform={`translateX(${transX})`}
      transitionDuration={'0.5s'}
      transitionTimingFunction={'ease-in-out'}
      flexGrow={1}
      {...props}
    />
  );
};

export default SlideLeft;
