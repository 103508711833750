import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import Steps from './Steps';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const Checkout: FC = () => {
  const { tenantCode, eventId } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'privacy'}
          />
        )}
        message={'Please tell us about your registration.'}
      >
        <UiStack
          // pt={4}
          // px={4}
          spacing={0}
          flexGrow={1}
        >
          <Steps />
        </UiStack>
        <UiHStack justifyContent={'space-between'}>
          <UiStack flexGrow={1} />
          <BaseRouterLink to={generatePageUrl('RegistrationBuildEventBadge', { tenantCode, eventId })}>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'}>
              Next
            </UiButton>
          </BaseRouterLink>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default Checkout;
