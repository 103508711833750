import { type FC } from 'react';
import {
  List
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconListProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconList: FC<UiIconListProps> = (props) => {
  return (
    <UiIcon
      IconComponent={List}
      {...props}
    />
  );
};
