import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { cartQueryKey, type CartDisplay } from '@/api/registration/cart';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useCartQuery(visitorId: number | undefined) {
  const { createTenantApiRequest, createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  return useQuery<CartDisplay, Error>({
    queryKey: [cartQueryKey, { visitorId }],
    queryFn: async (): Promise<CartDisplay> => {
      let result = await registration.loadCart(createTenantApiRequest)({ visitorId: visitorId! });
      if (result.items?.length === 0) {
        result = await registration.saveCart(createTenantAdminApiRequest)({ visitorId: visitorId! });
      }
      return result.items?.[0];
    },
    enabled: !!visitorId && !isApiPreparing
  });
}
