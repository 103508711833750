import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import AdditionalInfoForm from './AdditionalInfoForm';

const AdditionalInfo: FC = () => {
  return (
    <RegisterLayout>
      {/* <BaseTransitionSlideLeft> */}
      <AdditionalInfoForm />
      {/* </BaseTransitionSlideLeft> */}
    </RegisterLayout>
  );
};

export default AdditionalInfo;
