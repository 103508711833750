import {
  type ApiRequest,
  callApi
} from '@/api/globalClient';

export const tenantQueryKey = 'account.tenant';

export interface TenantData {
  id: number
  state: number
  name: string
  code: string
  apiEndpoint: string
  createdAt: number
  updatedAt: number
}

export interface TenantLoadByCodeRequest {
  code: string // The tenant code.
}

export interface TenantLoadByCodeResponse {
  item: TenantData
  errors: string[]
}

const emptyTenant: TenantData = {
  id: 0,
  state: 1,
  name: '',
  code: '',
  apiEndpoint: '',
  createdAt: 0,
  updatedAt: 0
};

/**
 * Always return an admin user data.
 * If the email is empty, an empty admin user data will be returned.
 *
 * @param params
 * @returns
 */
export async function loadTenantByCode(
  params: TenantLoadByCodeRequest
): Promise<TenantLoadByCodeResponse> {
  if (params.code) {
    const request: ApiRequest = {
      method: 'POST',
      endpoint: {
        path: '/api/tenant/load-by-code'
      },
      payload: {
        code: params.code
      }
    };
    const response = await callApi<TenantLoadByCodeResponse>(request);
    return {
      item: response.item,
      errors: response.errors
    };
  }
  return {
    item: emptyTenant,
    errors: []
  };
}
