import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiStack, UiText } from '@/lib/ui';
import TicketList from '../MainTickets/TicketList';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { TicketType } from '@/api/constant';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

const MainTickets: FC = () => {
  const { eventId } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  const { toNextStep, toPreviousStep } = useRegisterSteps();

  return (
    <RegisterLayout>
      <QueryContainer query={eventQuery}>
        {() => (
          <>
            <BaseTransitionSlideLeft>
              <UiStack flexGrow={1}>
                <UiStack pb={4}>
                  <UiText variant={'h6'}>Function tickets</UiText>
                </UiStack>
                <TicketList ticketType={TicketType.Function} onEmpty={toNextStep} />
              </UiStack>
            </BaseTransitionSlideLeft>
            <UiStack height={32} />
            <LayoutFooter justifyContent={'flex-end'}>
              <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                Previous
              </UiButton>
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
                Next
              </UiButton>
            </LayoutFooter>
          </>
        )}
      </QueryContainer>
    </RegisterLayout>
  );
};

export default MainTickets;
