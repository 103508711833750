import { type FC, useCallback, useEffect, useState } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { registration } from '@/api';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { IsRequiredType, type DiscountCode } from '@/api/registration';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { UiStack, UiText } from '@/lib/ui';

export interface DiscountCodeFormProps {
  onUpdate: (discountCode: DiscountCode) => void
}

const DiscountCodeForm: FC<DiscountCodeFormProps> = ({ onUpdate }) => {
  const { eventId } = useRegisterRoute();
  const [error, setError] = useState<string>();
  const { values } = useFormikContext<{ discountCode: string }>();
  const [debouncedDiscountCode] = useDebounce<string>(values.discountCode, 1000);
  const [isRequired, setIsRequired] = useState<IsRequiredType>(IsRequiredType.NOT_REQUIRED);
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const checkDiscountCode = useCallback(
    async (code: string) => await registration.checkDiscountCode(createTenantApiRequest)({ eventId, discountCode: code })
    , [createTenantApiRequest, eventId]);

  useEffect(() => {
    void (async () => {
      if (debouncedDiscountCode === '') {
        setError(undefined);
      } else {
        const response = await checkDiscountCode(debouncedDiscountCode);
        if (response.errors && response.errors.length > 0) {
          setError('Invalid discount code');
          setIsRequired(IsRequiredType.NOT_REQUIRED);
        } else {
          setIsRequired(response.items[0].idPhotocopy);
          setError(undefined);
          onUpdate(response.items[0]);
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDiscountCode, onUpdate]);

  return (
    <>
      <UiStack spacing={4}>
        <BaseFormInputField
          name={'discountCode'}
          label={'Discount code'}
          isRequired={false}
          layout={'stack'}
        />
        {error && (
          <UiText color={'red.500'}>{error}</UiText>
        )}
      </UiStack>
      {(isRequired === IsRequiredType.REQUIRED || isRequired === IsRequiredType.OPTIONAL) && (
        <ImageSelectField
          name="idPhotocopy"
          label="ID photocopy"
          isRequired={isRequired === IsRequiredType.REQUIRED}
          layout={'stack'}
        />
      )}
    </>
  );
};

export default DiscountCodeForm;
