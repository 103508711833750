export interface LocalePriceOptions {
  locale?: string
  currency?: string
}

export function createFormatPrice(options: LocalePriceOptions = {
  locale: 'en-AU',
  currency: 'AUD'
}): (amount: number) => string {
  return (amount: number): string => {
    return (new Intl.NumberFormat(options.locale, { style: 'currency', currency: options.currency }).format(amount / 100)).toString();
  };
}
