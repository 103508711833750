import { useMemo, type FC } from 'react';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import { locale } from '@/lib/util';
import Item from './Item';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useVisitor } from '@/app/ProviderVisitor';

const { createFormatPrice } = locale;

export interface CartProps {}

const Cart: FC<CartProps> = () => {
  const priceCurrency = 'AUD';
  const priceLocale = 'en-AU';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );

  const { visitorId } = useVisitor();

  const cartQuery = useCartQuery(visitorId);

  const subtotal = useMemo(
    () => formatPrice((cartQuery.data?.subtotal ?? 0) * 100),
    [formatPrice, cartQuery.data]
  );

  const totalAmount = useMemo(
    () => formatPrice((cartQuery.data?.totalAmount ?? 0) * 100),
    [formatPrice, cartQuery.data]
  );

  return (
    <QueryContainer
      query={cartQuery}
      errorMessage="Failed to load the shopping cart.">
      {(shoppingCart) => (
        <UiStack flexGrow={1} spacing={4}>
          {shoppingCart.cartItems.map((itemData, index) => (
            <Item
              key={index}
              itemId={itemData.id}
              name={itemData.name}
              quantity={itemData.quantity}
              price={itemData.price}
              cartId={shoppingCart.id}
              visitorId={visitorId!} />
          ))}
          <UiHStack justifyContent={'flex-end'} flexGrow={1}>
            <UiStack py={2} justifyContent={'flex-end'} px={4}>
              <UiHStack alignItems={'center'} justifyContent={'flex-end'}>
                <UiText variant={'body1'} color={'text.secondary'}>Subtotal</UiText>
                <UiText variant={'body1'}>{subtotal}</UiText>
              </UiHStack>
              {shoppingCart.tax?.map((taxItem, index) => (
                <UiHStack alignItems={'center'} justifyContent={'flex-end'} key={index}>
                  <UiText variant={'body1'} color={'text.secondary'}>{taxItem.label}</UiText>
                  <UiText variant={'body1'}>{formatPrice(taxItem.amount * 100 ?? 0)}</UiText>
                </UiHStack>
              ))}
              {!!shoppingCart.surcharge && (
                <UiHStack alignItems={'center'} justifyContent={'flex-end'}>
                  <UiText variant={'body1'} color={'text.secondary'}>Surcharge</UiText>
                  <UiText variant={'body1'}>{formatPrice(shoppingCart.surcharge * 100 ?? 0)}</UiText>
                </UiHStack>
              )}
              <UiHStack alignItems={'center'} justifyContent={'flex-end'}>
                <UiText variant={'body1'} color={'text.secondary'}>Total amount</UiText>
                <UiText variant={'title'}>{totalAmount}</UiText>
              </UiHStack>
            </UiStack>
          </UiHStack>
        </UiStack>
      )}
    </QueryContainer>
  );
};

export default Cart;
