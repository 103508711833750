import {
  forwardRef,
  Button,
  type ButtonProps,
  type As
} from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui/styles';

export type UiButtonProps = ButtonProps;

export const UiButton = forwardRef<ButtonProps, As>((props, ref) => {
  return (
    <Button
      // display={{ base: 'none', md: 'inline-flex' }}
      display={'inline-flex'}
      fontSize={'md'}
      fontWeight={400}
      borderRadius={uiStyles.borderRadius}
      colorScheme={'primary'}
      ref={ref}
      {...uiStyles.hover}
      {...props}
    />
  );
});
