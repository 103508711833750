import { type FC, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import {
  UiButton,
  UiDivider,
  UiHStack,
  type UiHStackProps,
  UiIconPaperPlaneTilt,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import BaseButtonRouterLink from '@/base/Button/RouterLink';

export interface AdminUserFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  name: string
  email: string
  teams: string[]
  status: string
}

interface Errors {
  name?: string
  email?: string
  teams?: string
  status?: string
}

const teamOptions: Option[] = [
  { value: 'admin', label: 'Admin' },
  // { value: 'Medical', label: 'Medical', isDisabled: true },
  { value: 'regoManagement', label: 'Rego management' },
  { value: 'finance', label: 'Finance' }
];

const initFormData = {
  name: '',
  email: '',
  teams: [],
  status: ''
};

const AdminUserForm: FC<AdminUserFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  return (
    <Formik
      initialValues={formData}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData): Errors => {
        const errors: Errors = {};
        // if (!values.name) {
        //   errors.name = 'Required';
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.name = 'Invalid email address';
        // }
        // return errors;
        return {
          // name: 'adfasdf',
        };
      }}
      onSubmit={async (
        values: FormData
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        alert(JSON.stringify(values, null, 2));
        // setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Invite admin user'}
        size={'lg'}
        // isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'name'}
            label={'Name'}
          />
          <BaseFormInputField
            name={'email'}
            label={'Email'}
          />
          <BaseFormSelectField
            name={'teams'}
            label={'Teams'}
            isMultiple={true}
            options={teamOptions}
            // helper={(
            //   <BaseButtonRouterLink
            //     to={generatePageUrl('AccountAccessTeam')}
            //     description={'You will be redirected to teams page'}
            //   >
            //     Manage teams
            //   </BaseButtonRouterLink>
            // )}
            // isRequired={false}
          />
          <UiDivider borderColor={'gray.300'} />
          <UiStack alignItems={'flex-start'}>
            <UiText>Or</UiText>
            <UiHStack>
              <UiIconPaperPlaneTilt color={'primary.400'} />
              <UiButton colorScheme={'primary'} variant={'ghost'} p={0}>Copy invite link</UiButton>
            </UiHStack>
          </UiStack>
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default AdminUserForm;
