import { type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconClipboardText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface AdditionalInfoProps {
  isActive?: boolean
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconClipboardText}
      description={'Additional info'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventAdditionalInfoForm', { tenantCode, eventId })}
      isSet={eventSettings?.additionalInformation?.isSet}
      // isSkipped={!eventSettings?.additionalInformation?.isEnabled}
    >
      <UiStack>
        {/*{!!eventSettings?.additionalInformation?.isEnabled && (*/}
        {/*  <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.additionalInformation?.fieldsLayout?.length ?? 0} fields</UiText>*/}
        {/*)}*/}
        {/* @todo Replace the number here to show the number of forms.*/}
        {!!eventSettings?.additionalInformation?.isEnabled && (
          <UiText variant={'body2'} color={'text.secondary'}>3 forms</UiText>
        )}
        {/* <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>Fields</UiText>
          <UiText variant={'body2'} color={'text.primary'}>23</UiText>
        </UiStack>
        <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>Sections</UiText>
          <UiText variant={'body2'} color={'text.primary'}>3</UiText>
        </UiStack> */}
      </UiStack>
    </Step>
  );
};

export default AdditionalInfo;
