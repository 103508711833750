import { type FC } from 'react';
import { type UiHStackProps, UiVStack } from '@/lib/ui';

export interface ListProps extends UiHStackProps {
}

const List: FC<ListProps> = ({
  ...props
}) => {
  return (
    <UiVStack
      alignItems={'flex-start'}
      flexGrow={1}
      spacing={0}
      {...props}
    />
  );
};

export default List;
