import { useMemo, type FC } from 'react';
import {
  UiButton,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
} from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import AccessDeniedLayout from '@/account/component/Layout/AccessDenied';
import AccessDeniedSmartButton from '@/account/component/Layout/AccessDeniedSmartButton';
import AccessDeniedSmartWindow from '@/account/component/Layout/AccessDeniedSmartWindow';

export interface AccessDeniedProps {
}

const AccessDenied: FC<AccessDeniedProps> = () => {
  const loginUrl = useMemo(() => generatePageUrl('AccountLoginLoginEmail'), []);

  return (
    <AccessDeniedLayout
      smartButton={<AccessDeniedSmartButton/>}
      smartWindow={<AccessDeniedSmartWindow/>}
    >
      <UiStack p={8} spacing={4} bgColor={'gray.100'} flexGrow={1} borderRadius={uiStyles.borderRadius}>
        <BaseMessageBarError>
          Access denied.
        </BaseMessageBarError>
        <UiHStack>
          <BaseRouterLink to={loginUrl}>
            <UiStack
              {...uiStyles.hover}
              borderRadius={uiStyles.borderRadius}
              p={2}
              px={4}
              bgColor={'primary.500'}
            >
              <UiText
                color={'#fff'}
                // variant={'title'}
                // p={4}
              >
                Back to login
              </UiText>
            </UiStack>
          </BaseRouterLink>
        </UiHStack>
      </UiStack>
    </AccessDeniedLayout>
  );
};

export default AccessDenied;
