import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI
} from '@/api/tenantClient';

export const cartQueryKey = 'registration.cartList';
export const confirmCartStockQueryKey = 'registration.confirmCartStock';

export interface Cart {
  id: number
  visitorId: number
}

export interface Tax {
  label: string
  amount: number
}

export interface CartDisplay extends Cart {
  cartItems: CartItemDisplay[]
  subtotal: number
  totalAmount: number
  surcharge: number
  tax: Tax[]
}

export interface CartQueryRequest {
  visitorId: number
}

export function loadCart(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CartQueryRequest
  ): Promise<ApiResponse<CartDisplay>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/carts/' + params.visitorId;
    return await callApi<CartDisplay>(request);
  };
}

export interface CartSaveRequest extends Omit<Cart, 'id'> {}

export function saveCart(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: CartSaveRequest
  ): Promise<ApiResponse<CartDisplay>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/carts';
    request.payload = {
      cart: params
    };
    return await callApi<CartDisplay>(request);
  };
}

export interface CartItem {
  id: number
  cartId: number
  productId: number
  productType: string
  quantity: number
}

export interface CartItemDisplay extends CartItem {
  name: string
  price: number
}

export interface CartItemSaveRequest extends Omit<CartItem, 'id'> {}

export function saveCartItem(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CartItemSaveRequest
  ): Promise<ApiResponse<CartItem>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/cart_items';
    request.payload = {
      cartItem: params
    };
    return await callApi<CartItem>(request);
  };
}

export interface CartItemUpdateRequest extends Omit<CartItem, 'productId' | 'productType'> {
  productId?: number
  productType?: string
}

export function updateCartItem(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CartItemUpdateRequest
  ): Promise<ApiResponse<CartItem>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/cart_items/' + params.id;
    request.payload = {
      cartItem: params
    };
    return await callApi<CartItem>(request);
  };
}

export interface CartItemDeleteRequest {
  id: number
}

export function deleteCartItem(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CartItemDeleteRequest
  ) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/cart_items/' + params.id;
    await deleteAPI(request);
  };
}

export const emptyShoppingCart = {
  id: 0,
  subtotal: 0,
  surcharge: 0,
  tax: [
    {
      label: '',
      amount: 0
    }
  ],
  totalAmount: 0,
  cartItems: []
};
