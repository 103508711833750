import { type FC, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';

export interface LoginEmailFormProps extends UiHStackProps {
  onAdminUserLoaded: (adminUser: account.AdminUserData) => void
  email?: string
}

interface FormData {
  email: string
}

const formSchema = Yup.object().shape({
  email: Yup.string().required('Email is required.')
});

const inputWidth = '420px';

const LoginEmailForm: FC<LoginEmailFormProps> = ({
  onAdminUserLoaded,
  email = ''
}) => {
  const [currentEmail, setCurrentEmail] = useState(email);
  const [errors, setErrors] = useState<string[]>([]);

  const { data: adminUserResponse, isLoading, refetch } = useQuery<account.AdminUserLoadByEmailResponse, Error>(
    [account.adminUserQueryKey, {}],
    async () => {
      return await account.loadAdminUserByEmail({ email: currentEmail });
    },
    {
      // enabled: false, // Having enabled: false will mess up isLoading. https://github.com/TanStack/query/issues/3584
    }
  );

  // Load the user info.
  useEffect(
    () => {
      if (currentEmail) {
        void (async () => {
          await refetch();
        }
        )();
      }
    },
    [currentEmail]
  );

  // Check the user.
  useEffect(
    () => {
      // The currentEmail is not empty meaning the user has submitted the form.
      if (currentEmail) {
        // console.log(isLoading,adminUserResponse, adminUserResponse?.item?.id);
        if (!isLoading && !adminUserResponse?.item?.id) {
          setErrors(['The email is not found.']);
        } else if (adminUserResponse?.item?.id && adminUserResponse?.item.email === currentEmail) {
          /**
           * @todo Redirect to the next page.
           */
          onAdminUserLoaded(adminUserResponse.item);
          setErrors([]);
        }
      }
    },
    [currentEmail, adminUserResponse?.item?.id]
  );

  const submitForm = async (values: FormData) => {
    // mutate({
    //   email: values.email,
    // });
    if (values.email) {
      setCurrentEmail(values.email);
    }
  };

  return (
    <Formik
      initialValues={{
        email: currentEmail
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <Form
        style={{
          display: 'flex'
        }}
      >
        <UiStack alignItems={'flex-start'}>
          {errors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} pb={2}>
              {errors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index} width={inputWidth}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <BaseFormFieldGroup minW={inputWidth} maxW={inputWidth}>
            <BaseFormInputField
              name="email"
              label="Email"
              layout="stack"
            />
          </BaseFormFieldGroup>
          <UiStack flexDirection={'row-reverse'} py={4}>
            {isLoading
              ? (
                <UiButton px={8} size={'lg'} colorScheme={'gray'} width={inputWidth}>
                Processing...
                </UiButton>
              ) : (
                <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'} width={inputWidth}>
                Next
                </UiButton>
              )}
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default LoginEmailForm;
