import { type FC } from 'react';
// import { Player } from '@lottiefiles/react-lottie-player';
import {
  UiBox,
  type UiBoxProps,
  uiStyles,
  UiIconHeadset,
  UiText,
  UiHStack,
} from '@/lib/ui';
// import smartButtonLottie from '@/lottie/white-robot.json';

export interface AccessDeniedSmartButtonProps extends UiBoxProps {
}

const AccessDeniedSmartButton: FC<AccessDeniedSmartButtonProps> = (props) => {
  return (
    <UiBox
      // p={4}
      borderRadius={'100%'}
      // bgColor={'primary.400'}
      // p={4}
      {...uiStyles.hover}
      {...props}
    >
      {/* <Player
        autoplay
        loop
        src={smartButtonLottie}
        style={{ height: '72px', width: '72px' }}
      /> */}
      <UiHStack>
        <UiText color={'primary.500'} variant={'title'}>Help</UiText>
        <UiIconHeadset size={40} color={'primary.400'}/>
      </UiHStack>
    </UiBox>
  );
};

export default AccessDeniedSmartButton;
