import { type FC } from 'react';
import BaseRouterLink from '@/base/Router/Link';
import {
  type UiBoxProps,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiSimpleGrid
} from '@/lib/ui';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseInfoBlockGroup from '@/base/InfoBlock/Group';
import BaseInfoBlockItem from '@/base/InfoBlock/Item';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
// import ModulePermissionsLottie from '@/lottie/white-robot.json';

// export const apps = {
//   ACCOUNT: 1,
//   REGISTRATION: 2,
//   ABSTRACT: 3,
// }

export interface ModulePermissionsProps extends UiBoxProps {
}

const ModulePermissions: FC<ModulePermissionsProps> = () => {
  const { tenant } = useTenantRoute();

  return (
    <UiStack spacing={4}>
      <UiSimpleGrid spacing={8} columns={4}>
        <BaseRouterLink to={generatePageUrl('RegistrationManageEvent', { tenantCode: tenant?.code ?? '' })}>
          <BaseInfoBlockGroup
            enableShadow={true}
            enableSeparator={false}
            bgColor={'#fff'}
            shadow={'none'}
            p={2}
            spacing={4}
            minWidth={300}
            {...uiStyles.hover}
            borderRadius={uiStyles.borderRadius}
          >
            <BaseInfoBlockItem p={4}>
              <BaseAppRegistrationCard />
            </BaseInfoBlockItem>
            <BaseInfoBlockItem p={4} py={2}>
              <UiHStack flexGrow={1} justifyContent={'space-between'}>
                <UiText variant={'body1'}>Permission</UiText>
                <UiText variant={'body1'}>Edit</UiText>
              </UiHStack>
            </BaseInfoBlockItem>
          </BaseInfoBlockGroup>
        </BaseRouterLink>
        <BaseInfoBlockGroup
          enableShadow={true}
          enableSeparator={false}
          bgColor={'#fff'}
          shadow={'none'}
          p={2}
          spacing={4}
          minWidth={300}
          {...uiStyles.hover}
          borderRadius={uiStyles.borderRadius}
        >
          <BaseInfoBlockItem p={4}>
            <BaseAbstractCard />
          </BaseInfoBlockItem>
          <BaseInfoBlockItem p={4} py={2}>
            <UiHStack flexGrow={1} justifyContent={'space-between'}>
              <UiText variant={'body1'}>Permission</UiText>
              <UiText variant={'body1'}>View</UiText>
            </UiHStack>
          </BaseInfoBlockItem>
        </BaseInfoBlockGroup>
      </UiSimpleGrid>
    </UiStack>
  );
};

export default ModulePermissions;
