import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
// import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
// import { UiStack } from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import PersonalInfoReview from './PersonalInfoReview';
import AdditionalInfoReview from './AdditionalInfoReview';
import ShoppingCartReview from './ShoppingCartReview';
// import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiStack, UiText } from '@/lib/ui';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useVisitor } from '@/app/ProviderVisitor';

const Review: FC = () => {
  const { toNextStep, toPreviousStep } = useRegisterSteps();
  const { visitor } = useVisitor();

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={6} enableDivider={true} pb={16}>
          <UiStack>
            <UiText variant={'h6'}>Registration review</UiText>
          </UiStack>
          {/* <BaseDividerHorizontal height={{base: 4, lg: 6}} color={'gray.300'}/> */}
          <PersonalInfoReview />
          {visitor?.registrationSteps?.additionalInformation && <AdditionalInfoReview />}
          <ShoppingCartReview />
        </UiStack>
      </BaseTransitionSlideLeft>
      <LayoutFooter justifyContent={'flex-end'}>
        <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
          Previous
        </UiButton>
        <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
          Addresses
        </UiButton>
      </LayoutFooter>
    </RegisterLayout>
  );
};

export default Review;
